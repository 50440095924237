const ENCOUNTER_ID = '1152c58a-74d2-4f4f-904e-603107f7a681';
const ENVIRONMENTS = {
  mock: {
    text: 'Mock',
    authCode: 'dummyAuthCode'
  }
};
export default {
  BACKEND_URL: process.env.BACKEND_URL || 'http://localhost:8081',
  APP_URL: process.env.APP_URL || 'http://localhost:8082',
  AUTH_ENVIRONMENT: ENVIRONMENTS[process.env.AUTH_ENV] || ENVIRONMENTS.mock,
  ENCOUNTER_ID
};