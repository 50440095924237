/* components/Login.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	add_render_callback,
	append_dev,
	attr_dev,
	check_outros,
	create_component,
	destroy_component,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element,
	empty,
	globals,
	group_outros,
	init,
	insert_dev,
	listen_dev,
	mount_component,
	noop,
	prop_dev,
	run_all,
	safe_not_equal,
	select_option,
	select_value,
	set_data_dev,
	set_input_value,
	space,
	text,
	transition_in,
	transition_out,
	validate_each_argument,
	validate_slots
} from "svelte/internal";

const { Object: Object_1 } = globals;
import { createEventDispatcher, onMount } from 'svelte';
import Button from './common/Button.svelte';
import { envs } from '../envs.js';
import { hrzLogin, initWidgetQuick } from '../actions.js';
import config from '../../../../e2e/config';
import es from 'date-fns/locale/es';
import EncounterConfig from './panels/EncounterConfig.svelte';
import Collapsable from './common/Collapsable.svelte';
import { LOCAL_STORAGE_URL_OVERRIDE_FLAG } from '../constants';
const file = "components/Login.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[35] = list[i];
	child_ctx[37] = i;
	return child_ctx;
}

// (160:8) {#each flatEnvs as env, i}
function create_each_block(ctx) {
	let option;
	let t_value = /*env*/ ctx[35].name + "";
	let t;
	let option_selected_value;
	let option_value_value;

	const block = {
		c: function create() {
			option = element("option");
			t = text(t_value);
			option.selected = option_selected_value = /*environment*/ ctx[0].key === /*env*/ ctx[35].key;
			option.__value = option_value_value = /*env*/ ctx[35];
			option.value = option.__value;
			add_location(option, file, 160, 10, 4703);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option, anchor);
			append_dev(option, t);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*flatEnvs*/ 2048 && t_value !== (t_value = /*env*/ ctx[35].name + "")) set_data_dev(t, t_value);

			if (dirty[0] & /*environment, flatEnvs*/ 2049 && option_selected_value !== (option_selected_value = /*environment*/ ctx[0].key === /*env*/ ctx[35].key)) {
				prop_dev(option, "selected", option_selected_value);
			}

			if (dirty[0] & /*flatEnvs*/ 2048 && option_value_value !== (option_value_value = /*env*/ ctx[35])) {
				prop_dev(option, "__value", option_value_value);
				option.value = option.__value;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(option);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(160:8) {#each flatEnvs as env, i}",
		ctx
	});

	return block;
}

// (163:8) {#if envFilter === 'others' || !envFilter}
function create_if_block_6(ctx) {
	let option0;
	let option1;
	let option2;

	const block = {
		c: function create() {
			option0 = element("option");
			option0.textContent = "[Api Key with authcode]";
			option1 = element("option");
			option1.textContent = "[Api Key with token]";
			option2 = element("option");
			option2.textContent = "[Mock]";
			option0.__value = "custom-with-authcode";
			option0.value = option0.__value;
			add_location(option0, file, 163, 10, 4859);
			option1.__value = "custom-with-token";
			option1.value = option1.__value;
			add_location(option1, file, 164, 10, 4939);
			option2.__value = "mock";
			option2.value = option2.__value;
			add_location(option2, file, 165, 10, 5013);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option0, anchor);
			insert_dev(target, option1, anchor);
			insert_dev(target, option2, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(option0);
			if (detaching) detach_dev(option1);
			if (detaching) detach_dev(option2);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_6.name,
		type: "if",
		source: "(163:8) {#if envFilter === 'others' || !envFilter}",
		ctx
	});

	return block;
}

// (170:4) {#if environment === 'custom-with-token' || environment === 'custom-with-authcode'}
function create_if_block_5(ctx) {
	let div;
	let label;
	let t1;
	let input;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div = element("div");
			label = element("label");
			label.textContent = "Api Key:";
			t1 = space();
			input = element("input");
			attr_dev(label, "class", "md:w-3/12 font-bold");
			attr_dev(label, "for", "env");
			add_location(label, file, 171, 8, 5238);
			attr_dev(input, "class", "focus:outline-none focus:shadow-outline");
			attr_dev(input, "name", "apiKey");
			attr_dev(input, "placeholder", "ApiKey");
			add_location(input, file, 172, 8, 5308);
			attr_dev(div, "class", "flex flex-row p-2 items-center");
			add_location(div, file, 170, 6, 5185);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, label);
			append_dev(div, t1);
			append_dev(div, input);
			set_input_value(input, /*customApiKey*/ ctx[5]);

			if (!mounted) {
				dispose = listen_dev(input, "input", /*input_input_handler*/ ctx[24]);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*customApiKey*/ 32 && input.value !== /*customApiKey*/ ctx[5]) {
				set_input_value(input, /*customApiKey*/ ctx[5]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_5.name,
		type: "if",
		source: "(170:4) {#if environment === 'custom-with-token' || environment === 'custom-with-authcode'}",
		ctx
	});

	return block;
}

// (181:4) {#if environment === 'mock'}
function create_if_block_4(ctx) {
	let div;
	let label;
	let t1;
	let input;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div = element("div");
			label = element("label");
			label.textContent = "MockServer URL:";
			t1 = space();
			input = element("input");
			attr_dev(label, "class", "md:w-3/12 font-bold");
			attr_dev(label, "for", "env");
			add_location(label, file, 182, 8, 5590);
			attr_dev(input, "class", "focus:outline-none focus:shadow-outline");
			attr_dev(input, "name", "mockUrl");
			attr_dev(input, "placeholder", "URL");
			add_location(input, file, 183, 8, 5667);
			attr_dev(div, "class", "flex flex-row p-2 items-center");
			add_location(div, file, 181, 6, 5537);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, label);
			append_dev(div, t1);
			append_dev(div, input);
			set_input_value(input, /*mockUrl*/ ctx[6]);

			if (!mounted) {
				dispose = listen_dev(input, "input", /*input_input_handler_1*/ ctx[25]);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*mockUrl*/ 64 && input.value !== /*mockUrl*/ ctx[6]) {
				set_input_value(input, /*mockUrl*/ ctx[6]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_4.name,
		type: "if",
		source: "(181:4) {#if environment === 'mock'}",
		ctx
	});

	return block;
}

// (192:4) {#if environment === 'custom-with-authcode' || (!environment.token && environment !== 'custom-with-token')}
function create_if_block_3(ctx) {
	let div0;
	let label0;
	let t1;
	let input;
	let t2;
	let div1;
	let label1;
	let t4;
	let textarea;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div0 = element("div");
			label0 = element("label");
			label0.textContent = "Authcode:";
			t1 = space();
			input = element("input");
			t2 = space();
			div1 = element("div");
			label1 = element("label");
			label1.textContent = "Data:";
			t4 = space();
			textarea = element("textarea");
			attr_dev(label0, "class", "md:w-3/12 font-bold");
			attr_dev(label0, "for", "env");
			add_location(label0, file, 193, 8, 6021);
			attr_dev(input, "class", "focus:outline-none focus:shadow-outline");
			attr_dev(input, "name", "authCode");
			attr_dev(input, "placeholder", "AuthCode");
			add_location(input, file, 194, 8, 6092);
			attr_dev(div0, "class", "flex flex-row p-2 items-center");
			add_location(div0, file, 192, 6, 5968);
			attr_dev(label1, "class", "md:w-3/12 font-bold");
			attr_dev(label1, "for", "env");
			add_location(label1, file, 202, 8, 6331);
			attr_dev(textarea, "class", "resizefocus:outline-none focus:shadow-outline");
			attr_dev(textarea, "name", "data");
			add_location(textarea, file, 203, 8, 6398);
			attr_dev(div1, "class", "flex flex-row p-2 items-center");
			add_location(div1, file, 201, 6, 6278);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div0, anchor);
			append_dev(div0, label0);
			append_dev(div0, t1);
			append_dev(div0, input);
			set_input_value(input, /*authCode*/ ctx[1]);
			insert_dev(target, t2, anchor);
			insert_dev(target, div1, anchor);
			append_dev(div1, label1);
			append_dev(div1, t4);
			append_dev(div1, textarea);
			set_input_value(textarea, /*data*/ ctx[2]);

			if (!mounted) {
				dispose = [
					listen_dev(input, "input", /*input_input_handler_2*/ ctx[26]),
					listen_dev(textarea, "input", /*textarea_input_handler*/ ctx[27])
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*authCode*/ 2 && input.value !== /*authCode*/ ctx[1]) {
				set_input_value(input, /*authCode*/ ctx[1]);
			}

			if (dirty[0] & /*data*/ 4) {
				set_input_value(textarea, /*data*/ ctx[2]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div0);
			if (detaching) detach_dev(t2);
			if (detaching) detach_dev(div1);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_3.name,
		type: "if",
		source: "(192:4) {#if environment === 'custom-with-authcode' || (!environment.token && environment !== 'custom-with-token')}",
		ctx
	});

	return block;
}

// (211:4) {#if environment === 'custom-with-token' || environment.token}
function create_if_block_2(ctx) {
	let div0;
	let label0;
	let t1;
	let textarea;
	let t2;
	let div2;
	let label1;
	let t4;
	let div1;
	let input;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div0 = element("div");
			label0 = element("label");
			label0.textContent = "Token:";
			t1 = space();
			textarea = element("textarea");
			t2 = space();
			div2 = element("div");
			label1 = element("label");
			label1.textContent = "Is mocked user:";
			t4 = space();
			div1 = element("div");
			input = element("input");
			attr_dev(label0, "class", "md:w-3/12 font-bold");
			attr_dev(label0, "for", "env");
			add_location(label0, file, 212, 8, 6682);
			attr_dev(textarea, "class", "resizefocus:outline-none focus:shadow-outline");
			attr_dev(textarea, "name", "token");
			add_location(textarea, file, 213, 8, 6750);
			attr_dev(div0, "class", "flex flex-row p-2 items-center");
			add_location(div0, file, 211, 6, 6629);
			attr_dev(label1, "class", "md:w-3/12 font-bold");
			attr_dev(label1, "for", "mocked");
			add_location(label1, file, 220, 8, 6959);
			attr_dev(input, "type", "checkbox");
			attr_dev(input, "class", "shadow-none w-auto");
			attr_dev(input, "name", "mocked");
			add_location(input, file, 222, 10, 7095);
			attr_dev(div1, "class", "md:w-full text-left border-none");
			add_location(div1, file, 221, 8, 7039);
			attr_dev(div2, "class", "flex flex-row p-2 items-center");
			add_location(div2, file, 219, 6, 6906);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div0, anchor);
			append_dev(div0, label0);
			append_dev(div0, t1);
			append_dev(div0, textarea);
			set_input_value(textarea, /*token*/ ctx[3]);
			insert_dev(target, t2, anchor);
			insert_dev(target, div2, anchor);
			append_dev(div2, label1);
			append_dev(div2, t4);
			append_dev(div2, div1);
			append_dev(div1, input);
			input.checked = /*mocked*/ ctx[4];

			if (!mounted) {
				dispose = [
					listen_dev(textarea, "input", /*textarea_input_handler_1*/ ctx[28]),
					listen_dev(input, "change", /*input_change_handler*/ ctx[29])
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*token*/ 8) {
				set_input_value(textarea, /*token*/ ctx[3]);
			}

			if (dirty[0] & /*mocked*/ 16) {
				input.checked = /*mocked*/ ctx[4];
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div0);
			if (detaching) detach_dev(t2);
			if (detaching) detach_dev(div2);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(211:4) {#if environment === 'custom-with-token' || environment.token}",
		ctx
	});

	return block;
}

// (228:6) <Button on:click={dispatchLoginData}>
function create_default_slot_1(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Log in");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_1.name,
		type: "slot",
		source: "(228:6) <Button on:click={dispatchLoginData}>",
		ctx
	});

	return block;
}

// (231:4) {#if environment.token && environment.name.includes('Horizon')}
function create_if_block(ctx) {
	let div0;
	let label0;
	let t1;
	let input0;
	let t2;
	let div1;
	let label1;
	let t4;
	let input1;
	let t5;
	let div2;
	let label2;
	let t7;
	let input2;
	let t8;
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	let mounted;
	let dispose;
	const if_block_creators = [create_if_block_1, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (!/*loading*/ ctx[10]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, [-1, -1]);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	const block = {
		c: function create() {
			div0 = element("div");
			label0 = element("label");
			label0.textContent = "Username:";
			t1 = space();
			input0 = element("input");
			t2 = space();
			div1 = element("div");
			label1 = element("label");
			label1.textContent = "Password:";
			t4 = space();
			input1 = element("input");
			t5 = space();
			div2 = element("div");
			label2 = element("label");
			label2.textContent = "Env:";
			t7 = space();
			input2 = element("input");
			t8 = space();
			if_block.c();
			if_block_anchor = empty();
			attr_dev(label0, "class", "md:w-3/12 font-bold");
			attr_dev(label0, "for", "env");
			add_location(label0, file, 232, 8, 7481);
			attr_dev(input0, "class", "focus:outline-none focus:shadow-outline");
			attr_dev(input0, "name", "username");
			attr_dev(input0, "placeholder", "Username");
			add_location(input0, file, 233, 8, 7552);
			attr_dev(div0, "class", "flex flex-row p-2 items-center");
			add_location(div0, file, 231, 6, 7428);
			attr_dev(label1, "class", "md:w-3/12 font-bold");
			attr_dev(label1, "for", "env");
			add_location(label1, file, 241, 8, 7791);
			attr_dev(input1, "class", "focus:outline-none focus:shadow-outline");
			attr_dev(input1, "type", "password");
			attr_dev(input1, "name", "password");
			attr_dev(input1, "placeholder", "Password");
			add_location(input1, file, 242, 8, 7862);
			attr_dev(div1, "class", "flex flex-row p-2 items-center");
			add_location(div1, file, 240, 6, 7738);
			attr_dev(label2, "class", "md:w-3/12 font-bold");
			attr_dev(label2, "for", "env");
			add_location(label2, file, 251, 8, 8127);
			attr_dev(input2, "class", "focus:outline-none focus:shadow-outline");
			attr_dev(input2, "name", "env");
			attr_dev(input2, "placeholder", "Env");
			input2.disabled = "true";
			add_location(input2, file, 252, 8, 8193);
			attr_dev(div2, "class", "flex flex-row p-2 items-center");
			add_location(div2, file, 250, 6, 8074);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div0, anchor);
			append_dev(div0, label0);
			append_dev(div0, t1);
			append_dev(div0, input0);
			set_input_value(input0, /*username*/ ctx[7]);
			insert_dev(target, t2, anchor);
			insert_dev(target, div1, anchor);
			append_dev(div1, label1);
			append_dev(div1, t4);
			append_dev(div1, input1);
			set_input_value(input1, /*password*/ ctx[8]);
			insert_dev(target, t5, anchor);
			insert_dev(target, div2, anchor);
			append_dev(div2, label2);
			append_dev(div2, t7);
			append_dev(div2, input2);
			set_input_value(input2, /*loginEnv*/ ctx[12]);
			insert_dev(target, t8, anchor);
			if_blocks[current_block_type_index].m(target, anchor);
			insert_dev(target, if_block_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = [
					listen_dev(input0, "input", /*input0_input_handler*/ ctx[30]),
					listen_dev(input1, "input", /*input1_input_handler*/ ctx[31]),
					listen_dev(input2, "input", /*input2_input_handler*/ ctx[32])
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*username*/ 128 && input0.value !== /*username*/ ctx[7]) {
				set_input_value(input0, /*username*/ ctx[7]);
			}

			if (dirty[0] & /*password*/ 256 && input1.value !== /*password*/ ctx[8]) {
				set_input_value(input1, /*password*/ ctx[8]);
			}

			if (dirty[0] & /*loginEnv*/ 4096 && input2.value !== /*loginEnv*/ ctx[12]) {
				set_input_value(input2, /*loginEnv*/ ctx[12]);
			}

			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i: function intro(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o: function outro(local) {
			transition_out(if_block);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div0);
			if (detaching) detach_dev(t2);
			if (detaching) detach_dev(div1);
			if (detaching) detach_dev(t5);
			if (detaching) detach_dev(div2);
			if (detaching) detach_dev(t8);
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach_dev(if_block_anchor);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(231:4) {#if environment.token && environment.name.includes('Horizon')}",
		ctx
	});

	return block;
}

// (265:6) {:else}
function create_else_block(ctx) {
	let div;
	let span;

	const block = {
		c: function create() {
			div = element("div");
			span = element("span");
			span.textContent = "Loading...";
			attr_dev(span, "class", "text-2xl");
			add_location(span, file, 265, 46, 8607);
			attr_dev(div, "class", "flex-1 text-center mt-20");
			add_location(div, file, 265, 8, 8569);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, span);
		},
		p: noop,
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(265:6) {:else}",
		ctx
	});

	return block;
}

// (261:6) {#if !loading}
function create_if_block_1(ctx) {
	let div;
	let button;
	let current;

	button = new Button({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button.$on("click", /*getToken*/ ctx[15]);

	const block = {
		c: function create() {
			div = element("div");
			create_component(button.$$.fragment);
			attr_dev(div, "class", "flex justify-end flex-row p-2 items-center");
			add_location(div, file, 261, 8, 8418);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			mount_component(button, div, null);
			current = true;
		},
		p: function update(ctx, dirty) {
			const button_changes = {};

			if (dirty[1] & /*$$scope*/ 128) {
				button_changes.$$scope = { dirty, ctx };
			}

			button.$set(button_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			destroy_component(button);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(261:6) {#if !loading}",
		ctx
	});

	return block;
}

// (263:10) <Button on:click={getToken}>
function create_default_slot(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Get Token");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot.name,
		type: "slot",
		source: "(263:10) <Button on:click={getToken}>",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div4;
	let fieldset;
	let div1;
	let label0;
	let t1;
	let div0;
	let button0;
	let t2;
	let button0_class_value;
	let t3;
	let button1;
	let t4;
	let button1_class_value;
	let t5;
	let button2;
	let t6;
	let button2_class_value;
	let t7;
	let button3;
	let t8;
	let button3_class_value;
	let t9;
	let button4;
	let t10;
	let button4_class_value;
	let t11;
	let button5;
	let t12;
	let button5_class_value;
	let t13;
	let button6;
	let t15;
	let div2;
	let label1;
	let t17;
	let select;
	let each_1_anchor;
	let t18;
	let t19;
	let t20;
	let t21;
	let t22;
	let div3;
	let button7;
	let t23;
	let show_if = /*environment*/ ctx[0].token && /*environment*/ ctx[0].name.includes('Horizon');
	let current;
	let mounted;
	let dispose;
	let each_value = /*flatEnvs*/ ctx[11];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	let if_block0 = (/*envFilter*/ ctx[9] === 'others' || !/*envFilter*/ ctx[9]) && create_if_block_6(ctx);
	let if_block1 = (/*environment*/ ctx[0] === 'custom-with-token' || /*environment*/ ctx[0] === 'custom-with-authcode') && create_if_block_5(ctx);
	let if_block2 = /*environment*/ ctx[0] === 'mock' && create_if_block_4(ctx);
	let if_block3 = (/*environment*/ ctx[0] === 'custom-with-authcode' || !/*environment*/ ctx[0].token && /*environment*/ ctx[0] !== 'custom-with-token') && create_if_block_3(ctx);
	let if_block4 = (/*environment*/ ctx[0] === 'custom-with-token' || /*environment*/ ctx[0].token) && create_if_block_2(ctx);

	button7 = new Button({
			props: {
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button7.$on("click", /*dispatchLoginData*/ ctx[13]);
	let if_block5 = show_if && create_if_block(ctx);

	const block = {
		c: function create() {
			div4 = element("div");
			fieldset = element("fieldset");
			div1 = element("div");
			label0 = element("label");
			label0.textContent = "Env filter:";
			t1 = space();
			div0 = element("div");
			button0 = element("button");
			t2 = text("All");
			t3 = space();
			button1 = element("button");
			t4 = text("DEV");
			t5 = space();
			button2 = element("button");
			t6 = text("QA");
			t7 = space();
			button3 = element("button");
			t8 = text("STAGE");
			t9 = space();
			button4 = element("button");
			t10 = text("SDX");
			t11 = space();
			button5 = element("button");
			t12 = text("OTHERS");
			t13 = space();
			button6 = element("button");
			button6.textContent = "QUICK INIT";
			t15 = space();
			div2 = element("div");
			label1 = element("label");
			label1.textContent = "Env - Org:";
			t17 = space();
			select = element("select");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
			if (if_block0) if_block0.c();
			t18 = space();
			if (if_block1) if_block1.c();
			t19 = space();
			if (if_block2) if_block2.c();
			t20 = space();
			if (if_block3) if_block3.c();
			t21 = space();
			if (if_block4) if_block4.c();
			t22 = space();
			div3 = element("div");
			create_component(button7.$$.fragment);
			t23 = space();
			if (if_block5) if_block5.c();
			attr_dev(label0, "class", "md:w-3/12 font-bold");
			attr_dev(label0, "for", "env");
			add_location(label0, file, 122, 6, 3232);
			attr_dev(button0, "class", button0_class_value = !/*envFilter*/ ctx[9] ? 'bg-pager-orange' : '');
			add_location(button0, file, 124, 8, 3332);
			attr_dev(button1, "class", button1_class_value = /*envFilter*/ ctx[9] === 'dev' ? 'bg-pager-orange' : '');
			add_location(button1, file, 127, 8, 3457);
			attr_dev(button2, "class", button2_class_value = /*envFilter*/ ctx[9] === 'qa' ? 'bg-pager-orange' : '');
			add_location(button2, file, 131, 8, 3605);

			attr_dev(button3, "class", button3_class_value = /*envFilter*/ ctx[9] === 'stage'
			? 'bg-pager-orange'
			: '');

			add_location(button3, file, 135, 8, 3750);
			attr_dev(button4, "class", button4_class_value = /*envFilter*/ ctx[9] === 'sdx' ? 'bg-pager-orange' : '');
			add_location(button4, file, 139, 8, 3904);

			attr_dev(button5, "class", button5_class_value = /*envFilter*/ ctx[9] === 'others'
			? 'bg-pager-orange'
			: '');

			add_location(button5, file, 143, 8, 4052);
			attr_dev(button6, "class", "hiddenButton svelte-1f4ugxj");
			add_location(button6, file, 147, 8, 4209);
			attr_dev(div0, "class", "w-full");
			add_location(div0, file, 123, 6, 3303);
			attr_dev(div1, "class", "flex flex-row p-2 items-center");
			add_location(div1, file, 121, 4, 3181);
			attr_dev(label1, "class", "md:w-3/12 font-bold");
			attr_dev(label1, "for", "env");
			add_location(label1, file, 157, 6, 4543);
			attr_dev(select, "name", "env");
			if (/*environment*/ ctx[0] === void 0) add_render_callback(() => /*select_change_handler*/ ctx[23].call(select));
			add_location(select, file, 158, 6, 4613);
			attr_dev(div2, "class", "flex flex-row p-2 items-center");
			add_location(div2, file, 156, 4, 4492);
			attr_dev(div3, "class", "flex justify-end flex-row p-2 items-center");
			add_location(div3, file, 226, 4, 7226);
			attr_dev(fieldset, "class", "flex flex-col border-1 bg-gray-200 shadow p-4 dark:bg-gray-800 dark:text-white");
			add_location(fieldset, file, 120, 2, 3079);
			attr_dev(div4, "id", "loginContainer");
			attr_dev(div4, "class", "mt-10");
			add_location(div4, file, 119, 0, 3037);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div4, anchor);
			append_dev(div4, fieldset);
			append_dev(fieldset, div1);
			append_dev(div1, label0);
			append_dev(div1, t1);
			append_dev(div1, div0);
			append_dev(div0, button0);
			append_dev(button0, t2);
			append_dev(div0, t3);
			append_dev(div0, button1);
			append_dev(button1, t4);
			append_dev(div0, t5);
			append_dev(div0, button2);
			append_dev(button2, t6);
			append_dev(div0, t7);
			append_dev(div0, button3);
			append_dev(button3, t8);
			append_dev(div0, t9);
			append_dev(div0, button4);
			append_dev(button4, t10);
			append_dev(div0, t11);
			append_dev(div0, button5);
			append_dev(button5, t12);
			append_dev(div0, t13);
			append_dev(div0, button6);
			append_dev(fieldset, t15);
			append_dev(fieldset, div2);
			append_dev(div2, label1);
			append_dev(div2, t17);
			append_dev(div2, select);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(select, null);
			}

			append_dev(select, each_1_anchor);
			if (if_block0) if_block0.m(select, null);
			select_option(select, /*environment*/ ctx[0]);
			append_dev(fieldset, t18);
			if (if_block1) if_block1.m(fieldset, null);
			append_dev(fieldset, t19);
			if (if_block2) if_block2.m(fieldset, null);
			append_dev(fieldset, t20);
			if (if_block3) if_block3.m(fieldset, null);
			append_dev(fieldset, t21);
			if (if_block4) if_block4.m(fieldset, null);
			append_dev(fieldset, t22);
			append_dev(fieldset, div3);
			mount_component(button7, div3, null);
			append_dev(fieldset, t23);
			if (if_block5) if_block5.m(fieldset, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen_dev(button0, "click", /*click_handler*/ ctx[16], false, false, false),
					listen_dev(button1, "click", /*click_handler_1*/ ctx[17], false, false, false),
					listen_dev(button2, "click", /*click_handler_2*/ ctx[18], false, false, false),
					listen_dev(button3, "click", /*click_handler_3*/ ctx[19], false, false, false),
					listen_dev(button4, "click", /*click_handler_4*/ ctx[20], false, false, false),
					listen_dev(button5, "click", /*click_handler_5*/ ctx[21], false, false, false),
					listen_dev(button6, "click", /*click_handler_6*/ ctx[22], false, false, false),
					listen_dev(select, "change", /*select_change_handler*/ ctx[23])
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (!current || dirty[0] & /*envFilter*/ 512 && button0_class_value !== (button0_class_value = !/*envFilter*/ ctx[9] ? 'bg-pager-orange' : '')) {
				attr_dev(button0, "class", button0_class_value);
			}

			if (!current || dirty[0] & /*envFilter*/ 512 && button1_class_value !== (button1_class_value = /*envFilter*/ ctx[9] === 'dev' ? 'bg-pager-orange' : '')) {
				attr_dev(button1, "class", button1_class_value);
			}

			if (!current || dirty[0] & /*envFilter*/ 512 && button2_class_value !== (button2_class_value = /*envFilter*/ ctx[9] === 'qa' ? 'bg-pager-orange' : '')) {
				attr_dev(button2, "class", button2_class_value);
			}

			if (!current || dirty[0] & /*envFilter*/ 512 && button3_class_value !== (button3_class_value = /*envFilter*/ ctx[9] === 'stage'
			? 'bg-pager-orange'
			: '')) {
				attr_dev(button3, "class", button3_class_value);
			}

			if (!current || dirty[0] & /*envFilter*/ 512 && button4_class_value !== (button4_class_value = /*envFilter*/ ctx[9] === 'sdx' ? 'bg-pager-orange' : '')) {
				attr_dev(button4, "class", button4_class_value);
			}

			if (!current || dirty[0] & /*envFilter*/ 512 && button5_class_value !== (button5_class_value = /*envFilter*/ ctx[9] === 'others'
			? 'bg-pager-orange'
			: '')) {
				attr_dev(button5, "class", button5_class_value);
			}

			if (dirty[0] & /*environment, flatEnvs*/ 2049) {
				each_value = /*flatEnvs*/ ctx[11];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (/*envFilter*/ ctx[9] === 'others' || !/*envFilter*/ ctx[9]) {
				if (if_block0) {
					
				} else {
					if_block0 = create_if_block_6(ctx);
					if_block0.c();
					if_block0.m(select, null);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (dirty[0] & /*environment, flatEnvs*/ 2049) {
				select_option(select, /*environment*/ ctx[0]);
			}

			if (/*environment*/ ctx[0] === 'custom-with-token' || /*environment*/ ctx[0] === 'custom-with-authcode') {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_5(ctx);
					if_block1.c();
					if_block1.m(fieldset, t19);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*environment*/ ctx[0] === 'mock') {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block_4(ctx);
					if_block2.c();
					if_block2.m(fieldset, t20);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}

			if (/*environment*/ ctx[0] === 'custom-with-authcode' || !/*environment*/ ctx[0].token && /*environment*/ ctx[0] !== 'custom-with-token') {
				if (if_block3) {
					if_block3.p(ctx, dirty);
				} else {
					if_block3 = create_if_block_3(ctx);
					if_block3.c();
					if_block3.m(fieldset, t21);
				}
			} else if (if_block3) {
				if_block3.d(1);
				if_block3 = null;
			}

			if (/*environment*/ ctx[0] === 'custom-with-token' || /*environment*/ ctx[0].token) {
				if (if_block4) {
					if_block4.p(ctx, dirty);
				} else {
					if_block4 = create_if_block_2(ctx);
					if_block4.c();
					if_block4.m(fieldset, t22);
				}
			} else if (if_block4) {
				if_block4.d(1);
				if_block4 = null;
			}

			const button7_changes = {};

			if (dirty[1] & /*$$scope*/ 128) {
				button7_changes.$$scope = { dirty, ctx };
			}

			button7.$set(button7_changes);
			if (dirty[0] & /*environment*/ 1) show_if = /*environment*/ ctx[0].token && /*environment*/ ctx[0].name.includes('Horizon');

			if (show_if) {
				if (if_block5) {
					if_block5.p(ctx, dirty);

					if (dirty[0] & /*environment*/ 1) {
						transition_in(if_block5, 1);
					}
				} else {
					if_block5 = create_if_block(ctx);
					if_block5.c();
					transition_in(if_block5, 1);
					if_block5.m(fieldset, null);
				}
			} else if (if_block5) {
				group_outros();

				transition_out(if_block5, 1, 1, () => {
					if_block5 = null;
				});

				check_outros();
			}
		},
		i: function intro(local) {
			if (current) return;
			transition_in(button7.$$.fragment, local);
			transition_in(if_block5);
			current = true;
		},
		o: function outro(local) {
			transition_out(button7.$$.fragment, local);
			transition_out(if_block5);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div4);
			destroy_each(each_blocks, detaching);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			if (if_block4) if_block4.d();
			destroy_component(button7);
			if (if_block5) if_block5.d();
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let loginEnv;
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('Login', slots, []);
	let { environment = null } = $$props;
	let authCode = '';
	let data = '';
	let token = '';
	let mocked = false;
	let customApiKey = null;
	let mockUrl = 'http://localhost';
	let username = '';
	let password = '';
	let envFilter = null;
	let loading = false;
	let flatEnvs = [];

	const getEnvsArray = (filter = null) => {
		$$invalidate(11, flatEnvs = []);

		if (!filter) {
			let i = 0;

			for (let e of Object.keys(envs)) {
				$$invalidate(11, flatEnvs = [
					...flatEnvs,
					...envs[e].map(item => {
						item.type = e;
						return item;
					})
				]);

				$$invalidate(0, environment = i === 0 ? flatEnvs[0] : environment);
			}
		} else if (envs[filter]) {
			$$invalidate(11, flatEnvs = [
				...envs[filter].map(item => {
					item.type = filter;
					return item;
				})
			]);

			$$invalidate(0, environment = flatEnvs[0]);
		} else {
			// OTHERS
			$$invalidate(0, environment = 'custom-with-authcode');
		}
	};

	onMount(() => {
		getEnvsArray();
	});

	const dispatch = createEventDispatcher();

	function dispatchLoginData() {
		$$invalidate(3, token = token.trim());
		$$invalidate(2, data = data.trim());
		$$invalidate(1, authCode = authCode.trim());
		localStorage.removeItem(LOCAL_STORAGE_URL_OVERRIDE_FLAG);

		if (environment === 'custom-with-authcode') {
			dispatch('login', {
				env: { key: customApiKey },
				authCode,
				data
			});
		} else if (environment === 'custom-with-token') {
			dispatch('login', { env: { key: customApiKey }, token });
		} else if (environment === 'mock') {
			localStorage.setItem(LOCAL_STORAGE_URL_OVERRIDE_FLAG, mockUrl);
			dispatch('login', { env: { key: '123' }, authCode, data });
		} else {
			if (!environment.token) {
				dispatch('login', {
					env: environment,
					authCode,
					data,
					type: environment.type
				});
			} else {
				if (mocked) {
					dispatch('login', {
						env: environment,
						token,
						mocked,
						endpoint: environment.endpoint,
						type: environment.type
					});
				} else {
					dispatch('login', { env: environment, token });
				}
			}
		}
	}

	const setEnvFilter = (filter = null) => {
		$$invalidate(9, envFilter = filter);
		getEnvsArray(filter);
	};

	const getToken = async () => {
		$$invalidate(10, loading = true);
		$$invalidate(3, token = '');

		const result = await hrzLogin({
			username,
			password,
			environment: loginEnv
		});

		$$invalidate(3, token = result.data.token);
		$$invalidate(10, loading = false);
	};

	const writable_props = ['environment'];

	Object_1.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<Login> was created with unknown prop '${key}'`);
	});

	const click_handler = () => setEnvFilter();
	const click_handler_1 = () => setEnvFilter('dev');
	const click_handler_2 = () => setEnvFilter('qa');
	const click_handler_3 = () => setEnvFilter('stage');
	const click_handler_4 = () => setEnvFilter('sdx');
	const click_handler_5 = () => setEnvFilter('others');

	const click_handler_6 = () => {
		localStorage.setItem(LOCAL_STORAGE_URL_OVERRIDE_FLAG, mockUrl);
		initWidgetQuick({ env: { key: '123' }, authCode, data });
	};

	function select_change_handler() {
		environment = select_value(this);
		$$invalidate(0, environment);
		$$invalidate(11, flatEnvs);
	}

	function input_input_handler() {
		customApiKey = this.value;
		$$invalidate(5, customApiKey);
	}

	function input_input_handler_1() {
		mockUrl = this.value;
		$$invalidate(6, mockUrl);
	}

	function input_input_handler_2() {
		authCode = this.value;
		$$invalidate(1, authCode);
	}

	function textarea_input_handler() {
		data = this.value;
		$$invalidate(2, data);
	}

	function textarea_input_handler_1() {
		token = this.value;
		$$invalidate(3, token);
	}

	function input_change_handler() {
		mocked = this.checked;
		$$invalidate(4, mocked);
	}

	function input0_input_handler() {
		username = this.value;
		$$invalidate(7, username);
	}

	function input1_input_handler() {
		password = this.value;
		$$invalidate(8, password);
	}

	function input2_input_handler() {
		loginEnv = this.value;
		($$invalidate(12, loginEnv), $$invalidate(0, environment));
	}

	$$self.$$set = $$props => {
		if ('environment' in $$props) $$invalidate(0, environment = $$props.environment);
	};

	$$self.$capture_state = () => ({
		createEventDispatcher,
		onMount,
		Button,
		envs,
		hrzLogin,
		initWidgetQuick,
		config,
		es,
		EncounterConfig,
		Collapsable,
		LOCAL_STORAGE_URL_OVERRIDE_FLAG,
		environment,
		authCode,
		data,
		token,
		mocked,
		customApiKey,
		mockUrl,
		username,
		password,
		envFilter,
		loading,
		flatEnvs,
		getEnvsArray,
		dispatch,
		dispatchLoginData,
		setEnvFilter,
		getToken,
		loginEnv
	});

	$$self.$inject_state = $$props => {
		if ('environment' in $$props) $$invalidate(0, environment = $$props.environment);
		if ('authCode' in $$props) $$invalidate(1, authCode = $$props.authCode);
		if ('data' in $$props) $$invalidate(2, data = $$props.data);
		if ('token' in $$props) $$invalidate(3, token = $$props.token);
		if ('mocked' in $$props) $$invalidate(4, mocked = $$props.mocked);
		if ('customApiKey' in $$props) $$invalidate(5, customApiKey = $$props.customApiKey);
		if ('mockUrl' in $$props) $$invalidate(6, mockUrl = $$props.mockUrl);
		if ('username' in $$props) $$invalidate(7, username = $$props.username);
		if ('password' in $$props) $$invalidate(8, password = $$props.password);
		if ('envFilter' in $$props) $$invalidate(9, envFilter = $$props.envFilter);
		if ('loading' in $$props) $$invalidate(10, loading = $$props.loading);
		if ('flatEnvs' in $$props) $$invalidate(11, flatEnvs = $$props.flatEnvs);
		if ('loginEnv' in $$props) $$invalidate(12, loginEnv = $$props.loginEnv);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*environment*/ 1) {
			$: $$invalidate(12, loginEnv = environment?.type);
		}
	};

	return [
		environment,
		authCode,
		data,
		token,
		mocked,
		customApiKey,
		mockUrl,
		username,
		password,
		envFilter,
		loading,
		flatEnvs,
		loginEnv,
		dispatchLoginData,
		setEnvFilter,
		getToken,
		click_handler,
		click_handler_1,
		click_handler_2,
		click_handler_3,
		click_handler_4,
		click_handler_5,
		click_handler_6,
		select_change_handler,
		input_input_handler,
		input_input_handler_1,
		input_input_handler_2,
		textarea_input_handler,
		textarea_input_handler_1,
		input_change_handler,
		input0_input_handler,
		input1_input_handler,
		input2_input_handler
	];
}

class Login extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, { environment: 0 }, null, [-1, -1]);

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "Login",
			options,
			id: create_fragment.name
		});
	}

	get environment() {
		throw new Error("<Login>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set environment(value) {
		throw new Error("<Login>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}
}

export default Login;