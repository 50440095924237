"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.widgetGetterResponse = exports.widgetEvents = exports.reasonCodesAndAttr = exports.messageHistory = exports.loggedin = exports.loading = exports.encounters = exports.currentPatient = exports.currentEncounterData = exports.cleared = void 0;
var _store = require("svelte/store");
var currentPatient = exports.currentPatient = (0, _store.writable)('');
var loggedin = exports.loggedin = (0, _store.writable)(false);
var loading = exports.loading = (0, _store.writable)(false);
var cleared = exports.cleared = (0, _store.writable)(true);
var encounters = exports.encounters = (0, _store.writable)([]);
var currentEncounterData = exports.currentEncounterData = (0, _store.writable)(null);
var messageHistory = exports.messageHistory = (0, _store.writable)([]);
var reasonCodesAndAttr = exports.reasonCodesAndAttr = (0, _store.writable)([]);
var widgetEvents = exports.widgetEvents = (0, _store.writable)([]);
var widgetGetterResponse = exports.widgetGetterResponse = (0, _store.writable)('');