"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.urls = exports.envs = void 0;
var urls = exports.urls = {
  dev: [{
    name: 'Command center',
    url: 'https://pgr-dev-command-center.pagerinc.com/'
  }, {
    name: 'Old Enterprise Admin',
    url: 'https://pgr-dev-enterprise-admin.pagerinc.com/'
  }, {
    name: 'Enterprise Admin',
    url: 'https://pgr-dev-ea.pagerinc.com/'
  }, {
    name: 'Horizon Command Center',
    url: 'https://hrz-dev-command-center.pagerinc.com/'
  }, {
    name: 'Horizon Old Enterprise Admin:',
    url: 'https://hrz-dev-enterprise-admin.pagerinc.com/'
  }, {
    name: 'Horizon Enterprise Admin',
    url: 'https://hrz-dev-ea.pagerinc.com/'
  }],
  qa: [{
    name: 'Command center',
    url: 'https://pgr-qa-command-center.pagerinc.com/'
  }, {
    name: 'Old Enterprise Admin',
    url: 'https://pgr-qa-enterprise-admin.pagerinc.com/'
  }, {
    name: 'Enterprise Admin',
    url: 'https://pgr-qa-ea.pagerinc.com/'
  }, {
    name: 'Horizon Command Center',
    url: 'https://hrz-qa-command-center.pagerinc.com/'
  }, {
    name: 'Horizon Old Enterprise Admin:',
    url: 'https://hrz-qa-enterprise-admin.pagerinc.com/'
  }, {
    name: 'Horizon Enterprise Admin',
    url: 'https://hrz-qa-ea.pagerinc.com/'
  }],
  stage: [{
    name: 'Command center',
    url: 'https://pgr-stage-command-center.pagerinc.com/'
  }, {
    name: 'Old Enterprise Admin',
    url: 'https://pgr-stage-enterprise-admin.pagerinc.com/'
  }, {
    name: 'Enterprise Admin',
    url: 'https://pgr-stage-ea.pagerinc.com/'
  }, {
    name: 'Horizon Command Center',
    url: 'https://hrz-stage-command-center.pagerinc.com/'
  }, {
    name: 'Horizon Old Enterprise Admin:',
    url: 'https://hrz-stage-enterprise-admin.pagerinc.com/'
  }, {
    name: 'Horizon Enterprise Admin',
    url: 'https://hrz-stage-ea.pagerinc.com/'
  }],
  sdx: [{
    name: 'Command center',
    url: 'https://pgr-sdx-command-center.pagerinc.com/'
  }, {
    name: 'Old Enterprise Admin',
    url: 'https://pgr-sdx-enterprise-admin.pagerinc.com/'
  }, {
    name: 'Enterprise Admin',
    url: 'https://pgr-sdx-ea.pagerinc.com/'
  }]
};
var envs = exports.envs = {
  dev: [
  // --------- DEV -----------
  {
    key: 'df93b7f2-a40b-4f09-b12b-50607baccd02',
    name: 'Dev - Asuris'
  }, {
    key: 'e7934639-af98-4e4e-a2d8-b6f834419685',
    name: 'Pgr-Dev - FB'
  }, {
    key: '13af055b-a958-411a-b1f1-9eea2ad82136',
    name: 'Hrz-Dev - FB'
  }, {
    key: 'fd48e172-9972-4990-bc9f-0b4cd38bcbf1',
    name: 'Dev - Horizon',
    token: true,
    endpoint: 'https://hrz-dev-api-horizon.pagerinc.com'
  }, {
    key: 'db83e8e5-7330-4d05-ba6b-6dde011da2c4',
    name: 'Dev - Horizon (Medicaid)'
  }, {
    key: '16f1bdf2-1e18-4b9c-89bf-e4de8a505996',
    name: 'Dev - Pager Sandbox Org'
  }, {
    key: '17ee077b-e794-48ea-87ec-f3d17c88c290',
    name: 'Dev - Pager'
  }, {
    key: '3add6355-37d0-4f65-9bff-54ebe6761eca',
    name: 'Dev - Regence'
  }, {
    key: '47d02f71-acc1-4003-af0c-c4263370b214',
    name: 'Dev - Seamless'
  }, {
    key: 'a20347b6-b6d6-45e6-9b37-4f35c8800602',
    name: 'Dev - Sura'
  }, {
    key: '65dd694d-b69c-462e-9821-4681a860b209',
    name: 'Dev - Triple-s'
  }, {
    key: '185d743e-a815-43f9-9be8-46636ce8798e',
    name: 'Dev - UHC'
  }, {
    key: '39aad322-f8b6-4f95-951e-4d38c0645660',
    name: 'Dev - Baptist'
  }],
  qa: [
  // --------- QA -----------
  {
    key: 'c429175a-6fea-426e-aa6a-97e989a7324f',
    name: 'QA - Asuris'
  }, {
    key: '4f1330aa-166a-4f99-9ed2-20a71dd1b17b',
    name: 'QA - FB'
  }, {
    key: '737fc632-0a91-4397-b801-d985638990a5',
    name: 'QA - Horizon',
    token: true,
    endpoint: 'https://hrz-qa-api-horizon.pagerinc.com'
  }, {
    key: '8453930c-5816-4d2e-8c38-eb032d95ea11',
    name: 'QA - Horizon (Medicaid)'
  }, {
    key: '29fc084b-cfd0-4f41-924d-8652aa90a4bf',
    name: 'QA - Pager Sandbox Org'
  }, {
    key: '81acb2bc-cfaa-4640-bc88-65afb4374557',
    name: 'QA - Regence'
  }, {
    key: '9888829d-a0b9-45a8-92f8-fbfa1a1b5dab',
    name: 'QA - Seamless'
  }, {
    key: '0446fa39-096c-431b-bfb8-40ebe72e944b',
    name: 'QA - Sura'
  }, {
    key: '98d85b5e-86cf-4059-88fc-3a9219708cb4',
    name: 'QA - Triple-s'
  }, {
    key: '4f0695ec-4cae-4d7a-8b1d-f62a237eb066',
    name: 'QA - UHC'
  }, {
    key: '7aecd91d-5a97-4ae0-a24d-c23dc2c85962',
    name: 'QA - Baptist'
  }],
  stage: [
  // --------- STAGING -----------
  {
    key: '010ebb5a-9882-47cc-b9fe-8ed882378588',
    name: 'Staging - Asuris'
  }, {
    key: '59e0f4cb-8a84-4912-8885-1fa72951d637',
    name: 'Staging - FB'
  }, {
    key: 'f808312e-02d9-498c-bcb8-8da453e0678f',
    name: 'Staging - Horizon',
    token: true,
    endpoint: 'https://hrz-stage-api-horizon.pagerinc.com'
  }, {
    key: 'ddbde38a-26ed-4bf5-86c8-f2b8d54325cf',
    name: 'Staging - Horizon (Medicaid)'
  }, {
    key: '4386fc91-e8b5-4989-8495-97294619cc37',
    name: 'Staging Latest - Horizon',
    token: true,
    endpoint: 'https://pgr-stage-latest-api.pagerinc.com'
  }, {
    key: '22745530-9520-4937-bb54-7d04ccdd418b',
    name: 'Staging - Pager Sandbox Org'
  }, {
    key: 'cb6cea21-cf06-4b19-92db-eca662a1a019',
    name: 'Staging - Regence'
  }, {
    key: '4b2432d1-e8e6-4089-bad2-17d91eca3abc',
    name: 'Staging - Seamless'
  }, {
    key: '67def52f-54fd-44f9-9998-896fabefeeef',
    name: 'Staging - Sura'
  }, {
    key: 'db4bd298-fb8a-4fcd-8ae3-f66eef734d31',
    name: 'Staging Latest - Sura'
  }, {
    key: 'd598e53b-7593-4815-8228-c4c76803aa1b',
    name: 'Staging - Triple-s'
  }, {
    key: '72049c21-0ec5-4e07-8608-517dd7797522',
    name: 'Staging - Pager Demo'
  }, {
    key: '152a59f6-3a1a-453d-8a4e-b2544b27d20d',
    name: 'Staging - Baptist'
  }],
  sdx: [
  // --------- SANDBOX -----------
  {
    key: 'f3735f48-dbb6-4b2e-82c8-c7414bdf05c8',
    name: 'Sandbox - Asuris'
  }, {
    key: '776b6df2-74a5-4759-83a3-130dacbd1704',
    name: 'Sandbox - Seamless'
  }, {
    key: '179dfe57-eeb7-47c5-a6c6-f7332a12c25d',
    name: 'Sandbox - Sura'
  }, {
    key: 'e0fbc6b9-0629-4d5d-a2ae-89e6daa7ceb1',
    name: 'Sandbox - Baptist'
  }]
};