/* components/Header.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	add_render_callback,
	append_dev,
	attr_dev,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element,
	globals,
	init,
	insert_dev,
	listen_dev,
	noop,
	prop_dev,
	run_all,
	safe_not_equal,
	select_option,
	select_value,
	set_data_dev,
	space,
	text,
	validate_each_argument,
	validate_slots
} from "svelte/internal";

const { Object: Object_1, console: console_1 } = globals;
import { onMount } from 'svelte';
import { each } from 'svelte/internal';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
const file = "components/Header.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[6] = list[i];
	return child_ctx;
}

// (35:4) {#if ver}
function create_if_block_1(ctx) {
	let div;
	let small;
	let t;

	function select_block_type(ctx, dirty) {
		if (/*versions*/ ctx[1]) return create_if_block_2;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			div = element("div");
			small = element("small");
			t = text("Widget\n          ");
			if_block.c();
			attr_dev(small, "class", "flex gap-2 bg-gray-200 p-1 rounded text-sm self-center dark:bg-gray-700 dark:text-white;");
			add_location(small, file, 36, 8, 911);
			attr_dev(div, "class", "flex");
			add_location(div, file, 35, 6, 884);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, small);
			append_dev(small, t);
			if_block.m(small, null);
		},
		p: function update(ctx, dirty) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(small, null);
				}
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if_block.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(35:4) {#if ver}",
		ctx
	});

	return block;
}

// (51:10) {:else}
function create_else_block(ctx) {
	let t0;
	let t1;

	const block = {
		c: function create() {
			t0 = text("v");
			t1 = text(/*ver*/ ctx[3]);
		},
		m: function mount(target, anchor) {
			insert_dev(target, t0, anchor);
			insert_dev(target, t1, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(t0);
			if (detaching) detach_dev(t1);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(51:10) {:else}",
		ctx
	});

	return block;
}

// (41:10) {#if versions }
function create_if_block_2(ctx) {
	let select;
	let mounted;
	let dispose;
	let each_value = Object.keys(/*versions*/ ctx[1]).sort().reverse();
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			select = element("select");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr_dev(select, "class", "p-0 border-0 text-pager-orange version-selector");
			if (/*selectedValue*/ ctx[2] === void 0) add_render_callback(() => /*select_change_handler*/ ctx[5].call(select));
			add_location(select, file, 41, 12, 1090);
		},
		m: function mount(target, anchor) {
			insert_dev(target, select, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(select, null);
			}

			select_option(select, /*selectedValue*/ ctx[2]);

			if (!mounted) {
				dispose = [
					listen_dev(select, "change", /*handleOnChangeSelect*/ ctx[4], false, false, false),
					listen_dev(select, "change", /*select_change_handler*/ ctx[5])
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty & /*Object, versions*/ 2) {
				each_value = Object.keys(/*versions*/ ctx[1]).sort().reverse();
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*selectedValue, Object, versions*/ 6) {
				select_option(select, /*selectedValue*/ ctx[2]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(select);
			destroy_each(each_blocks, detaching);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(41:10) {#if versions }",
		ctx
	});

	return block;
}

// (47:14) {#each Object.keys(versions).sort().reverse() as version}
function create_each_block(ctx) {
	let option;
	let t0;
	let t1_value = /*version*/ ctx[6] + "";
	let t1;
	let option_value_value;

	const block = {
		c: function create() {
			option = element("option");
			t0 = text("v");
			t1 = text(t1_value);
			option.__value = option_value_value = /*version*/ ctx[6];
			option.value = option.__value;
			attr_dev(option, "class", "text-pager-orange");
			add_location(option, file, 47, 16, 1358);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option, anchor);
			append_dev(option, t0);
			append_dev(option, t1);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*versions*/ 2 && t1_value !== (t1_value = /*version*/ ctx[6] + "")) set_data_dev(t1, t1_value);

			if (dirty & /*versions*/ 2 && option_value_value !== (option_value_value = /*version*/ ctx[6])) {
				prop_dev(option, "__value", option_value_value);
				option.value = option.__value;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(option);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(47:14) {#each Object.keys(versions).sort().reverse() as version}",
		ctx
	});

	return block;
}

// (58:2) {#if isDev}
function create_if_block(ctx) {
	let div;
	let span;

	const block = {
		c: function create() {
			div = element("div");
			span = element("span");
			span.textContent = "This is a development preview.";
			attr_dev(span, "class", "font-bold");
			add_location(span, file, 59, 6, 1692);
			attr_dev(div, "class", "bg-yellow-600 p-1 pl-3 my-2 rounded text-sm dark:bg-orange-200 dark:text-white;");
			add_location(div, file, 58, 4, 1592);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, span);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(58:2) {#if isDev}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let h1;
	let span;
	let t1;
	let t2;
	let t3;
	let if_block0 = /*ver*/ ctx[3] && create_if_block_1(ctx);
	let if_block1 = /*isDev*/ ctx[0] && create_if_block(ctx);

	const block = {
		c: function create() {
			div1 = element("div");
			div0 = element("div");
			h1 = element("h1");
			span = element("span");
			span.textContent = "Pager";
			t1 = text("\n      WebSDK Widget Playground");
			t2 = space();
			if (if_block0) if_block0.c();
			t3 = space();
			if (if_block1) if_block1.c();
			attr_dev(span, "class", "text-pager-orange");
			add_location(span, file, 31, 6, 778);
			add_location(h1, file, 30, 4, 767);
			attr_dev(div0, "class", "flex justify-between");
			add_location(div0, file, 29, 2, 728);
			add_location(div1, file, 28, 0, 720);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			append_dev(div1, div0);
			append_dev(div0, h1);
			append_dev(h1, span);
			append_dev(h1, t1);
			append_dev(div0, t2);
			if (if_block0) if_block0.m(div0, null);
			append_dev(div1, t3);
			if (if_block1) if_block1.m(div1, null);
		},
		p: function update(ctx, [dirty]) {
			if (/*ver*/ ctx[3]) if_block0.p(ctx, dirty);

			if (/*isDev*/ ctx[0]) {
				if (if_block1) {
					
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(div1, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('Header', slots, []);
	let { isDev = false } = $$props;
	let versions = null;
	const ver = process.env.WIDGET_VERSION || null;
	let selectedValue = ver && ver.trim();

	onMount(async () => {
		try {
			const db = getFirestore();

			if (db) {
				const documentRef = doc(db, 'web', 'versions-preview');
				const document = await getDoc(documentRef);
				$$invalidate(1, versions = document.data());
			}
		} catch(e) {
			console.log(e);
		}
	});

	function handleOnChangeSelect({ target }) {
		window.location.href = versions?.[target.value]?.preview_url;
	}

	const writable_props = ['isDev'];

	Object_1.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console_1.warn(`<Header> was created with unknown prop '${key}'`);
	});

	function select_change_handler() {
		selectedValue = select_value(this);
		$$invalidate(2, selectedValue);
		$$invalidate(1, versions);
	}

	$$self.$$set = $$props => {
		if ('isDev' in $$props) $$invalidate(0, isDev = $$props.isDev);
	};

	$$self.$capture_state = () => ({
		onMount,
		each,
		getFirestore,
		doc,
		getDoc,
		isDev,
		versions,
		ver,
		selectedValue,
		handleOnChangeSelect
	});

	$$self.$inject_state = $$props => {
		if ('isDev' in $$props) $$invalidate(0, isDev = $$props.isDev);
		if ('versions' in $$props) $$invalidate(1, versions = $$props.versions);
		if ('selectedValue' in $$props) $$invalidate(2, selectedValue = $$props.selectedValue);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [
		isDev,
		versions,
		selectedValue,
		ver,
		handleOnChangeSelect,
		select_change_handler
	];
}

class Header extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, { isDev: 0 });

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "Header",
			options,
			id: create_fragment.name
		});
	}

	get isDev() {
		throw new Error("<Header>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set isDev(value) {
		throw new Error("<Header>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}
}

export default Header;