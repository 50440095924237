/* components/panels/Tracking.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	create_component,
	destroy_component,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	listen_dev,
	mount_component,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out,
	validate_slots
} from "svelte/internal";

import Button from '../common/Button.svelte';
import { get } from 'svelte/store';
import { getLocalStorage, setTrackingOptions } from '../../actions.js';
import { LOCAL_STORAGE_INTERNAL_MP_FLAG } from '../../constants';
const file = "components/panels/Tracking.svelte";

// (31:2) <Button id="applyTrackingBtn" className="self-end" on:click={handleSubmit}>
function create_default_slot(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Apply");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot.name,
		type: "slot",
		source: "(31:2) <Button id=\\\"applyTrackingBtn\\\" className=\\\"self-end\\\" on:click={handleSubmit}>",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let ul;
	let li;
	let input;
	let input_value_value;
	let label;
	let t1;
	let button;
	let current;
	let mounted;
	let dispose;

	button = new Button({
			props: {
				id: "applyTrackingBtn",
				className: "self-end",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button.$on("click", /*handleSubmit*/ ctx[1]);

	const block = {
		c: function create() {
			div1 = element("div");
			div0 = element("div");
			ul = element("ul");
			li = element("li");
			input = element("input");
			label = element("label");
			label.textContent = "Track to Internal Mixpanel (SDK Internal)";
			t1 = space();
			create_component(button.$$.fragment);
			attr_dev(input, "class", "shadow-none mx-1");
			attr_dev(input, "name", "track-internal");
			attr_dev(input, "id", "track-internal");
			input.__value = input_value_value = true;
			input.value = input.__value;
			attr_dev(input, "type", "checkbox");
			add_location(input, file, 19, 8, 611);
			attr_dev(label, "for", "track-internal");
			add_location(label, file, 26, 10, 813);
			attr_dev(li, "class", "flex");
			add_location(li, file, 18, 6, 585);
			add_location(ul, file, 17, 4, 574);
			attr_dev(div0, "class", "flex mt-1 w-full");
			add_location(div0, file, 16, 2, 539);
			attr_dev(div1, "id", "trackingContainer");
			attr_dev(div1, "class", "collapsable-content flex flex-col my-2 space-y-4");
			add_location(div1, file, 15, 0, 451);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			append_dev(div1, div0);
			append_dev(div0, ul);
			append_dev(ul, li);
			append_dev(li, input);
			input.checked = /*trackInternal*/ ctx[0];
			append_dev(li, label);
			append_dev(div1, t1);
			mount_component(button, div1, null);
			current = true;

			if (!mounted) {
				dispose = listen_dev(input, "change", /*input_change_handler*/ ctx[2]);
				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*trackInternal*/ 1) {
				input.checked = /*trackInternal*/ ctx[0];
			}

			const button_changes = {};

			if (dirty & /*$$scope*/ 16) {
				button_changes.$$scope = { dirty, ctx };
			}

			button.$set(button_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
			destroy_component(button);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('Tracking', slots, []);
	let tracking = localStorage.getItem(LOCAL_STORAGE_INTERNAL_MP_FLAG);
	let trackInternal = tracking === 'true' ? true : false;

	function handleSubmit() {
		setTrackingOptions(trackInternal);
	}

	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<Tracking> was created with unknown prop '${key}'`);
	});

	function input_change_handler() {
		trackInternal = this.checked;
		$$invalidate(0, trackInternal);
	}

	$$self.$capture_state = () => ({
		Button,
		get,
		getLocalStorage,
		setTrackingOptions,
		LOCAL_STORAGE_INTERNAL_MP_FLAG,
		tracking,
		trackInternal,
		handleSubmit
	});

	$$self.$inject_state = $$props => {
		if ('tracking' in $$props) tracking = $$props.tracking;
		if ('trackInternal' in $$props) $$invalidate(0, trackInternal = $$props.trackInternal);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [trackInternal, handleSubmit, input_change_handler];
}

class Tracking extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "Tracking",
			options,
			id: create_fragment.name
		});
	}
}

export default Tracking;