/* components/panels/Encounters.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	listen_dev,
	mount_component,
	prop_dev,
	safe_not_equal,
	set_data_dev,
	space,
	text,
	transition_in,
	transition_out,
	validate_each_argument,
	validate_slots,
	validate_store
} from "svelte/internal";

import Button from '../common/Button.svelte';
import { encounters } from '../../store';
import { get } from 'svelte/store';
import { getEncounterHistory } from '../../actions.js';
const file = "components/panels/Encounters.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[5] = list[i];
	return child_ctx;
}

// (17:2) {#if $encounters}
function create_if_block(ctx) {
	let ul;
	let each_value = /*$encounters*/ ctx[1];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			ul = element("ul");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			add_location(ul, file, 17, 4, 406);
		},
		m: function mount(target, anchor) {
			insert_dev(target, ul, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul, null);
			}
		},
		p: function update(ctx, dirty) {
			if (dirty & /*JSON, $encounters, undefined, active*/ 3) {
				each_value = /*$encounters*/ ctx[1];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(ul);
			destroy_each(each_blocks, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(17:2) {#if $encounters}",
		ctx
	});

	return block;
}

// (26:10) {#if item.id === active}
function create_if_block_1(ctx) {
	let textarea;
	let textarea_value_value;

	const block = {
		c: function create() {
			textarea = element("textarea");
			textarea.readOnly = true;
			textarea.value = textarea_value_value = JSON.stringify(/*item*/ ctx[5], undefined, 2);
			add_location(textarea, file, 26, 12, 780);
		},
		m: function mount(target, anchor) {
			insert_dev(target, textarea, anchor);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*$encounters*/ 2 && textarea_value_value !== (textarea_value_value = JSON.stringify(/*item*/ ctx[5], undefined, 2))) {
				prop_dev(textarea, "value", textarea_value_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(textarea);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(26:10) {#if item.id === active}",
		ctx
	});

	return block;
}

// (19:6) {#each $encounters as item}
function create_each_block(ctx) {
	let li;
	let span;
	let t0_value = /*item*/ ctx[5].createdAt + "";
	let t0;
	let t1;
	let t2_value = /*item*/ ctx[5].id + "";
	let t2;
	let t3;
	let t4;
	let t5;
	let mounted;
	let dispose;

	function click_handler() {
		return /*click_handler*/ ctx[3](/*item*/ ctx[5]);
	}

	let if_block = /*item*/ ctx[5].id === /*active*/ ctx[0] && create_if_block_1(ctx);

	const block = {
		c: function create() {
			li = element("li");
			span = element("span");
			t0 = text(t0_value);
			t1 = text(" - (");
			t2 = text(t2_value);
			t3 = text(")");
			t4 = space();
			if (if_block) if_block.c();
			t5 = space();
			attr_dev(span, "class", "block cursor-pointer text-sm");
			add_location(span, file, 20, 10, 527);
			attr_dev(li, "class", "p-1 my-2 bg-white dark:bg-gray-800 dark:text-white");
			add_location(li, file, 19, 8, 453);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, span);
			append_dev(span, t0);
			append_dev(span, t1);
			append_dev(span, t2);
			append_dev(span, t3);
			append_dev(li, t4);
			if (if_block) if_block.m(li, null);
			append_dev(li, t5);

			if (!mounted) {
				dispose = listen_dev(span, "click", click_handler, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty & /*$encounters*/ 2 && t0_value !== (t0_value = /*item*/ ctx[5].createdAt + "")) set_data_dev(t0, t0_value);
			if (dirty & /*$encounters*/ 2 && t2_value !== (t2_value = /*item*/ ctx[5].id + "")) set_data_dev(t2, t2_value);

			if (/*item*/ ctx[5].id === /*active*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block_1(ctx);
					if_block.c();
					if_block.m(li, t5);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			if (if_block) if_block.d();
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(19:6) {#each $encounters as item}",
		ctx
	});

	return block;
}

// (34:2) <Button id="getEncountersBtn" className="self-end" on:click={() => handleSubmit()}>
function create_default_slot(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Get Encounters");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot.name,
		type: "slot",
		source: "(34:2) <Button id=\\\"getEncountersBtn\\\" className=\\\"self-end\\\" on:click={() => handleSubmit()}>",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let t;
	let button;
	let current;
	let if_block = /*$encounters*/ ctx[1] && create_if_block(ctx);

	button = new Button({
			props: {
				id: "getEncountersBtn",
				className: "self-end",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button.$on("click", /*click_handler_1*/ ctx[4]);

	const block = {
		c: function create() {
			div = element("div");
			if (if_block) if_block.c();
			t = space();
			create_component(button.$$.fragment);
			attr_dev(div, "id", "encountersContainer");
			attr_dev(div, "class", "collapsable-content flex flex-col my-2 space-y-4");
			add_location(div, file, 13, 0, 290);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			append_dev(div, t);
			mount_component(button, div, null);
			current = true;
		},
		p: function update(ctx, [dirty]) {
			if (/*$encounters*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, t);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			const button_changes = {};

			if (dirty & /*$$scope*/ 256) {
				button_changes.$$scope = { dirty, ctx };
			}

			button.$set(button_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			destroy_component(button);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $encounters;
	validate_store(encounters, 'encounters');
	component_subscribe($$self, encounters, $$value => $$invalidate(1, $encounters = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('Encounters', slots, []);

	function handleSubmit() {
		getEncounterHistory();
	}

	let active = null;
	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<Encounters> was created with unknown prop '${key}'`);
	});

	const click_handler = item => active === item.id
	? $$invalidate(0, active = null)
	: $$invalidate(0, active = item.id);

	const click_handler_1 = () => handleSubmit();

	$$self.$capture_state = () => ({
		Button,
		encounters,
		get,
		getEncounterHistory,
		handleSubmit,
		active,
		$encounters
	});

	$$self.$inject_state = $$props => {
		if ('active' in $$props) $$invalidate(0, active = $$props.active);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [active, $encounters, handleSubmit, click_handler, click_handler_1];
}

class Encounters extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "Encounters",
			options,
			id: create_fragment.name
		});
	}
}

export default Encounters;