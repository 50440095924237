/* components/common/Collapsable.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	check_outros,
	create_slot,
	detach_dev,
	dispatch_dev,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	group_outros,
	init,
	insert_dev,
	listen_dev,
	safe_not_equal,
	set_data_dev,
	space,
	text,
	transition_in,
	transition_out,
	update_slot_base,
	validate_slots
} from "svelte/internal";

const file = "components/common/Collapsable.svelte";

// (18:4) {:else}
function create_else_block(ctx) {
	let span;

	const block = {
		c: function create() {
			span = element("span");
			span.textContent = "▲";
			add_location(span, file, 18, 6, 465);
		},
		m: function mount(target, anchor) {
			insert_dev(target, span, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(span);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(18:4) {:else}",
		ctx
	});

	return block;
}

// (16:4) {#if collapsed}
function create_if_block_1(ctx) {
	let span;

	const block = {
		c: function create() {
			span = element("span");
			span.textContent = "▼";
			add_location(span, file, 16, 6, 426);
		},
		m: function mount(target, anchor) {
			insert_dev(target, span, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(span);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(16:4) {#if collapsed}",
		ctx
	});

	return block;
}

// (22:2) {#if !collapsed}
function create_if_block(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[6].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[5], null);

	const block = {
		c: function create() {
			if (default_slot) default_slot.c();
		},
		m: function mount(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p: function update(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 32)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[5],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[5])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[5], dirty, null),
						null
					);
				}
			}
		},
		i: function intro(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(22:2) {#if !collapsed}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let span;
	let t0;
	let span_class_value;
	let t1;
	let t2;
	let div1_class_value;
	let current;
	let mounted;
	let dispose;

	function select_block_type(ctx, dirty) {
		if (/*collapsed*/ ctx[3]) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block0 = current_block_type(ctx);
	let if_block1 = !/*collapsed*/ ctx[3] && create_if_block(ctx);

	const block = {
		c: function create() {
			div1 = element("div");
			div0 = element("div");
			span = element("span");
			t0 = text(/*title*/ ctx[0]);
			t1 = space();
			if_block0.c();
			t2 = space();
			if (if_block1) if_block1.c();
			attr_dev(span, "class", span_class_value = /*bold*/ ctx[2] ? 'font-bold' : '');
			add_location(span, file, 14, 4, 347);
			attr_dev(div0, "id", /*title*/ ctx[0]);
			attr_dev(div0, "class", "collapsable-title flex justify-between");
			add_location(div0, file, 13, 2, 261);
			attr_dev(div1, "class", div1_class_value = "collapsable flex flex-col p-2 my-3 w-full " + (/*ghost*/ ctx[1] ? 'ghost' : ''));
			add_location(div1, file, 12, 0, 180);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			append_dev(div1, div0);
			append_dev(div0, span);
			append_dev(span, t0);
			append_dev(div0, t1);
			if_block0.m(div0, null);
			append_dev(div1, t2);
			if (if_block1) if_block1.m(div1, null);
			current = true;

			if (!mounted) {
				dispose = listen_dev(div0, "click", /*toggle*/ ctx[4], false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (!current || dirty & /*title*/ 1) set_data_dev(t0, /*title*/ ctx[0]);

			if (!current || dirty & /*bold*/ 4 && span_class_value !== (span_class_value = /*bold*/ ctx[2] ? 'font-bold' : '')) {
				attr_dev(span, "class", span_class_value);
			}

			if (current_block_type !== (current_block_type = select_block_type(ctx, dirty))) {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(div0, null);
				}
			}

			if (!current || dirty & /*title*/ 1) {
				attr_dev(div0, "id", /*title*/ ctx[0]);
			}

			if (!/*collapsed*/ ctx[3]) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*collapsed*/ 8) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div1, null);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (!current || dirty & /*ghost*/ 2 && div1_class_value !== (div1_class_value = "collapsable flex flex-col p-2 my-3 w-full " + (/*ghost*/ ctx[1] ? 'ghost' : ''))) {
				attr_dev(div1, "class", div1_class_value);
			}
		},
		i: function intro(local) {
			if (current) return;
			transition_in(if_block1);
			current = true;
		},
		o: function outro(local) {
			transition_out(if_block1);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
			if_block0.d();
			if (if_block1) if_block1.d();
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('Collapsable', slots, ['default']);
	let { title = '' } = $$props;
	let { ghost = false } = $$props;
	let { bold = false } = $$props;
	let collapsed = true;

	function toggle() {
		$$invalidate(3, collapsed = !collapsed);
	}

	const writable_props = ['title', 'ghost', 'bold'];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<Collapsable> was created with unknown prop '${key}'`);
	});

	$$self.$$set = $$props => {
		if ('title' in $$props) $$invalidate(0, title = $$props.title);
		if ('ghost' in $$props) $$invalidate(1, ghost = $$props.ghost);
		if ('bold' in $$props) $$invalidate(2, bold = $$props.bold);
		if ('$$scope' in $$props) $$invalidate(5, $$scope = $$props.$$scope);
	};

	$$self.$capture_state = () => ({ title, ghost, bold, collapsed, toggle });

	$$self.$inject_state = $$props => {
		if ('title' in $$props) $$invalidate(0, title = $$props.title);
		if ('ghost' in $$props) $$invalidate(1, ghost = $$props.ghost);
		if ('bold' in $$props) $$invalidate(2, bold = $$props.bold);
		if ('collapsed' in $$props) $$invalidate(3, collapsed = $$props.collapsed);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [title, ghost, bold, collapsed, toggle, $$scope, slots];
}

class Collapsable extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, { title: 0, ghost: 1, bold: 2 });

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "Collapsable",
			options,
			id: create_fragment.name
		});
	}

	get title() {
		throw new Error("<Collapsable>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set title(value) {
		throw new Error("<Collapsable>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	get ghost() {
		throw new Error("<Collapsable>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set ghost(value) {
		throw new Error("<Collapsable>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	get bold() {
		throw new Error("<Collapsable>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set bold(value) {
		throw new Error("<Collapsable>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}
}

export default Collapsable;