/* components/panels/Getters.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	component_subscribe,
	create_component,
	destroy_component,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	mount_component,
	prop_dev,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out,
	validate_slots,
	validate_store
} from "svelte/internal";

import { createEventDispatcher, onMount } from 'svelte';
import { executeGetter } from '../../actions';
import { widgetGetterResponse } from '../../store';
import Button from '../common/Button.svelte';
const file = "components/panels/Getters.svelte";

// (10:4) <Button id="isVisibleBtn" on:click={() => executeGetter('isVisible')}>
function create_default_slot_4(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("isVisible");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_4.name,
		type: "slot",
		source: "(10:4) <Button id=\\\"isVisibleBtn\\\" on:click={() => executeGetter('isVisible')}>",
		ctx
	});

	return block;
}

// (11:4) <Button id="isOpenedBtn" on:click={() => executeGetter('isOpened')}>
function create_default_slot_3(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("isOpened");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_3.name,
		type: "slot",
		source: "(11:4) <Button id=\\\"isOpenedBtn\\\" on:click={() => executeGetter('isOpened')}>",
		ctx
	});

	return block;
}

// (12:4) <Button id="getClientBtn" on:click={() => executeGetter('getClient')}>
function create_default_slot_2(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("getClient");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_2.name,
		type: "slot",
		source: "(12:4) <Button id=\\\"getClientBtn\\\" on:click={() => executeGetter('getClient')}>",
		ctx
	});

	return block;
}

// (13:4) <Button id="getCurrentEncounterBtn" on:click={() => executeGetter('getCurrentEncounter')}       >
function create_default_slot_1(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("getCurrentEncounter");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_1.name,
		type: "slot",
		source: "(13:4) <Button id=\\\"getCurrentEncounterBtn\\\" on:click={() => executeGetter('getCurrentEncounter')}       >",
		ctx
	});

	return block;
}

// (16:4) <Button id="getTokenBtn" on:click={() => executeGetter('getToken')}>
function create_default_slot(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("getToken");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot.name,
		type: "slot",
		source: "(16:4) <Button id=\\\"getTokenBtn\\\" on:click={() => executeGetter('getToken')}>",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let button0;
	let t0;
	let button1;
	let t1;
	let button2;
	let t2;
	let button3;
	let t3;
	let button4;
	let t4;
	let label;
	let t5;
	let textarea;
	let textarea_value_value;
	let current;

	button0 = new Button({
			props: {
				id: "isVisibleBtn",
				$$slots: { default: [create_default_slot_4] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button0.$on("click", /*click_handler*/ ctx[1]);

	button1 = new Button({
			props: {
				id: "isOpenedBtn",
				$$slots: { default: [create_default_slot_3] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button1.$on("click", /*click_handler_1*/ ctx[2]);

	button2 = new Button({
			props: {
				id: "getClientBtn",
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button2.$on("click", /*click_handler_2*/ ctx[3]);

	button3 = new Button({
			props: {
				id: "getCurrentEncounterBtn",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button3.$on("click", /*click_handler_3*/ ctx[4]);

	button4 = new Button({
			props: {
				id: "getTokenBtn",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button4.$on("click", /*click_handler_4*/ ctx[5]);

	const block = {
		c: function create() {
			div1 = element("div");
			div0 = element("div");
			create_component(button0.$$.fragment);
			t0 = space();
			create_component(button1.$$.fragment);
			t1 = space();
			create_component(button2.$$.fragment);
			t2 = space();
			create_component(button3.$$.fragment);
			t3 = space();
			create_component(button4.$$.fragment);
			t4 = space();
			label = element("label");
			t5 = text("Value:\n    ");
			textarea = element("textarea");
			attr_dev(div0, "class", "flex-1 space-x-3 flex-row");
			add_location(div0, file, 8, 2, 320);
			textarea.readOnly = true;

			textarea.value = textarea_value_value = /*$widgetGetterResponse*/ ctx[0]
			? /*$widgetGetterResponse*/ ctx[0]
			: /*$widgetGetterResponse*/ ctx[0] === false ? false : '';

			add_location(textarea, file, 19, 4, 895);
			add_location(label, file, 17, 2, 872);
			attr_dev(div1, "id", "appearanceContainer");
			attr_dev(div1, "class", "collapsable-content flex flex-col my-2 space-y-4");
			add_location(div1, file, 7, 0, 230);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			append_dev(div1, div0);
			mount_component(button0, div0, null);
			append_dev(div0, t0);
			mount_component(button1, div0, null);
			append_dev(div0, t1);
			mount_component(button2, div0, null);
			append_dev(div0, t2);
			mount_component(button3, div0, null);
			append_dev(div0, t3);
			mount_component(button4, div0, null);
			append_dev(div1, t4);
			append_dev(div1, label);
			append_dev(label, t5);
			append_dev(label, textarea);
			current = true;
		},
		p: function update(ctx, [dirty]) {
			const button0_changes = {};

			if (dirty & /*$$scope*/ 64) {
				button0_changes.$$scope = { dirty, ctx };
			}

			button0.$set(button0_changes);
			const button1_changes = {};

			if (dirty & /*$$scope*/ 64) {
				button1_changes.$$scope = { dirty, ctx };
			}

			button1.$set(button1_changes);
			const button2_changes = {};

			if (dirty & /*$$scope*/ 64) {
				button2_changes.$$scope = { dirty, ctx };
			}

			button2.$set(button2_changes);
			const button3_changes = {};

			if (dirty & /*$$scope*/ 64) {
				button3_changes.$$scope = { dirty, ctx };
			}

			button3.$set(button3_changes);
			const button4_changes = {};

			if (dirty & /*$$scope*/ 64) {
				button4_changes.$$scope = { dirty, ctx };
			}

			button4.$set(button4_changes);

			if (!current || dirty & /*$widgetGetterResponse*/ 1 && textarea_value_value !== (textarea_value_value = /*$widgetGetterResponse*/ ctx[0]
			? /*$widgetGetterResponse*/ ctx[0]
			: /*$widgetGetterResponse*/ ctx[0] === false ? false : '')) {
				prop_dev(textarea, "value", textarea_value_value);
			}
		},
		i: function intro(local) {
			if (current) return;
			transition_in(button0.$$.fragment, local);
			transition_in(button1.$$.fragment, local);
			transition_in(button2.$$.fragment, local);
			transition_in(button3.$$.fragment, local);
			transition_in(button4.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(button0.$$.fragment, local);
			transition_out(button1.$$.fragment, local);
			transition_out(button2.$$.fragment, local);
			transition_out(button3.$$.fragment, local);
			transition_out(button4.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
			destroy_component(button0);
			destroy_component(button1);
			destroy_component(button2);
			destroy_component(button3);
			destroy_component(button4);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $widgetGetterResponse;
	validate_store(widgetGetterResponse, 'widgetGetterResponse');
	component_subscribe($$self, widgetGetterResponse, $$value => $$invalidate(0, $widgetGetterResponse = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('Getters', slots, []);
	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<Getters> was created with unknown prop '${key}'`);
	});

	const click_handler = () => executeGetter('isVisible');
	const click_handler_1 = () => executeGetter('isOpened');
	const click_handler_2 = () => executeGetter('getClient');
	const click_handler_3 = () => executeGetter('getCurrentEncounter');
	const click_handler_4 = () => executeGetter('getToken');

	$$self.$capture_state = () => ({
		createEventDispatcher,
		onMount,
		executeGetter,
		widgetGetterResponse,
		Button,
		$widgetGetterResponse
	});

	return [
		$widgetGetterResponse,
		click_handler,
		click_handler_1,
		click_handler_2,
		click_handler_3,
		click_handler_4
	];
}

class Getters extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "Getters",
			options,
			id: create_fragment.name
		});
	}
}

export default Getters;