/* components/panels/EventLog.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	component_subscribe,
	create_component,
	destroy_component,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	mount_component,
	prop_dev,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out,
	validate_slots,
	validate_store
} from "svelte/internal";

import { onMount } from 'svelte';
import { widgetEvents } from '../../store';
import { clearEventLog } from '../../actions';
import Button from '../common/Button.svelte';
const file = "components/panels/EventLog.svelte";

// (11:4) <Button className="ml-3" on:click={() => clearEventLog()}>
function create_default_slot(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Clear");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot.name,
		type: "slot",
		source: "(11:4) <Button className=\\\"ml-3\\\" on:click={() => clearEventLog()}>",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div1;
	let textarea;
	let textarea_value_value;
	let t;
	let div0;
	let button;
	let current;

	button = new Button({
			props: {
				className: "ml-3",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button.$on("click", /*click_handler*/ ctx[1]);

	const block = {
		c: function create() {
			div1 = element("div");
			textarea = element("textarea");
			t = space();
			div0 = element("div");
			create_component(button.$$.fragment);
			textarea.readOnly = true;
			textarea.value = textarea_value_value = JSON.stringify(/*$widgetEvents*/ ctx[0], null, 2);
			add_location(textarea, file, 8, 2, 289);
			attr_dev(div0, "class", "flex self-end");
			add_location(div0, file, 9, 2, 360);
			attr_dev(div1, "id", "appearanceContainer");
			attr_dev(div1, "class", "collapsable-content flex flex-col my-2 space-y-4");
			add_location(div1, file, 7, 0, 199);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			append_dev(div1, textarea);
			append_dev(div1, t);
			append_dev(div1, div0);
			mount_component(button, div0, null);
			current = true;
		},
		p: function update(ctx, [dirty]) {
			if (!current || dirty & /*$widgetEvents*/ 1 && textarea_value_value !== (textarea_value_value = JSON.stringify(/*$widgetEvents*/ ctx[0], null, 2))) {
				prop_dev(textarea, "value", textarea_value_value);
			}

			const button_changes = {};

			if (dirty & /*$$scope*/ 4) {
				button_changes.$$scope = { dirty, ctx };
			}

			button.$set(button_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
			destroy_component(button);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $widgetEvents;
	validate_store(widgetEvents, 'widgetEvents');
	component_subscribe($$self, widgetEvents, $$value => $$invalidate(0, $widgetEvents = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('EventLog', slots, []);
	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<EventLog> was created with unknown prop '${key}'`);
	});

	const click_handler = () => clearEventLog();

	$$self.$capture_state = () => ({
		onMount,
		widgetEvents,
		clearEventLog,
		Button,
		$widgetEvents
	});

	return [$widgetEvents, click_handler];
}

class EventLog extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "EventLog",
			options,
			id: create_fragment.name
		});
	}
}

export default EventLog;