/* components/common/MoonIcon.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	detach_dev,
	dispatch_dev,
	init,
	insert_dev,
	noop,
	safe_not_equal,
	set_style,
	svg_element,
	validate_slots
} from "svelte/internal";

const file = "components/common/MoonIcon.svelte";

function create_fragment(ctx) {
	let svg;
	let path;
	let g0;
	let g1;
	let g2;
	let g3;
	let g4;
	let g5;
	let g6;
	let g7;
	let g8;
	let g9;
	let g10;
	let g11;
	let g12;
	let g13;
	let g14;

	const block = {
		c: function create() {
			svg = svg_element("svg");
			path = svg_element("path");
			g0 = svg_element("g");
			g1 = svg_element("g");
			g2 = svg_element("g");
			g3 = svg_element("g");
			g4 = svg_element("g");
			g5 = svg_element("g");
			g6 = svg_element("g");
			g7 = svg_element("g");
			g8 = svg_element("g");
			g9 = svg_element("g");
			g10 = svg_element("g");
			g11 = svg_element("g");
			g12 = svg_element("g");
			g13 = svg_element("g");
			g14 = svg_element("g");
			set_style(path, "fill", "currentColor");
			attr_dev(path, "d", "M29,28c0-11.917,7.486-22.112,18-26.147C43.892,0.66,40.523,0,37,0C21.561,0,9,12.561,9,28\n\ts12.561,28,28,28c3.523,0,6.892-0.66,10-1.853C36.486,50.112,29,39.917,29,28z");
			add_location(path, file, 13, 2, 312);
			add_location(g0, file, 18, 2, 529);
			add_location(g1, file, 19, 2, 537);
			add_location(g2, file, 20, 2, 545);
			add_location(g3, file, 21, 2, 553);
			add_location(g4, file, 22, 2, 561);
			add_location(g5, file, 23, 2, 569);
			add_location(g6, file, 24, 2, 577);
			add_location(g7, file, 25, 2, 585);
			add_location(g8, file, 26, 2, 593);
			add_location(g9, file, 27, 2, 601);
			add_location(g10, file, 28, 2, 609);
			add_location(g11, file, 29, 2, 617);
			add_location(g12, file, 30, 2, 625);
			add_location(g13, file, 31, 2, 633);
			add_location(g14, file, 32, 2, 641);
			attr_dev(svg, "version", "1.1");
			attr_dev(svg, "id", "Capa_1");
			attr_dev(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr_dev(svg, "xmlns:xlink", "http://www.w3.org/1999/xlink");
			attr_dev(svg, "x", "0px");
			attr_dev(svg, "y", "0px");
			attr_dev(svg, "width", "100%");
			attr_dev(svg, "height", "100%");
			attr_dev(svg, "viewBox", "0 0 56 56");
			attr_dev(svg, "xml:space", "preserve");
			add_location(svg, file, 1, 0, 95);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, svg, anchor);
			append_dev(svg, path);
			append_dev(svg, g0);
			append_dev(svg, g1);
			append_dev(svg, g2);
			append_dev(svg, g3);
			append_dev(svg, g4);
			append_dev(svg, g5);
			append_dev(svg, g6);
			append_dev(svg, g7);
			append_dev(svg, g8);
			append_dev(svg, g9);
			append_dev(svg, g10);
			append_dev(svg, g11);
			append_dev(svg, g12);
			append_dev(svg, g13);
			append_dev(svg, g14);
		},
		p: noop,
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(svg);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('MoonIcon', slots, []);
	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<MoonIcon> was created with unknown prop '${key}'`);
	});

	return [];
}

class MoonIcon extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "MoonIcon",
			options,
			id: create_fragment.name
		});
	}
}

export default MoonIcon;