/* components/panels/EncounterMessages.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	add_render_callback,
	append_dev,
	attr_dev,
	component_subscribe,
	create_component,
	destroy_component,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	listen_dev,
	mount_component,
	prop_dev,
	run_all,
	safe_not_equal,
	select_option,
	select_value,
	set_input_value,
	space,
	text,
	transition_in,
	transition_out,
	validate_slots,
	validate_store
} from "svelte/internal";

import Button from '../common/Button.svelte';
import { messageHistory } from '../../store';
import { get } from 'svelte/store';
import { getEncounterMessages } from '../../actions.js';
const file = "components/panels/EncounterMessages.svelte";

// (39:2) {#if $messageHistory?.messages?.length > 0}
function create_if_block(ctx) {
	let label;
	let t1;
	let textarea;
	let textarea_value_value;

	const block = {
		c: function create() {
			label = element("label");
			label.textContent = "Messages:";
			t1 = space();
			textarea = element("textarea");
			attr_dev(label, "for", "messages");
			add_location(label, file, 39, 4, 1069);
			attr_dev(textarea, "name", "messages");
			textarea.readOnly = true;
			textarea.value = textarea_value_value = JSON.stringify(/*$messageHistory*/ ctx[3].messages, undefined, 2);
			add_location(textarea, file, 40, 4, 1113);
		},
		m: function mount(target, anchor) {
			insert_dev(target, label, anchor);
			insert_dev(target, t1, anchor);
			insert_dev(target, textarea, anchor);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*$messageHistory*/ 8 && textarea_value_value !== (textarea_value_value = JSON.stringify(/*$messageHistory*/ ctx[3].messages, undefined, 2))) {
				prop_dev(textarea, "value", textarea_value_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(label);
			if (detaching) detach_dev(t1);
			if (detaching) detach_dev(textarea);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(39:2) {#if $messageHistory?.messages?.length > 0}",
		ctx
	});

	return block;
}

// (46:2) <Button id="getMessagesBtn" className="self-end" on:click={() => handleSubmit()}>
function create_default_slot(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Get Encounter Messages");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot.name,
		type: "slot",
		source: "(46:2) <Button id=\\\"getMessagesBtn\\\" className=\\\"self-end\\\" on:click={() => handleSubmit()}>",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div3;
	let div0;
	let label0;
	let t1;
	let input0;
	let t2;
	let div1;
	let label1;
	let t4;
	let input1;
	let t5;
	let div2;
	let label2;
	let t7;
	let select;
	let option0;
	let option1;
	let t10;
	let t11;
	let button;
	let current;
	let mounted;
	let dispose;
	let if_block = /*$messageHistory*/ ctx[3]?.messages?.length > 0 && create_if_block(ctx);

	button = new Button({
			props: {
				id: "getMessagesBtn",
				className: "self-end",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button.$on("click", /*click_handler*/ ctx[8]);

	const block = {
		c: function create() {
			div3 = element("div");
			div0 = element("div");
			label0 = element("label");
			label0.textContent = "EncounterId:";
			t1 = space();
			input0 = element("input");
			t2 = space();
			div1 = element("div");
			label1 = element("label");
			label1.textContent = "limit:";
			t4 = space();
			input1 = element("input");
			t5 = space();
			div2 = element("div");
			label2 = element("label");
			label2.textContent = "order:";
			t7 = space();
			select = element("select");
			option0 = element("option");
			option0.textContent = "ASC";
			option1 = element("option");
			option1.textContent = "DESC";
			t10 = space();
			if (if_block) if_block.c();
			t11 = space();
			create_component(button.$$.fragment);
			attr_dev(label0, "for", "encounterId");
			add_location(label0, file, 24, 4, 606);
			attr_dev(input0, "name", "encounterId");
			add_location(input0, file, 25, 4, 656);
			add_location(div0, file, 23, 2, 596);
			attr_dev(label1, "for", "encounterId");
			add_location(label1, file, 28, 4, 731);
			attr_dev(input1, "name", "encounterId");
			add_location(input1, file, 29, 4, 775);
			add_location(div1, file, 27, 2, 721);
			attr_dev(label2, "for", "encounterId");
			add_location(label2, file, 32, 4, 844);
			option0.__value = "asc";
			option0.value = option0.__value;
			add_location(option0, file, 34, 6, 922);
			option1.__value = "desc";
			option1.value = option1.__value;
			add_location(option1, file, 35, 6, 961);
			if (/*order*/ ctx[1] === void 0) add_render_callback(() => /*select_change_handler*/ ctx[7].call(select));
			add_location(select, file, 33, 4, 888);
			add_location(div2, file, 31, 2, 834);
			attr_dev(div3, "id", "encountersContainer");
			attr_dev(div3, "class", "collapsable-content flex flex-col my-2 space-y-4");
			add_location(div3, file, 22, 0, 506);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div3, anchor);
			append_dev(div3, div0);
			append_dev(div0, label0);
			append_dev(div0, t1);
			append_dev(div0, input0);
			set_input_value(input0, /*encounterId*/ ctx[2]);
			append_dev(div3, t2);
			append_dev(div3, div1);
			append_dev(div1, label1);
			append_dev(div1, t4);
			append_dev(div1, input1);
			set_input_value(input1, /*limit*/ ctx[0]);
			append_dev(div3, t5);
			append_dev(div3, div2);
			append_dev(div2, label2);
			append_dev(div2, t7);
			append_dev(div2, select);
			append_dev(select, option0);
			append_dev(select, option1);
			select_option(select, /*order*/ ctx[1]);
			append_dev(div3, t10);
			if (if_block) if_block.m(div3, null);
			append_dev(div3, t11);
			mount_component(button, div3, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen_dev(input0, "input", /*input0_input_handler*/ ctx[5]),
					listen_dev(input1, "input", /*input1_input_handler*/ ctx[6]),
					listen_dev(select, "change", /*select_change_handler*/ ctx[7])
				];

				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*encounterId*/ 4 && input0.value !== /*encounterId*/ ctx[2]) {
				set_input_value(input0, /*encounterId*/ ctx[2]);
			}

			if (dirty & /*limit*/ 1 && input1.value !== /*limit*/ ctx[0]) {
				set_input_value(input1, /*limit*/ ctx[0]);
			}

			if (dirty & /*order*/ 2) {
				select_option(select, /*order*/ ctx[1]);
			}

			if (/*$messageHistory*/ ctx[3]?.messages?.length > 0) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div3, t11);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			const button_changes = {};

			if (dirty & /*$$scope*/ 512) {
				button_changes.$$scope = { dirty, ctx };
			}

			button.$set(button_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div3);
			if (if_block) if_block.d();
			destroy_component(button);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $messageHistory;
	validate_store(messageHistory, 'messageHistory');
	component_subscribe($$self, messageHistory, $$value => $$invalidate(3, $messageHistory = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('EncounterMessages', slots, []);

	function handleSubmit() {
		const params = {
			limit,
			asc: order === 'asc' ? true : false
		};

		if (encounterId.trim() !== '') {
			params.triage = encounterId;
		}

		getEncounterMessages(params);
	}

	let limit = 50;
	let order = 'asc';
	let encounterId = '';
	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<EncounterMessages> was created with unknown prop '${key}'`);
	});

	function input0_input_handler() {
		encounterId = this.value;
		$$invalidate(2, encounterId);
	}

	function input1_input_handler() {
		limit = this.value;
		$$invalidate(0, limit);
	}

	function select_change_handler() {
		order = select_value(this);
		$$invalidate(1, order);
	}

	const click_handler = () => handleSubmit();

	$$self.$capture_state = () => ({
		Button,
		messageHistory,
		get,
		getEncounterMessages,
		handleSubmit,
		limit,
		order,
		encounterId,
		$messageHistory
	});

	$$self.$inject_state = $$props => {
		if ('limit' in $$props) $$invalidate(0, limit = $$props.limit);
		if ('order' in $$props) $$invalidate(1, order = $$props.order);
		if ('encounterId' in $$props) $$invalidate(2, encounterId = $$props.encounterId);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [
		limit,
		order,
		encounterId,
		$messageHistory,
		handleSubmit,
		input0_input_handler,
		input1_input_handler,
		select_change_handler,
		click_handler
	];
}

class EncounterMessages extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "EncounterMessages",
			options,
			id: create_fragment.name
		});
	}
}

export default EncounterMessages;