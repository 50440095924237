/* components/panels/CurrentEncounter.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	component_subscribe,
	create_component,
	destroy_component,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	mount_component,
	prop_dev,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out,
	validate_slots,
	validate_store
} from "svelte/internal";

import Button from '../common/Button.svelte';
import { currentEncounterData } from '../../store';
import { get } from 'svelte/store';
import { getCurrentEncounterData } from '../../actions.js';
const file = "components/panels/CurrentEncounter.svelte";

// (15:2) {#if $currentEncounterData}
function create_if_block(ctx) {
	let textarea;
	let textarea_value_value;

	const block = {
		c: function create() {
			textarea = element("textarea");
			attr_dev(textarea, "name", "currentEncounter");
			textarea.readOnly = true;
			textarea.value = textarea_value_value = JSON.stringify(/*$currentEncounterData*/ ctx[0], undefined, 2);
			add_location(textarea, file, 15, 4, 430);
		},
		m: function mount(target, anchor) {
			insert_dev(target, textarea, anchor);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*$currentEncounterData*/ 1 && textarea_value_value !== (textarea_value_value = JSON.stringify(/*$currentEncounterData*/ ctx[0], undefined, 2))) {
				prop_dev(textarea, "value", textarea_value_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(textarea);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(15:2) {#if $currentEncounterData}",
		ctx
	});

	return block;
}

// (21:2) <Button id="getEncountersBtn" className="self-end" on:click={() => handleSubmit()}>
function create_default_slot(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Fetch Info");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot.name,
		type: "slot",
		source: "(21:2) <Button id=\\\"getEncountersBtn\\\" className=\\\"self-end\\\" on:click={() => handleSubmit()}>",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let t;
	let button;
	let current;
	let if_block = /*$currentEncounterData*/ ctx[0] && create_if_block(ctx);

	button = new Button({
			props: {
				id: "getEncountersBtn",
				className: "self-end",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button.$on("click", /*click_handler*/ ctx[2]);

	const block = {
		c: function create() {
			div = element("div");
			if (if_block) if_block.c();
			t = space();
			create_component(button.$$.fragment);
			attr_dev(div, "id", "encountersContainer");
			attr_dev(div, "class", "collapsable-content flex flex-col my-2 space-y-4");
			add_location(div, file, 13, 0, 308);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			if (if_block) if_block.m(div, null);
			append_dev(div, t);
			mount_component(button, div, null);
			current = true;
		},
		p: function update(ctx, [dirty]) {
			if (/*$currentEncounterData*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, t);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			const button_changes = {};

			if (dirty & /*$$scope*/ 16) {
				button_changes.$$scope = { dirty, ctx };
			}

			button.$set(button_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if (if_block) if_block.d();
			destroy_component(button);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let $currentEncounterData;
	validate_store(currentEncounterData, 'currentEncounterData');
	component_subscribe($$self, currentEncounterData, $$value => $$invalidate(0, $currentEncounterData = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('CurrentEncounter', slots, []);

	function handleSubmit() {
		getCurrentEncounterData();
	}

	let active = null;
	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<CurrentEncounter> was created with unknown prop '${key}'`);
	});

	const click_handler = () => handleSubmit();

	$$self.$capture_state = () => ({
		Button,
		currentEncounterData,
		get,
		getCurrentEncounterData,
		handleSubmit,
		active,
		$currentEncounterData
	});

	$$self.$inject_state = $$props => {
		if ('active' in $$props) active = $$props.active;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [$currentEncounterData, handleSubmit, click_handler];
}

class CurrentEncounter extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "CurrentEncounter",
			options,
			id: create_fragment.name
		});
	}
}

export default CurrentEncounter;