/* components/panels/EncounterConfig.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	create_component,
	destroy_component,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	listen_dev,
	mount_component,
	run_all,
	safe_not_equal,
	set_input_value,
	space,
	text,
	transition_in,
	transition_out,
	validate_slots
} from "svelte/internal";

import { onMount } from 'svelte';
import Button from '../common/Button.svelte';
import ReasonCodeAndAttrSelector from '../ReasonCodeAndAttrSelector.svelte';
import { defaultConfig } from '../../defaults';
import { startWithConfig, startCreateOnInitFlow } from '../../actions.js';
const file = "components/panels/EncounterConfig.svelte";

// (44:2) {#if withMetadata}
function create_if_block(ctx) {
	let div;
	let label;
	let t1;
	let input;
	let mounted;
	let dispose;

	const block = {
		c: function create() {
			div = element("div");
			label = element("label");
			label.textContent = "Metadata:";
			t1 = space();
			input = element("input");
			attr_dev(label, "for", "metadata");
			add_location(label, file, 45, 6, 1481);
			attr_dev(input, "name", "metadata");
			add_location(input, file, 46, 6, 1527);
			add_location(div, file, 44, 4, 1469);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, label);
			append_dev(div, t1);
			append_dev(div, input);
			set_input_value(input, /*metadata*/ ctx[2]);

			if (!mounted) {
				dispose = listen_dev(input, "input", /*input_input_handler*/ ctx[7]);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty & /*metadata*/ 4 && input.value !== /*metadata*/ ctx[2]) {
				set_input_value(input, /*metadata*/ ctx[2]);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(44:2) {#if withMetadata}",
		ctx
	});

	return block;
}

// (59:4) <Button id="startEncounter" className="ml-3" on:click={() => handleSubmit()}>
function create_default_slot(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Start Encounter");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot.name,
		type: "slot",
		source: "(59:4) <Button id=\\\"startEncounter\\\" className=\\\"ml-3\\\" on:click={() => handleSubmit()}>",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div2;
	let reasoncodeandattrselector;
	let t0;
	let textarea;
	let t1;
	let div0;
	let label;
	let t3;
	let input;
	let t4;
	let t5;
	let div1;
	let button;
	let current;
	let mounted;
	let dispose;

	reasoncodeandattrselector = new ReasonCodeAndAttrSelector({
			props: { onChange: /*handleSelector*/ ctx[4] },
			$$inline: true
		});

	let if_block = /*withMetadata*/ ctx[1] && create_if_block(ctx);

	button = new Button({
			props: {
				id: "startEncounter",
				className: "ml-3",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button.$on("click", /*click_handler*/ ctx[8]);

	const block = {
		c: function create() {
			div2 = element("div");
			create_component(reasoncodeandattrselector.$$.fragment);
			t0 = space();
			textarea = element("textarea");
			t1 = space();
			div0 = element("div");
			label = element("label");
			label.textContent = "With metadata:";
			t3 = space();
			input = element("input");
			t4 = space();
			if (if_block) if_block.c();
			t5 = space();
			div1 = element("div");
			create_component(button.$$.fragment);
			add_location(textarea, file, 38, 2, 1205);
			attr_dev(label, "class", "w-auto");
			attr_dev(label, "for", "withNotes");
			add_location(label, file, 40, 4, 1276);
			attr_dev(input, "class", "w-auto ml-3");
			attr_dev(input, "type", "checkbox");
			attr_dev(input, "name", "withMetadata");
			add_location(input, file, 41, 4, 1341);
			attr_dev(div0, "class", "flex items-center");
			add_location(div0, file, 39, 2, 1240);
			attr_dev(div1, "class", "flex self-end");
			add_location(div1, file, 57, 2, 1906);
			attr_dev(div2, "id", "contextContainer");
			attr_dev(div2, "class", "collapsable-content flex flex-col my-2 space-y-4");
			add_location(div2, file, 36, 0, 1060);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div2, anchor);
			mount_component(reasoncodeandattrselector, div2, null);
			append_dev(div2, t0);
			append_dev(div2, textarea);
			set_input_value(textarea, /*config*/ ctx[0]);
			append_dev(div2, t1);
			append_dev(div2, div0);
			append_dev(div0, label);
			append_dev(div0, t3);
			append_dev(div0, input);
			input.checked = /*withMetadata*/ ctx[1];
			append_dev(div2, t4);
			if (if_block) if_block.m(div2, null);
			append_dev(div2, t5);
			append_dev(div2, div1);
			mount_component(button, div1, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen_dev(textarea, "input", /*textarea_input_handler*/ ctx[5]),
					listen_dev(input, "change", /*input_change_handler*/ ctx[6])
				];

				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*config*/ 1) {
				set_input_value(textarea, /*config*/ ctx[0]);
			}

			if (dirty & /*withMetadata*/ 2) {
				input.checked = /*withMetadata*/ ctx[1];
			}

			if (/*withMetadata*/ ctx[1]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div2, t5);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			const button_changes = {};

			if (dirty & /*$$scope*/ 2048) {
				button_changes.$$scope = { dirty, ctx };
			}

			button.$set(button_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(reasoncodeandattrselector.$$.fragment, local);
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(reasoncodeandattrselector.$$.fragment, local);
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div2);
			destroy_component(reasoncodeandattrselector);
			if (if_block) if_block.d();
			destroy_component(button);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('EncounterConfig', slots, []);
	let config = '';
	let withMetadata = false;
	let metadata = null;
	let overrideAddress = false;

	onMount(() => {
		$$invalidate(0, config = JSON.stringify(defaultConfig, undefined, 2));
	});

	function handleSubmit() {
		const metadataToSend = withMetadata ? metadata : null;
		startWithConfig(config, metadataToSend, overrideAddress);
	}

	function handleCreateOnInitFlow() {
		const metadataToSend = withMetadata ? metadata : null;
		startCreateOnInitFlow(config, metadataToSend, overrideAddress);
	}

	function handleSelector(obj) {
		const currentConfig = JSON.parse(config);
		const oldNotes = currentConfig.notes;
		const newObj = { notes: oldNotes, ...obj };
		$$invalidate(0, config = JSON.stringify(newObj, undefined, 2));
	}

	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<EncounterConfig> was created with unknown prop '${key}'`);
	});

	function textarea_input_handler() {
		config = this.value;
		$$invalidate(0, config);
	}

	function input_change_handler() {
		withMetadata = this.checked;
		$$invalidate(1, withMetadata);
	}

	function input_input_handler() {
		metadata = this.value;
		$$invalidate(2, metadata);
	}

	const click_handler = () => handleSubmit();

	$$self.$capture_state = () => ({
		onMount,
		Button,
		ReasonCodeAndAttrSelector,
		defaultConfig,
		startWithConfig,
		startCreateOnInitFlow,
		config,
		withMetadata,
		metadata,
		overrideAddress,
		handleSubmit,
		handleCreateOnInitFlow,
		handleSelector
	});

	$$self.$inject_state = $$props => {
		if ('config' in $$props) $$invalidate(0, config = $$props.config);
		if ('withMetadata' in $$props) $$invalidate(1, withMetadata = $$props.withMetadata);
		if ('metadata' in $$props) $$invalidate(2, metadata = $$props.metadata);
		if ('overrideAddress' in $$props) overrideAddress = $$props.overrideAddress;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [
		config,
		withMetadata,
		metadata,
		handleSubmit,
		handleSelector,
		textarea_input_handler,
		input_change_handler,
		input_input_handler,
		click_handler
	];
}

class EncounterConfig extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "EncounterConfig",
			options,
			id: create_fragment.name
		});
	}
}

export default EncounterConfig;