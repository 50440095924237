/* components/common/OrderIcon.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	detach_dev,
	dispatch_dev,
	init,
	insert_dev,
	noop,
	safe_not_equal,
	set_style,
	svg_element,
	validate_slots
} from "svelte/internal";

const file = "components/common/OrderIcon.svelte";

function create_fragment(ctx) {
	let svg;
	let g1;
	let g0;
	let path;
	let g3;
	let g2;
	let polygon0;
	let g5;
	let g4;
	let polygon1;
	let g6;
	let g7;
	let g8;
	let g9;
	let g10;
	let g11;
	let g12;
	let g13;
	let g14;
	let g15;
	let g16;
	let g17;
	let g18;
	let g19;
	let g20;

	const block = {
		c: function create() {
			svg = svg_element("svg");
			g1 = svg_element("g");
			g0 = svg_element("g");
			path = svg_element("path");
			g3 = svg_element("g");
			g2 = svg_element("g");
			polygon0 = svg_element("polygon");
			g5 = svg_element("g");
			g4 = svg_element("g");
			polygon1 = svg_element("polygon");
			g6 = svg_element("g");
			g7 = svg_element("g");
			g8 = svg_element("g");
			g9 = svg_element("g");
			g10 = svg_element("g");
			g11 = svg_element("g");
			g12 = svg_element("g");
			g13 = svg_element("g");
			g14 = svg_element("g");
			g15 = svg_element("g");
			g16 = svg_element("g");
			g17 = svg_element("g");
			g18 = svg_element("g");
			g19 = svg_element("g");
			g20 = svg_element("g");
			attr_dev(path, "d", "M106.23,0H75.86L1.496,212.467h42.273l11.172-31.92h71.37l11.012,31.92h42.207L106.23,0z M68.906,140.647l21.976-62.791\n\t\t\tl21.664,62.791H68.906z");
			add_location(path, file, 4, 2, 250);
			add_location(g0, file, 3, 1, 244);
			add_location(g1, file, 2, 0, 239);
			attr_dev(polygon0, "points", "483.288,359.814 407.478,435.624 407.478,0 367.578,0 367.578,435.624 291.768,359.814 263.555,388.027 \n\t\t\t387.528,512 511.501,388.027 \t\t");
			add_location(polygon0, file, 10, 2, 426);
			add_location(g2, file, 9, 1, 420);
			add_location(g3, file, 8, 0, 415);
			attr_dev(polygon1, "points", "182.043,299.247 0.499,299.247 0.499,339.147 122.039,339.147 0.499,480.372 0.499,511.717 180.048,511.717 \n\t\t\t180.048,471.817 60.503,471.817 182.043,330.592 \t\t");
			add_location(polygon1, file, 16, 2, 603);
			add_location(g4, file, 15, 1, 597);
			add_location(g5, file, 14, 0, 592);
			add_location(g6, file, 20, 0, 792);
			add_location(g7, file, 22, 0, 801);
			add_location(g8, file, 24, 0, 810);
			add_location(g9, file, 26, 0, 819);
			add_location(g10, file, 28, 0, 828);
			add_location(g11, file, 30, 0, 837);
			add_location(g12, file, 32, 0, 846);
			add_location(g13, file, 34, 0, 855);
			add_location(g14, file, 36, 0, 864);
			add_location(g15, file, 38, 0, 873);
			add_location(g16, file, 40, 0, 882);
			add_location(g17, file, 42, 0, 891);
			add_location(g18, file, 44, 0, 900);
			add_location(g19, file, 46, 0, 909);
			add_location(g20, file, 48, 0, 918);
			attr_dev(svg, "width", "100%");
			attr_dev(svg, "height", "100%");
			attr_dev(svg, "version", "1.1");
			attr_dev(svg, "id", "sort");
			attr_dev(svg, "xmlns", "http://www.w3.org/2000/svg");
			attr_dev(svg, "xmlns:xlink", "http://www.w3.org/1999/xlink");
			attr_dev(svg, "x", "0px");
			attr_dev(svg, "y", "0px");
			attr_dev(svg, "viewBox", "0 0 512 512");
			set_style(svg, "enable-background", "new 0 0 512 512");
			attr_dev(svg, "xml:space", "preserve");
			add_location(svg, file, 0, 0, 0);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, svg, anchor);
			append_dev(svg, g1);
			append_dev(g1, g0);
			append_dev(g0, path);
			append_dev(svg, g3);
			append_dev(g3, g2);
			append_dev(g2, polygon0);
			append_dev(svg, g5);
			append_dev(g5, g4);
			append_dev(g4, polygon1);
			append_dev(svg, g6);
			append_dev(svg, g7);
			append_dev(svg, g8);
			append_dev(svg, g9);
			append_dev(svg, g10);
			append_dev(svg, g11);
			append_dev(svg, g12);
			append_dev(svg, g13);
			append_dev(svg, g14);
			append_dev(svg, g15);
			append_dev(svg, g16);
			append_dev(svg, g17);
			append_dev(svg, g18);
			append_dev(svg, g19);
			append_dev(svg, g20);
		},
		p: noop,
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(svg);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('OrderIcon', slots, []);
	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<OrderIcon> was created with unknown prop '${key}'`);
	});

	return [];
}

class OrderIcon extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "OrderIcon",
			options,
			id: create_fragment.name
		});
	}
}

export default OrderIcon;