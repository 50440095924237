/* components/panels/Appearance.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	add_render_callback,
	append_dev,
	attr_dev,
	create_component,
	destroy_component,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	listen_dev,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	select_option,
	select_value,
	set_input_value,
	space,
	text,
	transition_in,
	transition_out,
	validate_each_argument,
	validate_slots
} from "svelte/internal";

import { onMount } from 'svelte';
import Button from '../common/Button.svelte';
import defaultAppearances from '../../appearances';
import { setAppearance, getLocalStorage } from '../../actions.js';
const file = "components/panels/Appearance.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[6] = list[i];
	return child_ctx;
}

// (29:6) {#each defaultAppearances as appearanceItem}
function create_each_block(ctx) {
	let option;
	let t_value = /*appearanceItem*/ ctx[6].name + "";
	let t;
	let option_value_value;

	const block = {
		c: function create() {
			option = element("option");
			t = text(t_value);
			option.__value = option_value_value = /*appearanceItem*/ ctx[6];
			option.value = option.__value;
			add_location(option, file, 29, 8, 921);
		},
		m: function mount(target, anchor) {
			insert_dev(target, option, anchor);
			append_dev(option, t);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(option);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(29:6) {#each defaultAppearances as appearanceItem}",
		ctx
	});

	return block;
}

// (35:2) <Button     id="applyAppearanceBtn"     className="self-end"     on:click={() => setAppearance(appearance)}>
function create_default_slot(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Apply");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot.name,
		type: "slot",
		source: "(35:2) <Button     id=\\\"applyAppearanceBtn\\\"     className=\\\"self-end\\\"     on:click={() => setAppearance(appearance)}>",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let select;
	let t0;
	let textarea;
	let t1;
	let button;
	let current;
	let mounted;
	let dispose;
	let each_value = defaultAppearances;
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	button = new Button({
			props: {
				id: "applyAppearanceBtn",
				className: "self-end",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button.$on("click", /*click_handler*/ ctx[5]);

	const block = {
		c: function create() {
			div1 = element("div");
			div0 = element("div");
			select = element("select");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t0 = space();
			textarea = element("textarea");
			t1 = space();
			create_component(button.$$.fragment);
			if (/*selected*/ ctx[1] === void 0) add_render_callback(() => /*select_change_handler*/ ctx[3].call(select));
			add_location(select, file, 27, 4, 806);
			attr_dev(div0, "class", "flex mt-1 w-full");
			add_location(div0, file, 26, 2, 771);
			attr_dev(textarea, "class", "appearance-input");
			add_location(textarea, file, 33, 2, 1022);
			attr_dev(div1, "id", "appearanceContainer");
			attr_dev(div1, "class", "collapsable-content flex flex-col my-2 space-y-4");
			add_location(div1, file, 23, 0, 677);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			append_dev(div1, div0);
			append_dev(div0, select);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(select, null);
			}

			select_option(select, /*selected*/ ctx[1]);
			append_dev(div1, t0);
			append_dev(div1, textarea);
			set_input_value(textarea, /*appearance*/ ctx[0]);
			append_dev(div1, t1);
			mount_component(button, div1, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen_dev(select, "change", /*select_change_handler*/ ctx[3]),
					listen_dev(select, "change", /*handleChange*/ ctx[2], false, false, false),
					listen_dev(textarea, "input", /*textarea_input_handler*/ ctx[4])
				];

				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*defaultAppearances*/ 0) {
				each_value = defaultAppearances;
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(select, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*selected, defaultAppearances*/ 2) {
				select_option(select, /*selected*/ ctx[1]);
			}

			if (dirty & /*appearance*/ 1) {
				set_input_value(textarea, /*appearance*/ ctx[0]);
			}

			const button_changes = {};

			if (dirty & /*$$scope*/ 512) {
				button_changes.$$scope = { dirty, ctx };
			}

			button.$set(button_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
			destroy_each(each_blocks, detaching);
			destroy_component(button);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function stringify(str) {
	return JSON.stringify(str, undefined, 2);
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('Appearance', slots, []);
	let appearance = '';
	let selected = null;

	onMount(() => {
		$$invalidate(0, appearance = getLocalStorage('appearance') || stringify(defaultAppearances[0].obj));
	});

	const handleChange = () => {
		$$invalidate(0, appearance = stringify(selected.obj));
	};

	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<Appearance> was created with unknown prop '${key}'`);
	});

	function select_change_handler() {
		selected = select_value(this);
		$$invalidate(1, selected);
	}

	function textarea_input_handler() {
		appearance = this.value;
		$$invalidate(0, appearance);
	}

	const click_handler = () => setAppearance(appearance);

	$$self.$capture_state = () => ({
		onMount,
		Button,
		defaultAppearances,
		setAppearance,
		getLocalStorage,
		appearance,
		selected,
		handleChange,
		stringify
	});

	$$self.$inject_state = $$props => {
		if ('appearance' in $$props) $$invalidate(0, appearance = $$props.appearance);
		if ('selected' in $$props) $$invalidate(1, selected = $$props.selected);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [
		appearance,
		selected,
		handleChange,
		select_change_handler,
		textarea_input_handler,
		click_handler
	];
}

class Appearance extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "Appearance",
			options,
			id: create_fragment.name
		});
	}
}

export default Appearance;