/* components/panels/Address.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	create_component,
	destroy_component,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	listen_dev,
	mount_component,
	safe_not_equal,
	set_data_dev,
	set_input_value,
	space,
	text,
	transition_in,
	transition_out,
	validate_slots
} from "svelte/internal";

import { onMount } from 'svelte';
import Button from '../common/Button.svelte';
import { defaultAddress } from '../../defaults';
import { setAddress, getLocalStorage } from '../../actions.js';
const file = "components/panels/Address.svelte";

// (33:2) <Button     id="applyAddressBtn"     className="self-end"     on:click={() => {       setAddress(address);       currentAddress = address;     }}   >
function create_default_slot_1(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Apply");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_1.name,
		type: "slot",
		source: "(33:2) <Button     id=\\\"applyAddressBtn\\\"     className=\\\"self-end\\\"     on:click={() => {       setAddress(address);       currentAddress = address;     }}   >",
		ctx
	});

	return block;
}

// (43:2) <Button     id="applyAddressBtn"     className="self-end"     on:click={() => {       setAddress('empty');       currentAddress = null;     }}   >
function create_default_slot(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Set Null");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot.name,
		type: "slot",
		source: "(43:2) <Button     id=\\\"applyAddressBtn\\\"     className=\\\"self-end\\\"     on:click={() => {       setAddress('empty');       currentAddress = null;     }}   >",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div2;
	let div0;
	let small0;
	let t1;
	let small1;
	let t2;
	let t3;
	let div1;
	let span;
	let t5;
	let textarea;
	let t6;
	let button0;
	let t7;
	let button1;
	let current;
	let mounted;
	let dispose;

	button0 = new Button({
			props: {
				id: "applyAddressBtn",
				className: "self-end",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button0.$on("click", /*click_handler*/ ctx[3]);

	button1 = new Button({
			props: {
				id: "applyAddressBtn",
				className: "self-end",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button1.$on("click", /*click_handler_1*/ ctx[4]);

	const block = {
		c: function create() {
			div2 = element("div");
			div0 = element("div");
			small0 = element("small");
			small0.textContent = "Current Address:";
			t1 = space();
			small1 = element("small");
			t2 = text(/*currentAddress*/ ctx[0]);
			t3 = space();
			div1 = element("div");
			span = element("span");
			span.textContent = "New Address:";
			t5 = space();
			textarea = element("textarea");
			t6 = space();
			create_component(button0.$$.fragment);
			t7 = space();
			create_component(button1.$$.fragment);
			attr_dev(small0, "class", "font-bold");
			add_location(small0, file, 25, 4, 725);
			add_location(small1, file, 26, 4, 779);
			add_location(div0, file, 24, 2, 715);
			add_location(span, file, 29, 4, 832);
			add_location(textarea, file, 30, 4, 862);
			add_location(div1, file, 28, 2, 822);
			attr_dev(div2, "id", "addressContainer");
			attr_dev(div2, "class", "collapsable-content flex flex-col my-2 space-y-4");
			add_location(div2, file, 23, 0, 628);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div2, anchor);
			append_dev(div2, div0);
			append_dev(div0, small0);
			append_dev(div0, t1);
			append_dev(div0, small1);
			append_dev(small1, t2);
			append_dev(div2, t3);
			append_dev(div2, div1);
			append_dev(div1, span);
			append_dev(div1, t5);
			append_dev(div1, textarea);
			set_input_value(textarea, /*address*/ ctx[1]);
			append_dev(div2, t6);
			mount_component(button0, div2, null);
			append_dev(div2, t7);
			mount_component(button1, div2, null);
			current = true;

			if (!mounted) {
				dispose = listen_dev(textarea, "input", /*textarea_input_handler*/ ctx[2]);
				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (!current || dirty & /*currentAddress*/ 1) set_data_dev(t2, /*currentAddress*/ ctx[0]);

			if (dirty & /*address*/ 2) {
				set_input_value(textarea, /*address*/ ctx[1]);
			}

			const button0_changes = {};

			if (dirty & /*$$scope*/ 32) {
				button0_changes.$$scope = { dirty, ctx };
			}

			button0.$set(button0_changes);
			const button1_changes = {};

			if (dirty & /*$$scope*/ 32) {
				button1_changes.$$scope = { dirty, ctx };
			}

			button1.$set(button1_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(button0.$$.fragment, local);
			transition_in(button1.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(button0.$$.fragment, local);
			transition_out(button1.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div2);
			destroy_component(button0);
			destroy_component(button1);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('Address', slots, []);
	let currentAddress = '';
	let address = '';

	onMount(() => {
		const add = getLocalStorage('address');

		if (add === 'empty') {
			$$invalidate(0, currentAddress = null);
			$$invalidate(1, address = JSON.stringify(defaultAddress, undefined, 2));
		} else if (add === null) {
			$$invalidate(1, address = $$invalidate(0, currentAddress = JSON.stringify(defaultAddress, undefined, 2)));
		} else {
			$$invalidate(1, address = $$invalidate(0, currentAddress = add));
		}
	});

	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<Address> was created with unknown prop '${key}'`);
	});

	function textarea_input_handler() {
		address = this.value;
		$$invalidate(1, address);
	}

	const click_handler = () => {
		setAddress(address);
		$$invalidate(0, currentAddress = address);
	};

	const click_handler_1 = () => {
		setAddress('empty');
		$$invalidate(0, currentAddress = null);
	};

	$$self.$capture_state = () => ({
		onMount,
		Button,
		defaultAddress,
		setAddress,
		getLocalStorage,
		currentAddress,
		address
	});

	$$self.$inject_state = $$props => {
		if ('currentAddress' in $$props) $$invalidate(0, currentAddress = $$props.currentAddress);
		if ('address' in $$props) $$invalidate(1, address = $$props.address);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [
		currentAddress,
		address,
		textarea_input_handler,
		click_handler,
		click_handler_1
	];
}

class Address extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "Address",
			options,
			id: create_fragment.name
		});
	}
}

export default Address;