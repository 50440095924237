/* components/ReasonCodeAndAttrSelector.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	create_component,
	destroy_component,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element,
	empty,
	get_binding_group_value,
	globals,
	init,
	insert_dev,
	listen_dev,
	mount_component,
	prop_dev,
	run_all,
	safe_not_equal,
	set_data_dev,
	set_input_value,
	space,
	text,
	transition_in,
	transition_out,
	validate_each_argument,
	validate_slots
} from "svelte/internal";

const { Object: Object_1 } = globals;
import { reasonCodesAndAttr } from '../store';
import { get } from 'svelte/store';
import { join, sort } from 'tlds';
import OrderIcon from './common/OrderIcon.svelte';
import { InputListSearchProvider } from '@pgr/web-sdk-components';
const file = "components/ReasonCodeAndAttrSelector.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[24] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[27] = list[i];
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[30] = list[i];
	return child_ctx;
}

// (140:6) {#each reasonCodesAndAttributes as reasonItem}
function create_each_block_2(ctx) {
	let li;
	let t0_value = /*reasonItem*/ ctx[30].reasonName + "";
	let t0;
	let t1;
	let li_class_value;
	let mounted;
	let dispose;

	function click_handler_1() {
		return /*click_handler_1*/ ctx[14](/*reasonItem*/ ctx[30]);
	}

	const block = {
		c: function create() {
			li = element("li");
			t0 = text(t0_value);
			t1 = space();

			attr_dev(li, "class", li_class_value = /*selectedReason*/ ctx[3]?.reasonCode === /*reasonItem*/ ctx[30].reasonCode
			? 'active'
			: '');

			add_location(li, file, 140, 8, 3776);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, t0);
			append_dev(li, t1);

			if (!mounted) {
				dispose = listen_dev(li, "click", click_handler_1, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty[0] & /*reasonCodesAndAttributes*/ 2 && t0_value !== (t0_value = /*reasonItem*/ ctx[30].reasonName + "")) set_data_dev(t0, t0_value);

			if (dirty[0] & /*selectedReason, reasonCodesAndAttributes*/ 10 && li_class_value !== (li_class_value = /*selectedReason*/ ctx[3]?.reasonCode === /*reasonItem*/ ctx[30].reasonCode
			? 'active'
			: '')) {
				attr_dev(li, "class", li_class_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block_2.name,
		type: "each",
		source: "(140:6) {#each reasonCodesAndAttributes as reasonItem}",
		ctx
	});

	return block;
}

// (160:6) {#if selectedReason}
function create_if_block_2(ctx) {
	let each_1_anchor;
	let each_value_1 = /*selectedReason*/ ctx[3].attributes;
	validate_each_argument(each_value_1);
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const block = {
		c: function create() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m: function mount(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert_dev(target, each_1_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*highlightedAttr, selectedReason, selectedAttributes*/ 25) {
				each_value_1 = /*selectedReason*/ ctx[3].attributes;
				validate_each_argument(each_value_1);
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}
		},
		d: function destroy(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach_dev(each_1_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(160:6) {#if selectedReason}",
		ctx
	});

	return block;
}

// (170:14) {#if attrItem.required}
function create_if_block_3(ctx) {
	let strong;

	const block = {
		c: function create() {
			strong = element("strong");
			strong.textContent = "(req)";
			add_location(strong, file, 169, 37, 4641);
		},
		m: function mount(target, anchor) {
			insert_dev(target, strong, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(strong);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_3.name,
		type: "if",
		source: "(170:14) {#if attrItem.required}",
		ctx
	});

	return block;
}

// (161:8) {#each selectedReason.attributes as attrItem}
function create_each_block_1(ctx) {
	let li;
	let span;
	let t0_value = /*attrItem*/ ctx[27].name + "";
	let t0;
	let t1;
	let t2;
	let input;
	let input_value_value;
	let t3;
	let li_class_value;
	let mounted;
	let dispose;
	let if_block = /*attrItem*/ ctx[27].required && create_if_block_3(ctx);

	function click_handler_2() {
		return /*click_handler_2*/ ctx[17](/*attrItem*/ ctx[27]);
	}

	const block = {
		c: function create() {
			li = element("li");
			span = element("span");
			t0 = text(t0_value);
			t1 = space();
			if (if_block) if_block.c();
			t2 = space();
			input = element("input");
			t3 = space();
			add_location(span, file, 167, 12, 4567);
			attr_dev(input, "type", "checkbox");
			attr_dev(input, "class", "w-auto");
			input.__value = input_value_value = { .../*attrItem*/ ctx[27] };
			input.value = input.__value;
			/*$$binding_groups*/ ctx[16][0].push(input);
			add_location(input, file, 171, 12, 4701);

			attr_dev(li, "class", li_class_value = /*highlightedAttr*/ ctx[4]?.name === /*attrItem*/ ctx[27].name
			? 'active'
			: '');

			add_location(li, file, 161, 10, 4376);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, span);
			append_dev(span, t0);
			append_dev(span, t1);
			if (if_block) if_block.m(span, null);
			append_dev(li, t2);
			append_dev(li, input);
			input.checked = ~/*selectedAttributes*/ ctx[0].indexOf(input.__value);
			append_dev(li, t3);

			if (!mounted) {
				dispose = [
					listen_dev(input, "change", /*input_change_handler*/ ctx[15]),
					listen_dev(li, "click", click_handler_2, false, false, false)
				];

				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty[0] & /*selectedReason*/ 8 && t0_value !== (t0_value = /*attrItem*/ ctx[27].name + "")) set_data_dev(t0, t0_value);

			if (/*attrItem*/ ctx[27].required) {
				if (if_block) {
					
				} else {
					if_block = create_if_block_3(ctx);
					if_block.c();
					if_block.m(span, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}

			if (dirty[0] & /*selectedReason*/ 8 && input_value_value !== (input_value_value = { .../*attrItem*/ ctx[27] })) {
				prop_dev(input, "__value", input_value_value);
				input.value = input.__value;
			}

			if (dirty[0] & /*selectedAttributes*/ 1) {
				input.checked = ~/*selectedAttributes*/ ctx[0].indexOf(input.__value);
			}

			if (dirty[0] & /*highlightedAttr, selectedReason*/ 24 && li_class_value !== (li_class_value = /*highlightedAttr*/ ctx[4]?.name === /*attrItem*/ ctx[27].name
			? 'active'
			: '')) {
				attr_dev(li, "class", li_class_value);
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			if (if_block) if_block.d();
			/*$$binding_groups*/ ctx[16][0].splice(/*$$binding_groups*/ ctx[16][0].indexOf(input), 1);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block_1.name,
		type: "each",
		source: "(161:8) {#each selectedReason.attributes as attrItem}",
		ctx
	});

	return block;
}

// (186:6) {#if valueAdded}
function create_if_block_1(ctx) {
	let span;

	const block = {
		c: function create() {
			span = element("span");
			span.textContent = "Value Added!";
			attr_dev(span, "class", "text-green-500");
			add_location(span, file, 185, 22, 5038);
		},
		m: function mount(target, anchor) {
			insert_dev(target, span, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(span);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(186:6) {#if valueAdded}",
		ctx
	});

	return block;
}

// (189:6) {#if highlightedAttr}
function create_if_block(ctx) {
	let each_1_anchor;
	let each_value = /*highlightedAttr*/ ctx[4].values;
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m: function mount(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(target, anchor);
			}

			insert_dev(target, each_1_anchor, anchor);
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*addValue, highlightedAttr*/ 528) {
				each_value = /*highlightedAttr*/ ctx[4].values;
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d: function destroy(detaching) {
			destroy_each(each_blocks, detaching);
			if (detaching) detach_dev(each_1_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(189:6) {#if highlightedAttr}",
		ctx
	});

	return block;
}

// (190:8) {#each highlightedAttr.values as value}
function create_each_block(ctx) {
	let li;
	let t0_value = /*value*/ ctx[24] + "";
	let t0;
	let t1;
	let mounted;
	let dispose;

	function click_handler_3() {
		return /*click_handler_3*/ ctx[18](/*value*/ ctx[24]);
	}

	const block = {
		c: function create() {
			li = element("li");
			t0 = text(t0_value);
			t1 = space();
			add_location(li, file, 190, 10, 5218);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, t0);
			append_dev(li, t1);

			if (!mounted) {
				dispose = listen_dev(li, "click", click_handler_3, false, false, false);
				mounted = true;
			}
		},
		p: function update(new_ctx, dirty) {
			ctx = new_ctx;
			if (dirty[0] & /*highlightedAttr*/ 16 && t0_value !== (t0_value = /*value*/ ctx[24] + "")) set_data_dev(t0, t0_value);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(190:8) {#each highlightedAttr.values as value}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div6;
	let div2;
	let div0;
	let h20;
	let t1;
	let button;
	let ordericon;
	let t2;
	let div1;
	let input;
	let t3;
	let ul0;
	let t4;
	let div3;
	let h21;
	let t6;
	let ul1;
	let t7;
	let div5;
	let div4;
	let h22;
	let t9;
	let t10;
	let ul2;
	let current;
	let mounted;
	let dispose;
	ordericon = new OrderIcon({ $$inline: true });
	let each_value_2 = /*reasonCodesAndAttributes*/ ctx[1];
	validate_each_argument(each_value_2);
	let each_blocks = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	let if_block0 = /*selectedReason*/ ctx[3] && create_if_block_2(ctx);
	let if_block1 = /*valueAdded*/ ctx[5] && create_if_block_1(ctx);
	let if_block2 = /*highlightedAttr*/ ctx[4] && create_if_block(ctx);

	const block = {
		c: function create() {
			div6 = element("div");
			div2 = element("div");
			div0 = element("div");
			h20 = element("h2");
			h20.textContent = "Reason Codes";
			t1 = space();
			button = element("button");
			create_component(ordericon.$$.fragment);
			t2 = space();
			div1 = element("div");
			input = element("input");
			t3 = space();
			ul0 = element("ul");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t4 = space();
			div3 = element("div");
			h21 = element("h2");
			h21.textContent = "Attributes";
			t6 = space();
			ul1 = element("ul");
			if (if_block0) if_block0.c();
			t7 = space();
			div5 = element("div");
			div4 = element("div");
			h22 = element("h2");
			h22.textContent = "Values";
			t9 = space();
			if (if_block1) if_block1.c();
			t10 = space();
			ul2 = element("ul");
			if (if_block2) if_block2.c();
			add_location(h20, file, 121, 6, 3325);
			attr_dev(button, "class", "w-7 bg-gray-200 shadow-none");
			add_location(button, file, 122, 6, 3353);
			attr_dev(div0, "class", "flex justify-between mb-1");
			add_location(div0, file, 120, 4, 3279);
			attr_dev(input, "name", "search");
			attr_dev(input, "placeholder", "Search");
			attr_dev(input, "class", "my-2");
			add_location(input, file, 130, 6, 3538);
			attr_dev(div1, "class", "w-full");
			add_location(div1, file, 129, 4, 3511);
			add_location(ul0, file, 138, 4, 3710);
			attr_dev(div2, "class", "w-1/3 p-2");
			add_location(div2, file, 119, 2, 3251);
			add_location(h21, file, 157, 4, 4238);
			attr_dev(ul1, "class", "attr-list");
			add_location(ul1, file, 158, 4, 4262);
			attr_dev(div3, "class", "w-1/3 p-2");
			add_location(div3, file, 156, 2, 4210);
			add_location(h22, file, 184, 6, 5000);
			attr_dev(div4, "class", "flex justify-between");
			add_location(div4, file, 183, 4, 4959);
			attr_dev(ul2, "class", "values-list");
			add_location(ul2, file, 187, 4, 5107);
			attr_dev(div5, "class", "w-1/3 p-2");
			add_location(div5, file, 182, 2, 4931);
			attr_dev(div6, "class", "reason-selector-container w-full flex p-2 text-sm");
			add_location(div6, file, 118, 0, 3185);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div6, anchor);
			append_dev(div6, div2);
			append_dev(div2, div0);
			append_dev(div0, h20);
			append_dev(div0, t1);
			append_dev(div0, button);
			mount_component(ordericon, button, null);
			append_dev(div2, t2);
			append_dev(div2, div1);
			append_dev(div1, input);
			set_input_value(input, /*search*/ ctx[2]);
			append_dev(div2, t3);
			append_dev(div2, ul0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul0, null);
			}

			append_dev(div6, t4);
			append_dev(div6, div3);
			append_dev(div3, h21);
			append_dev(div3, t6);
			append_dev(div3, ul1);
			if (if_block0) if_block0.m(ul1, null);
			append_dev(div6, t7);
			append_dev(div6, div5);
			append_dev(div5, div4);
			append_dev(div4, h22);
			append_dev(div4, t9);
			if (if_block1) if_block1.m(div4, null);
			append_dev(div5, t10);
			append_dev(div5, ul2);
			if (if_block2) if_block2.m(ul2, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen_dev(button, "click", /*click_handler*/ ctx[11], false, false, false),
					listen_dev(input, "input", /*input_input_handler*/ ctx[12]),
					listen_dev(input, "input", /*input_handler*/ ctx[13], false, false, false)
				];

				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (dirty[0] & /*search*/ 4 && input.value !== /*search*/ ctx[2]) {
				set_input_value(input, /*search*/ ctx[2]);
			}

			if (dirty[0] & /*selectedReason, reasonCodesAndAttributes, selectedAttributes, highlightedAttr, buildObject*/ 283) {
				each_value_2 = /*reasonCodesAndAttributes*/ ctx[1];
				validate_each_argument(each_value_2);
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_2(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ul0, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_2.length;
			}

			if (/*selectedReason*/ ctx[3]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(ul1, null);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if (/*valueAdded*/ ctx[5]) {
				if (if_block1) {
					
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					if_block1.m(div4, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (/*highlightedAttr*/ ctx[4]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block(ctx);
					if_block2.c();
					if_block2.m(ul2, null);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}
		},
		i: function intro(local) {
			if (current) return;
			transition_in(ordericon.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(ordericon.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div6);
			destroy_component(ordericon);
			destroy_each(each_blocks, detaching);
			if (if_block0) if_block0.d();
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('ReasonCodeAndAttrSelector', slots, []);
	let reasonCodesAndAttributes = [...get(reasonCodesAndAttr)];
	sortReasons('ASC');
	const copyReasons = Object.assign({}, reasonCodesAndAttributes);
	let search = '';
	let selectedReason = null;
	let highlightedAttr = null;
	let selectedAttributes = [];
	let valueAdded = false;
	let valueAddedTO;
	let currentSort = 'ASC';
	let { onChange = null } = $$props;

	function showValueConfirmation() {
		$$invalidate(5, valueAdded = true);

		if (valueAddedTO) {
			clearTimeout(valueAddedTO);
		}

		valueAddedTO = setTimeout(
			() => {
				$$invalidate(5, valueAdded = false);
			},
			300
		);
	}

	function filterReasons() {
		const allReasonCodes = [...get(reasonCodesAndAttr)];

		if (search.trim() === '') {
			$$invalidate(1, reasonCodesAndAttributes = [...allReasonCodes]);
		}

		const filtered = allReasonCodes.filter(item => item.reasonName.toLowerCase().includes(search.toLocaleLowerCase()) || item.reasonCode.toLowerCase().includes(search.toLocaleLowerCase()));
		$$invalidate(1, reasonCodesAndAttributes = [...filtered]);
	}

	function sortReasons(order) {
		reasonCodesAndAttributes.sort((a, b) => {
			const aLower = a.reasonName.toLowerCase();
			const bLower = b.reasonName.toLowerCase();

			if (aLower < bLower) {
				if (order === 'ASC') {
					return -1;
				}

				return 1;
			}

			if (aLower > bLower) {
				if (order === 'ASC') {
					return 1;
				}

				return -1;
			}

			return 0;
		});

		$$invalidate(1, reasonCodesAndAttributes = [...reasonCodesAndAttributes]);
	}

	function toggleSort() {
		if (currentSort === 'ASC') {
			sortReasons('DESC');
			currentSort = 'DESC';
		} else {
			sortReasons('ASC');
			currentSort = 'ASC';
		}
	}

	function buildObject() {
		const obj = {};

		if (!selectedReason) {
			return;
		}

		obj.reasonCode = selectedReason.reasonCode;

		if (selectedAttributes.length > 0) {
			obj.attributeValues = [];

			selectedAttributes.forEach(attr => {
				const tempAttr = { attributeName: attr.name, value: '' };

				if (attr.selectedValues && attr.selectedValues.length > 0) {
					tempAttr.value = attr.selectedValues.join(',');
				}

				obj.attributeValues.push(tempAttr);
			});
		}

		onChange(obj);
	}

	function addValue(name, value) {
		const attrElementIndex = selectedAttributes.findIndex(el => el.name === name);
		const attrElement = selectedAttributes[attrElementIndex];

		if (!attrElement) {
			return;
		}

		const selectedValues = attrElement && attrElement.selectedValues;

		if (!selectedValues) {
			attrElement.selectedValues = [];
		}

		if (!selectedValues || selectedValues && !selectedValues.includes(value)) {
			attrElement.selectedValues = [...attrElement.selectedValues, value];
		}

		showValueConfirmation();
		buildObject();
	}

	const writable_props = ['onChange'];

	Object_1.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<ReasonCodeAndAttrSelector> was created with unknown prop '${key}'`);
	});

	const $$binding_groups = [[]];

	const click_handler = () => {
		toggleSort();
	};

	function input_input_handler() {
		search = this.value;
		$$invalidate(2, search);
	}

	const input_handler = () => filterReasons();

	const click_handler_1 = reasonItem => {
		if (selectedReason !== null) {
			$$invalidate(0, selectedAttributes = []);
			$$invalidate(4, highlightedAttr = null);
		}

		$$invalidate(3, selectedReason = { ...reasonItem });
		buildObject();
	};

	function input_change_handler() {
		selectedAttributes = get_binding_group_value($$binding_groups[0], this.__value, this.checked);
		$$invalidate(0, selectedAttributes);
	}

	const click_handler_2 = attrItem => {
		$$invalidate(4, highlightedAttr = attrItem);
	};

	const click_handler_3 = value => {
		addValue(highlightedAttr.name, value);
	};

	$$self.$$set = $$props => {
		if ('onChange' in $$props) $$invalidate(10, onChange = $$props.onChange);
	};

	$$self.$capture_state = () => ({
		reasonCodesAndAttr,
		get,
		join,
		sort,
		OrderIcon,
		InputListSearchProvider,
		reasonCodesAndAttributes,
		copyReasons,
		search,
		selectedReason,
		highlightedAttr,
		selectedAttributes,
		valueAdded,
		valueAddedTO,
		currentSort,
		onChange,
		showValueConfirmation,
		filterReasons,
		sortReasons,
		toggleSort,
		buildObject,
		addValue
	});

	$$self.$inject_state = $$props => {
		if ('reasonCodesAndAttributes' in $$props) $$invalidate(1, reasonCodesAndAttributes = $$props.reasonCodesAndAttributes);
		if ('search' in $$props) $$invalidate(2, search = $$props.search);
		if ('selectedReason' in $$props) $$invalidate(3, selectedReason = $$props.selectedReason);
		if ('highlightedAttr' in $$props) $$invalidate(4, highlightedAttr = $$props.highlightedAttr);
		if ('selectedAttributes' in $$props) $$invalidate(0, selectedAttributes = $$props.selectedAttributes);
		if ('valueAdded' in $$props) $$invalidate(5, valueAdded = $$props.valueAdded);
		if ('valueAddedTO' in $$props) valueAddedTO = $$props.valueAddedTO;
		if ('currentSort' in $$props) currentSort = $$props.currentSort;
		if ('onChange' in $$props) $$invalidate(10, onChange = $$props.onChange);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*selectedAttributes*/ 1) {
			$: selectedAttributes && buildObject();
		}
	};

	return [
		selectedAttributes,
		reasonCodesAndAttributes,
		search,
		selectedReason,
		highlightedAttr,
		valueAdded,
		filterReasons,
		toggleSort,
		buildObject,
		addValue,
		onChange,
		click_handler,
		input_input_handler,
		input_handler,
		click_handler_1,
		input_change_handler,
		$$binding_groups,
		click_handler_2,
		click_handler_3
	];
}

class ReasonCodeAndAttrSelector extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, { onChange: 10 }, null, [-1, -1]);

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "ReasonCodeAndAttrSelector",
			options,
			id: create_fragment.name
		});
	}

	get onChange() {
		throw new Error("<ReasonCodeAndAttrSelector>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set onChange(value) {
		throw new Error("<ReasonCodeAndAttrSelector>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}
}

export default ReasonCodeAndAttrSelector;