/* components/App.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_flush_callback,
	add_location,
	append_dev,
	attr_dev,
	bind,
	binding_callbacks,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach_dev,
	dispatch_dev,
	element,
	empty,
	globals,
	group_outros,
	init,
	insert_dev,
	listen_dev,
	mount_component,
	noop,
	run_all,
	safe_not_equal,
	set_data_dev,
	space,
	text,
	transition_in,
	transition_out,
	validate_slots,
	validate_store
} from "svelte/internal";

const { console: console_1 } = globals;
import 'regenerator-runtime/runtime';
import { onMount } from 'svelte';
import Header from './Header.svelte';
import Login from './Login.svelte';
import Collapsable from './common/Collapsable.svelte';
import Button from './common/Button.svelte';
import ActionsPanel from './panels/Actions.svelte';
import Appearance from './panels/Appearance.svelte';
import Services from './panels/Services.svelte';
import Address from './panels/Address.svelte';
import EncounterConfig from './panels/EncounterConfig.svelte';
import HowTo from './panels/HowTo.svelte';
import Language from './panels/Language.svelte';
import Encounters from './panels/Encounters.svelte';
import EncounterMessages from './panels/EncounterMessages.svelte';
import { envs } from '../envs.js';
import { config } from '../firebaseConfig.js';
import { get } from 'svelte/store';
import 'dotenv/config';

import {
	initWidget,
	checkForStoredAndStart,
	widgetShow,
	widgetHide,
	widgetClose,
	widgetOpen,
	widgetLogout,
	widgetDestroy,
	clearStorage,
	getLocalStorage,
	setLocalStorage
} from '../actions.js';

import { loading, loggedin, cleared, currentPatient } from '../store';
import { useSelector } from 'react-redux';
import { initializeApp } from 'firebase/app';
import MoonIcon from './common/MoonIcon.svelte';
import CurrentEncounter from './panels/CurrentEncounter.svelte';
import Features from './panels/Features.svelte';
import Tracking from './panels/Tracking.svelte';
import Visibility from './panels/Visibility.svelte';
const file = "components/App.svelte";

// (104:4) <Collapsable ghost={true} title="How to use this app">
function create_default_slot_10(ctx) {
	let howto;
	let current;

	howto = new HowTo({
			props: { loggedin: get(loggedin) },
			$$inline: true
		});

	const block = {
		c: function create() {
			create_component(howto.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(howto, target, anchor);
			current = true;
		},
		p: noop,
		i: function intro(local) {
			if (current) return;
			transition_in(howto.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(howto.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(howto, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_10.name,
		type: "slot",
		source: "(104:4) <Collapsable ghost={true} title=\\\"How to use this app\\\">",
		ctx
	});

	return block;
}

// (119:2) {:else}
function create_else_block_1(ctx) {
	let actionspanel;
	let t0;
	let div1;
	let div0;
	let t2;
	let collapsable0;
	let t3;
	let collapsable1;
	let t4;
	let collapsable2;
	let t5;
	let collapsable3;
	let t6;
	let collapsable4;
	let t7;
	let collapsable5;
	let t8;
	let div3;
	let div2;
	let t10;
	let collapsable6;
	let t11;
	let collapsable7;
	let t12;
	let collapsable8;
	let t13;
	let div5;
	let div4;
	let t15;
	let collapsable9;
	let current;

	actionspanel = new ActionsPanel({
			props: {
				loggedin: /*$loggedin*/ ctx[5],
				currentPatient: /*$currentPatient*/ ctx[6]
			},
			$$inline: true
		});

	actionspanel.$on("show", widgetShow);
	actionspanel.$on("hide", widgetHide);
	actionspanel.$on("close", widgetClose);
	actionspanel.$on("open", widgetOpen);
	actionspanel.$on("logout", widgetLogout);
	actionspanel.$on("destroy", widgetDestroy);
	actionspanel.$on("clear", clearStorage);

	collapsable0 = new Collapsable({
			props: {
				title: "Visibility",
				$$slots: { default: [create_default_slot_9] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	collapsable1 = new Collapsable({
			props: {
				title: "Language",
				$$slots: { default: [create_default_slot_8] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	collapsable2 = new Collapsable({
			props: {
				title: "Address",
				$$slots: { default: [create_default_slot_7] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	collapsable3 = new Collapsable({
			props: {
				title: "Services",
				$$slots: { default: [create_default_slot_6] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	collapsable4 = new Collapsable({
			props: {
				title: "Appearance",
				$$slots: { default: [create_default_slot_5] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	collapsable5 = new Collapsable({
			props: {
				title: "Features",
				$$slots: { default: [create_default_slot_4] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	collapsable6 = new Collapsable({
			props: {
				title: "Current Encounter",
				$$slots: { default: [create_default_slot_3] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	collapsable7 = new Collapsable({
			props: {
				title: "Encounter list",
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	collapsable8 = new Collapsable({
			props: {
				title: "Encounter Messages",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	collapsable9 = new Collapsable({
			props: {
				title: "Tracking Options",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	const block = {
		c: function create() {
			create_component(actionspanel.$$.fragment);
			t0 = space();
			div1 = element("div");
			div0 = element("div");
			div0.textContent = "Init Configuration:";
			t2 = space();
			create_component(collapsable0.$$.fragment);
			t3 = space();
			create_component(collapsable1.$$.fragment);
			t4 = space();
			create_component(collapsable2.$$.fragment);
			t5 = space();
			create_component(collapsable3.$$.fragment);
			t6 = space();
			create_component(collapsable4.$$.fragment);
			t7 = space();
			create_component(collapsable5.$$.fragment);
			t8 = space();
			div3 = element("div");
			div2 = element("div");
			div2.textContent = "Encounter Data:";
			t10 = space();
			create_component(collapsable6.$$.fragment);
			t11 = space();
			create_component(collapsable7.$$.fragment);
			t12 = space();
			create_component(collapsable8.$$.fragment);
			t13 = space();
			div5 = element("div");
			div4 = element("div");
			div4.textContent = "Tracking";
			t15 = space();
			create_component(collapsable9.$$.fragment);
			attr_dev(div0, "class", "flex-1 border-b border-gray-300 pb-1 font-bold mb-3");
			add_location(div0, file, 132, 6, 4306);
			attr_dev(div1, "class", "my-8");
			add_location(div1, file, 131, 4, 4281);
			attr_dev(div2, "class", "flex-1 border-b border-gray-300 pb-1 font-bold mb-3");
			add_location(div2, file, 153, 6, 4917);
			attr_dev(div3, "class", "my-8");
			add_location(div3, file, 152, 4, 4892);
			attr_dev(div4, "class", "flex-1 border-b border-gray-300 pb-1 font-bold mb-3");
			add_location(div4, file, 165, 6, 5325);
			attr_dev(div5, "class", "my-8");
			add_location(div5, file, 164, 4, 5300);
		},
		m: function mount(target, anchor) {
			mount_component(actionspanel, target, anchor);
			insert_dev(target, t0, anchor);
			insert_dev(target, div1, anchor);
			append_dev(div1, div0);
			append_dev(div1, t2);
			mount_component(collapsable0, div1, null);
			append_dev(div1, t3);
			mount_component(collapsable1, div1, null);
			append_dev(div1, t4);
			mount_component(collapsable2, div1, null);
			append_dev(div1, t5);
			mount_component(collapsable3, div1, null);
			append_dev(div1, t6);
			mount_component(collapsable4, div1, null);
			append_dev(div1, t7);
			mount_component(collapsable5, div1, null);
			insert_dev(target, t8, anchor);
			insert_dev(target, div3, anchor);
			append_dev(div3, div2);
			append_dev(div3, t10);
			mount_component(collapsable6, div3, null);
			append_dev(div3, t11);
			mount_component(collapsable7, div3, null);
			append_dev(div3, t12);
			mount_component(collapsable8, div3, null);
			insert_dev(target, t13, anchor);
			insert_dev(target, div5, anchor);
			append_dev(div5, div4);
			append_dev(div5, t15);
			mount_component(collapsable9, div5, null);
			current = true;
		},
		p: function update(ctx, dirty) {
			const actionspanel_changes = {};
			if (dirty & /*$loggedin*/ 32) actionspanel_changes.loggedin = /*$loggedin*/ ctx[5];
			if (dirty & /*$currentPatient*/ 64) actionspanel_changes.currentPatient = /*$currentPatient*/ ctx[6];
			actionspanel.$set(actionspanel_changes);
			const collapsable0_changes = {};

			if (dirty & /*$$scope*/ 16384) {
				collapsable0_changes.$$scope = { dirty, ctx };
			}

			collapsable0.$set(collapsable0_changes);
			const collapsable1_changes = {};

			if (dirty & /*$$scope*/ 16384) {
				collapsable1_changes.$$scope = { dirty, ctx };
			}

			collapsable1.$set(collapsable1_changes);
			const collapsable2_changes = {};

			if (dirty & /*$$scope*/ 16384) {
				collapsable2_changes.$$scope = { dirty, ctx };
			}

			collapsable2.$set(collapsable2_changes);
			const collapsable3_changes = {};

			if (dirty & /*$$scope*/ 16384) {
				collapsable3_changes.$$scope = { dirty, ctx };
			}

			collapsable3.$set(collapsable3_changes);
			const collapsable4_changes = {};

			if (dirty & /*$$scope*/ 16384) {
				collapsable4_changes.$$scope = { dirty, ctx };
			}

			collapsable4.$set(collapsable4_changes);
			const collapsable5_changes = {};

			if (dirty & /*$$scope*/ 16384) {
				collapsable5_changes.$$scope = { dirty, ctx };
			}

			collapsable5.$set(collapsable5_changes);
			const collapsable6_changes = {};

			if (dirty & /*$$scope*/ 16384) {
				collapsable6_changes.$$scope = { dirty, ctx };
			}

			collapsable6.$set(collapsable6_changes);
			const collapsable7_changes = {};

			if (dirty & /*$$scope*/ 16384) {
				collapsable7_changes.$$scope = { dirty, ctx };
			}

			collapsable7.$set(collapsable7_changes);
			const collapsable8_changes = {};

			if (dirty & /*$$scope*/ 16384) {
				collapsable8_changes.$$scope = { dirty, ctx };
			}

			collapsable8.$set(collapsable8_changes);
			const collapsable9_changes = {};

			if (dirty & /*$$scope*/ 16384) {
				collapsable9_changes.$$scope = { dirty, ctx };
			}

			collapsable9.$set(collapsable9_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(actionspanel.$$.fragment, local);
			transition_in(collapsable0.$$.fragment, local);
			transition_in(collapsable1.$$.fragment, local);
			transition_in(collapsable2.$$.fragment, local);
			transition_in(collapsable3.$$.fragment, local);
			transition_in(collapsable4.$$.fragment, local);
			transition_in(collapsable5.$$.fragment, local);
			transition_in(collapsable6.$$.fragment, local);
			transition_in(collapsable7.$$.fragment, local);
			transition_in(collapsable8.$$.fragment, local);
			transition_in(collapsable9.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(actionspanel.$$.fragment, local);
			transition_out(collapsable0.$$.fragment, local);
			transition_out(collapsable1.$$.fragment, local);
			transition_out(collapsable2.$$.fragment, local);
			transition_out(collapsable3.$$.fragment, local);
			transition_out(collapsable4.$$.fragment, local);
			transition_out(collapsable5.$$.fragment, local);
			transition_out(collapsable6.$$.fragment, local);
			transition_out(collapsable7.$$.fragment, local);
			transition_out(collapsable8.$$.fragment, local);
			transition_out(collapsable9.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(actionspanel, detaching);
			if (detaching) detach_dev(t0);
			if (detaching) detach_dev(div1);
			destroy_component(collapsable0);
			destroy_component(collapsable1);
			destroy_component(collapsable2);
			destroy_component(collapsable3);
			destroy_component(collapsable4);
			destroy_component(collapsable5);
			if (detaching) detach_dev(t8);
			if (detaching) detach_dev(div3);
			destroy_component(collapsable6);
			destroy_component(collapsable7);
			destroy_component(collapsable8);
			if (detaching) detach_dev(t13);
			if (detaching) detach_dev(div5);
			destroy_component(collapsable9);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block_1.name,
		type: "else",
		source: "(119:2) {:else}",
		ctx
	});

	return block;
}

// (108:2) {#if $cleared}
function create_if_block(ctx) {
	let t;
	let current_block_type_index;
	let if_block1;
	let if_block1_anchor;
	let current;
	let if_block0 = /*error*/ ctx[0] && create_if_block_2(ctx);
	const if_block_creators = [create_if_block_1, create_else_block];
	const if_blocks = [];

	function select_block_type_1(ctx, dirty) {
		if (!/*$loading*/ ctx[4]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type_1(ctx, -1);
	if_block1 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	const block = {
		c: function create() {
			if (if_block0) if_block0.c();
			t = space();
			if_block1.c();
			if_block1_anchor = empty();
		},
		m: function mount(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert_dev(target, t, anchor);
			if_blocks[current_block_type_index].m(target, anchor);
			insert_dev(target, if_block1_anchor, anchor);
			current = true;
		},
		p: function update(ctx, dirty) {
			if (/*error*/ ctx[0]) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					if_block0.m(t.parentNode, t);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type_1(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block1 = if_blocks[current_block_type_index];

				if (!if_block1) {
					if_block1 = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block1.c();
				} else {
					if_block1.p(ctx, dirty);
				}

				transition_in(if_block1, 1);
				if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
			}
		},
		i: function intro(local) {
			if (current) return;
			transition_in(if_block1);
			current = true;
		},
		o: function outro(local) {
			transition_out(if_block1);
			current = false;
		},
		d: function destroy(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach_dev(t);
			if_blocks[current_block_type_index].d(detaching);
			if (detaching) detach_dev(if_block1_anchor);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(108:2) {#if $cleared}",
		ctx
	});

	return block;
}

// (134:6) <Collapsable title="Visibility">
function create_default_slot_9(ctx) {
	let visibility;
	let current;
	visibility = new Visibility({ $$inline: true });

	const block = {
		c: function create() {
			create_component(visibility.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(visibility, target, anchor);
			current = true;
		},
		i: function intro(local) {
			if (current) return;
			transition_in(visibility.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(visibility.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(visibility, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_9.name,
		type: "slot",
		source: "(134:6) <Collapsable title=\\\"Visibility\\\">",
		ctx
	});

	return block;
}

// (137:6) <Collapsable title="Language">
function create_default_slot_8(ctx) {
	let language;
	let current;
	language = new Language({ $$inline: true });

	const block = {
		c: function create() {
			create_component(language.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(language, target, anchor);
			current = true;
		},
		i: function intro(local) {
			if (current) return;
			transition_in(language.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(language.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(language, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_8.name,
		type: "slot",
		source: "(137:6) <Collapsable title=\\\"Language\\\">",
		ctx
	});

	return block;
}

// (140:6) <Collapsable title="Address">
function create_default_slot_7(ctx) {
	let address;
	let current;
	address = new Address({ $$inline: true });

	const block = {
		c: function create() {
			create_component(address.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(address, target, anchor);
			current = true;
		},
		i: function intro(local) {
			if (current) return;
			transition_in(address.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(address.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(address, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_7.name,
		type: "slot",
		source: "(140:6) <Collapsable title=\\\"Address\\\">",
		ctx
	});

	return block;
}

// (143:6) <Collapsable title="Services">
function create_default_slot_6(ctx) {
	let services;
	let current;
	services = new Services({ $$inline: true });

	const block = {
		c: function create() {
			create_component(services.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(services, target, anchor);
			current = true;
		},
		i: function intro(local) {
			if (current) return;
			transition_in(services.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(services.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(services, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_6.name,
		type: "slot",
		source: "(143:6) <Collapsable title=\\\"Services\\\">",
		ctx
	});

	return block;
}

// (146:6) <Collapsable title="Appearance">
function create_default_slot_5(ctx) {
	let appearance;
	let current;
	appearance = new Appearance({ $$inline: true });

	const block = {
		c: function create() {
			create_component(appearance.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(appearance, target, anchor);
			current = true;
		},
		i: function intro(local) {
			if (current) return;
			transition_in(appearance.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(appearance.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(appearance, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_5.name,
		type: "slot",
		source: "(146:6) <Collapsable title=\\\"Appearance\\\">",
		ctx
	});

	return block;
}

// (149:6) <Collapsable title="Features">
function create_default_slot_4(ctx) {
	let features;
	let current;
	features = new Features({ $$inline: true });

	const block = {
		c: function create() {
			create_component(features.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(features, target, anchor);
			current = true;
		},
		i: function intro(local) {
			if (current) return;
			transition_in(features.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(features.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(features, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_4.name,
		type: "slot",
		source: "(149:6) <Collapsable title=\\\"Features\\\">",
		ctx
	});

	return block;
}

// (155:6) <Collapsable title="Current Encounter">
function create_default_slot_3(ctx) {
	let currentencounter;
	let current;
	currentencounter = new CurrentEncounter({ $$inline: true });

	const block = {
		c: function create() {
			create_component(currentencounter.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(currentencounter, target, anchor);
			current = true;
		},
		i: function intro(local) {
			if (current) return;
			transition_in(currentencounter.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(currentencounter.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(currentencounter, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_3.name,
		type: "slot",
		source: "(155:6) <Collapsable title=\\\"Current Encounter\\\">",
		ctx
	});

	return block;
}

// (158:6) <Collapsable title="Encounter list">
function create_default_slot_2(ctx) {
	let encounters;
	let current;
	encounters = new Encounters({ $$inline: true });

	const block = {
		c: function create() {
			create_component(encounters.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(encounters, target, anchor);
			current = true;
		},
		i: function intro(local) {
			if (current) return;
			transition_in(encounters.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(encounters.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(encounters, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_2.name,
		type: "slot",
		source: "(158:6) <Collapsable title=\\\"Encounter list\\\">",
		ctx
	});

	return block;
}

// (161:6) <Collapsable title="Encounter Messages">
function create_default_slot_1(ctx) {
	let encountermessages;
	let current;
	encountermessages = new EncounterMessages({ $$inline: true });

	const block = {
		c: function create() {
			create_component(encountermessages.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(encountermessages, target, anchor);
			current = true;
		},
		i: function intro(local) {
			if (current) return;
			transition_in(encountermessages.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(encountermessages.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(encountermessages, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_1.name,
		type: "slot",
		source: "(161:6) <Collapsable title=\\\"Encounter Messages\\\">",
		ctx
	});

	return block;
}

// (167:6) <Collapsable title="Tracking Options">
function create_default_slot(ctx) {
	let tracking;
	let current;
	tracking = new Tracking({ $$inline: true });

	const block = {
		c: function create() {
			create_component(tracking.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(tracking, target, anchor);
			current = true;
		},
		i: function intro(local) {
			if (current) return;
			transition_in(tracking.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(tracking.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(tracking, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot.name,
		type: "slot",
		source: "(167:6) <Collapsable title=\\\"Tracking Options\\\">",
		ctx
	});

	return block;
}

// (109:4) {#if error}
function create_if_block_2(ctx) {
	let div;
	let t;

	const block = {
		c: function create() {
			div = element("div");
			t = text(/*error*/ ctx[0]);
			attr_dev(div, "class", "flex-1 text-white bg-red-600 p-1 mt-3");
			add_location(div, file, 109, 6, 3691);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, t);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*error*/ 1) set_data_dev(t, /*error*/ ctx[0]);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(109:4) {#if error}",
		ctx
	});

	return block;
}

// (114:4) {:else}
function create_else_block(ctx) {
	let div;
	let span;

	const block = {
		c: function create() {
			div = element("div");
			span = element("span");
			span.textContent = "Loading...";
			attr_dev(span, "class", "text-2xl");
			add_location(span, file, 115, 8, 3907);
			attr_dev(div, "class", "flex-1 text-center mt-20");
			add_location(div, file, 114, 6, 3860);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, span);
		},
		p: noop,
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(114:4) {:else}",
		ctx
	});

	return block;
}

// (112:4) {#if !$loading}
function create_if_block_1(ctx) {
	let login;
	let updating_environment;
	let current;

	function login_environment_binding(value) {
		/*login_environment_binding*/ ctx[11](value);
	}

	let login_props = {};

	if (/*environment*/ ctx[2] !== void 0) {
		login_props.environment = /*environment*/ ctx[2];
	}

	login = new Login({ props: login_props, $$inline: true });
	binding_callbacks.push(() => bind(login, 'environment', login_environment_binding));
	login.$on("login", /*handleLogin*/ ctx[8]);

	const block = {
		c: function create() {
			create_component(login.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(login, target, anchor);
			current = true;
		},
		p: function update(ctx, dirty) {
			const login_changes = {};

			if (!updating_environment && dirty & /*environment*/ 4) {
				updating_environment = true;
				login_changes.environment = /*environment*/ ctx[2];
				add_flush_callback(() => updating_environment = false);
			}

			login.$set(login_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(login.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(login.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(login, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(112:4) {#if !$loading}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div1;
	let header;
	let t0;
	let div0;
	let button0;
	let raw_value = (/*centered*/ ctx[1] ? `&#9664;` : `&#9658;`) + "";
	let t1;
	let button1;
	let moonicon;
	let t2;
	let collapsable;
	let t3;
	let current_block_type_index;
	let if_block;
	let div1_class_value;
	let current;
	let mounted;
	let dispose;

	header = new Header({
			props: { isDev: /*isDev*/ ctx[7] },
			$$inline: true
		});

	moonicon = new MoonIcon({ $$inline: true });

	collapsable = new Collapsable({
			props: {
				ghost: true,
				title: "How to use this app",
				$$slots: { default: [create_default_slot_10] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	const if_block_creators = [create_if_block, create_else_block_1];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*$cleared*/ ctx[3]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	const block = {
		c: function create() {
			div1 = element("div");
			create_component(header.$$.fragment);
			t0 = space();
			div0 = element("div");
			button0 = element("button");
			t1 = space();
			button1 = element("button");
			create_component(moonicon.$$.fragment);
			t2 = space();
			create_component(collapsable.$$.fragment);
			t3 = space();
			if_block.c();
			attr_dev(button0, "class", "center-toggle");
			add_location(button0, file, 87, 4, 2948);
			attr_dev(button1, "class", "dark-toggle");
			add_location(button1, file, 90, 4, 3084);
			attr_dev(div0, "class", "flex");
			add_location(div0, file, 86, 2, 2925);
			attr_dev(div1, "class", div1_class_value = "container max-w-4xl dark:text-white " + (/*centered*/ ctx[1] ? 'md:mx-auto' : '') + " mb-20 p-4");
			add_location(div1, file, 84, 0, 2811);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			mount_component(header, div1, null);
			append_dev(div1, t0);
			append_dev(div1, div0);
			append_dev(div0, button0);
			button0.innerHTML = raw_value;
			append_dev(div0, t1);
			append_dev(div0, button1);
			mount_component(moonicon, button1, null);
			append_dev(div0, t2);
			mount_component(collapsable, div0, null);
			append_dev(div1, t3);
			if_blocks[current_block_type_index].m(div1, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen_dev(button0, "click", /*click_handler*/ ctx[9], false, false, false),
					listen_dev(button1, "click", /*click_handler_1*/ ctx[10], false, false, false)
				];

				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if ((!current || dirty & /*centered*/ 2) && raw_value !== (raw_value = (/*centered*/ ctx[1] ? `&#9664;` : `&#9658;`) + "")) button0.innerHTML = raw_value;;
			const collapsable_changes = {};

			if (dirty & /*$$scope*/ 16384) {
				collapsable_changes.$$scope = { dirty, ctx };
			}

			collapsable.$set(collapsable_changes);
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(div1, null);
			}

			if (!current || dirty & /*centered*/ 2 && div1_class_value !== (div1_class_value = "container max-w-4xl dark:text-white " + (/*centered*/ ctx[1] ? 'md:mx-auto' : '') + " mb-20 p-4")) {
				attr_dev(div1, "class", div1_class_value);
			}
		},
		i: function intro(local) {
			if (current) return;
			transition_in(header.$$.fragment, local);
			transition_in(moonicon.$$.fragment, local);
			transition_in(collapsable.$$.fragment, local);
			transition_in(if_block);
			current = true;
		},
		o: function outro(local) {
			transition_out(header.$$.fragment, local);
			transition_out(moonicon.$$.fragment, local);
			transition_out(collapsable.$$.fragment, local);
			transition_out(if_block);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
			destroy_component(header);
			destroy_component(moonicon);
			destroy_component(collapsable);
			if_blocks[current_block_type_index].d();
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

const PROD_HOSTNAME = 'playground.websdk.pager.com';

function instance($$self, $$props, $$invalidate) {
	let $cleared;
	let $loading;
	let $loggedin;
	let $currentPatient;
	validate_store(cleared, 'cleared');
	component_subscribe($$self, cleared, $$value => $$invalidate(3, $cleared = $$value));
	validate_store(loading, 'loading');
	component_subscribe($$self, loading, $$value => $$invalidate(4, $loading = $$value));
	validate_store(loggedin, 'loggedin');
	component_subscribe($$self, loggedin, $$value => $$invalidate(5, $loggedin = $$value));
	validate_store(currentPatient, 'currentPatient');
	component_subscribe($$self, currentPatient, $$value => $$invalidate(6, $currentPatient = $$value));
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('App', slots, []);
	let isDev = window.location.hostname !== PROD_HOSTNAME;
	let error = null;
	let widget = null;
	let centered = true;
	let environment = getLocalStorage('env') && JSON.parse(getLocalStorage('env')) || envs.dev[0];
	let dark = getLocalStorage('dark');

	if (config.apiKey !== null) {
		initializeApp(config);
	} else {
		console.warn("Bad config when initializing Firebase");
	}

	onMount(async () => {
		await checkForStoredAndStart();

		if (getLocalStorage('dark') === null) {
			setLocalStorage('dark', true);
			document.querySelector('html').setAttribute('class', 'dark');
		} else {
			if (getLocalStorage('dark') === 'true') {
				document.querySelector('html').setAttribute('class', 'dark');
			}

			if (getLocalStorage('dark') === 'false') {
				document.querySelector('html').setAttribute('class', '');
			}
		}
	});

	async function handleLogin({ detail }) {
		const { env, authCode, data, token, mocked, endpoint, urls } = detail;

		try {
			await initWidget({
				env,
				authCode,
				data,
				token,
				mocked,
				endpoint
			});
		} catch(e) {
			console.log(e);
			$$invalidate(0, error = e);
		}
	}

	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console_1.warn(`<App> was created with unknown prop '${key}'`);
	});

	const click_handler = () => $$invalidate(1, centered = !centered);

	const click_handler_1 = () => {
		console.log(getLocalStorage('dark'));

		if (getLocalStorage('dark') === 'true') {
			document.querySelector('html').setAttribute('class', '');
			setLocalStorage('dark', false);
		} else {
			document.querySelector('html').setAttribute('class', 'dark');
			setLocalStorage('dark', true);
		}
	};

	function login_environment_binding(value) {
		environment = value;
		$$invalidate(2, environment);
	}

	$$self.$capture_state = () => ({
		onMount,
		Header,
		Login,
		Collapsable,
		Button,
		ActionsPanel,
		Appearance,
		Services,
		Address,
		EncounterConfig,
		HowTo,
		Language,
		Encounters,
		EncounterMessages,
		envs,
		config,
		get,
		initWidget,
		checkForStoredAndStart,
		widgetShow,
		widgetHide,
		widgetClose,
		widgetOpen,
		widgetLogout,
		widgetDestroy,
		clearStorage,
		getLocalStorage,
		setLocalStorage,
		loading,
		loggedin,
		cleared,
		currentPatient,
		useSelector,
		initializeApp,
		MoonIcon,
		CurrentEncounter,
		Features,
		Tracking,
		Visibility,
		PROD_HOSTNAME,
		isDev,
		error,
		widget,
		centered,
		environment,
		dark,
		handleLogin,
		$cleared,
		$loading,
		$loggedin,
		$currentPatient
	});

	$$self.$inject_state = $$props => {
		if ('isDev' in $$props) $$invalidate(7, isDev = $$props.isDev);
		if ('error' in $$props) $$invalidate(0, error = $$props.error);
		if ('widget' in $$props) widget = $$props.widget;
		if ('centered' in $$props) $$invalidate(1, centered = $$props.centered);
		if ('environment' in $$props) $$invalidate(2, environment = $$props.environment);
		if ('dark' in $$props) dark = $$props.dark;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [
		error,
		centered,
		environment,
		$cleared,
		$loading,
		$loggedin,
		$currentPatient,
		isDev,
		handleLogin,
		click_handler,
		click_handler_1,
		login_environment_binding
	];
}

class App extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "App",
			options,
			id: create_fragment.name
		});
	}
}

export default App;