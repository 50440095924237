/* components/panels/HowTo.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	noop,
	safe_not_equal,
	space,
	text,
	validate_slots
} from "svelte/internal";

const file = "components/panels/HowTo.svelte";

// (21:2) {:else}
function create_else_block(ctx) {
	let p0;
	let t1;
	let h50;
	let t3;
	let div0;
	let ul;
	let li0;
	let strong0;
	let t5;
	let code0;
	let t7;
	let t8;
	let li1;
	let strong1;
	let t10;
	let code1;
	let t12;
	let t13;
	let li2;
	let strong2;
	let t15;
	let code2;
	let t17;
	let t18;
	let li3;
	let strong3;
	let t20;
	let code3;
	let t22;
	let t23;
	let li4;
	let strong4;
	let t25;
	let span;
	let t27;
	let h51;
	let t29;
	let div1;
	let p1;
	let t30;
	let br;
	let t31;
	let strong5;
	let t33;
	let t34;
	let h52;
	let t36;
	let div2;
	let p2;
	let t38;
	let h53;
	let t40;
	let div3;
	let p3;
	let t42;
	let h54;
	let t44;
	let div4;
	let p4;

	const block = {
		c: function create() {
			p0 = element("p");
			p0.textContent = "Once logged in, browser will keep login data to restore the widget unless\n      the storage is cleared.";
			t1 = space();
			h50 = element("h5");
			h50.textContent = "Actions:";
			t3 = space();
			div0 = element("div");
			ul = element("ul");
			li0 = element("li");
			strong0 = element("strong");
			strong0.textContent = "Open";
			t5 = text("\n          : Calls\n          ");
			code0 = element("code");
			code0.textContent = "open()";
			t7 = text("\n          method on current widget's instance");
			t8 = space();
			li1 = element("li");
			strong1 = element("strong");
			strong1.textContent = "Close";
			t10 = text("\n          : Calls\n          ");
			code1 = element("code");
			code1.textContent = "close()";
			t12 = text("\n          method on current widget's instance");
			t13 = space();
			li2 = element("li");
			strong2 = element("strong");
			strong2.textContent = "Logout";
			t15 = text("\n          : Calls\n          ");
			code2 = element("code");
			code2.textContent = "logout()";
			t17 = text("\n          method on current widget's instance (browser refresh will re-login)");
			t18 = space();
			li3 = element("li");
			strong3 = element("strong");
			strong3.textContent = "Destroy";
			t20 = text("\n          : Calls\n          ");
			code3 = element("code");
			code3.textContent = "destroy()";
			t22 = text("\n          method on current widget's instance (browser refresh will re-login)");
			t23 = space();
			li4 = element("li");
			strong4 = element("strong");
			strong4.textContent = "Clear storage";
			t25 = text("\n          : Clears browser's local and session storage\n          ");
			span = element("span");
			span.textContent = "This will trigger a reload and back to login screen";
			t27 = space();
			h51 = element("h5");
			h51.textContent = "Address:";
			t29 = space();
			div1 = element("div");
			p1 = element("p");
			t30 = text("Use this field to input the address info that will be used to start an\n        encounter. When the new address is applied, the widget will reload.\n        ");
			br = element("br");
			t31 = space();
			strong5 = element("strong");
			strong5.textContent = "Note:";
			t33 = text("\n        If there's a current encounter open, changing address won't have any\n        effect.");
			t34 = space();
			h52 = element("h5");
			h52.textContent = "Services:";
			t36 = space();
			div2 = element("div");
			p2 = element("p");
			p2.textContent = "Use this field to input the services array. It will be used to display\n        the service menu, this is the screen that renders when the widget is\n        initilized and there's no encounter in progress It will let the user\n        choose the context in which the encounter will initialize. Clicking on\n        apply will reload the widget with given services.";
			t38 = space();
			h53 = element("h5");
			h53.textContent = "Appearance:";
			t40 = space();
			div3 = element("div");
			p3 = element("p");
			p3.textContent = "Use this field to input the appearance object. It will be used to\n        customize the UI of the widget. Clicking on apply will reload the widget\n        with given appearance. You can select from the combo some predefined\n        ones.";
			t42 = space();
			h54 = element("h5");
			h54.textContent = "Start with config:";
			t44 = space();
			div4 = element("div");
			p4 = element("p");
			p4.textContent = "Select a reason code and attributes from the list to start a new encounter bypassing the service screen.\n        The encounterConfig object can be modified.";
			add_location(p0, file, 21, 4, 622);
			add_location(h50, file, 25, 4, 749);
			add_location(strong0, file, 29, 10, 862);
			add_location(code0, file, 31, 10, 912);
			add_location(li0, file, 28, 8, 847);
			add_location(strong1, file, 35, 10, 1015);
			add_location(code1, file, 37, 10, 1066);
			add_location(li1, file, 34, 8, 1000);
			add_location(strong2, file, 41, 10, 1170);
			add_location(code2, file, 43, 10, 1222);
			add_location(li2, file, 40, 8, 1155);
			add_location(strong3, file, 47, 10, 1359);
			add_location(code3, file, 49, 10, 1412);
			add_location(li3, file, 46, 8, 1344);
			add_location(strong4, file, 53, 10, 1550);
			attr_dev(span, "class", "text-red-500");
			add_location(span, file, 55, 10, 1646);
			add_location(li4, file, 52, 8, 1535);
			attr_dev(ul, "class", "list-decimal list-inside pl-3");
			add_location(ul, file, 27, 6, 796);
			attr_dev(div0, "class", "pl-3");
			add_location(div0, file, 26, 4, 771);
			add_location(h51, file, 61, 4, 1797);
			add_location(br, file, 66, 8, 2011);
			add_location(strong5, file, 67, 8, 2026);
			add_location(p1, file, 63, 6, 1844);
			attr_dev(div1, "class", "pl-3");
			add_location(div1, file, 62, 4, 1819);
			add_location(h52, file, 72, 4, 2168);
			add_location(p2, file, 74, 6, 2216);
			attr_dev(div2, "class", "pl-3");
			add_location(div2, file, 73, 4, 2191);
			add_location(h53, file, 82, 4, 2616);
			add_location(p3, file, 84, 6, 2666);
			attr_dev(div3, "class", "pl-3");
			add_location(div3, file, 83, 4, 2641);
			add_location(h54, file, 91, 4, 2942);
			add_location(p4, file, 93, 6, 2999);
			attr_dev(div4, "class", "pl-3");
			add_location(div4, file, 92, 4, 2974);
		},
		m: function mount(target, anchor) {
			insert_dev(target, p0, anchor);
			insert_dev(target, t1, anchor);
			insert_dev(target, h50, anchor);
			insert_dev(target, t3, anchor);
			insert_dev(target, div0, anchor);
			append_dev(div0, ul);
			append_dev(ul, li0);
			append_dev(li0, strong0);
			append_dev(li0, t5);
			append_dev(li0, code0);
			append_dev(li0, t7);
			append_dev(ul, t8);
			append_dev(ul, li1);
			append_dev(li1, strong1);
			append_dev(li1, t10);
			append_dev(li1, code1);
			append_dev(li1, t12);
			append_dev(ul, t13);
			append_dev(ul, li2);
			append_dev(li2, strong2);
			append_dev(li2, t15);
			append_dev(li2, code2);
			append_dev(li2, t17);
			append_dev(ul, t18);
			append_dev(ul, li3);
			append_dev(li3, strong3);
			append_dev(li3, t20);
			append_dev(li3, code3);
			append_dev(li3, t22);
			append_dev(ul, t23);
			append_dev(ul, li4);
			append_dev(li4, strong4);
			append_dev(li4, t25);
			append_dev(li4, span);
			insert_dev(target, t27, anchor);
			insert_dev(target, h51, anchor);
			insert_dev(target, t29, anchor);
			insert_dev(target, div1, anchor);
			append_dev(div1, p1);
			append_dev(p1, t30);
			append_dev(p1, br);
			append_dev(p1, t31);
			append_dev(p1, strong5);
			append_dev(p1, t33);
			insert_dev(target, t34, anchor);
			insert_dev(target, h52, anchor);
			insert_dev(target, t36, anchor);
			insert_dev(target, div2, anchor);
			append_dev(div2, p2);
			insert_dev(target, t38, anchor);
			insert_dev(target, h53, anchor);
			insert_dev(target, t40, anchor);
			insert_dev(target, div3, anchor);
			append_dev(div3, p3);
			insert_dev(target, t42, anchor);
			insert_dev(target, h54, anchor);
			insert_dev(target, t44, anchor);
			insert_dev(target, div4, anchor);
			append_dev(div4, p4);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(p0);
			if (detaching) detach_dev(t1);
			if (detaching) detach_dev(h50);
			if (detaching) detach_dev(t3);
			if (detaching) detach_dev(div0);
			if (detaching) detach_dev(t27);
			if (detaching) detach_dev(h51);
			if (detaching) detach_dev(t29);
			if (detaching) detach_dev(div1);
			if (detaching) detach_dev(t34);
			if (detaching) detach_dev(h52);
			if (detaching) detach_dev(t36);
			if (detaching) detach_dev(div2);
			if (detaching) detach_dev(t38);
			if (detaching) detach_dev(h53);
			if (detaching) detach_dev(t40);
			if (detaching) detach_dev(div3);
			if (detaching) detach_dev(t42);
			if (detaching) detach_dev(h54);
			if (detaching) detach_dev(t44);
			if (detaching) detach_dev(div4);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(21:2) {:else}",
		ctx
	});

	return block;
}

// (7:2) {#if !loggedin}
function create_if_block(ctx) {
	let h5;
	let t1;
	let div;
	let ol;
	let li0;
	let t3;
	let li1;
	let t5;
	let li2;
	let t7;
	let li3;

	const block = {
		c: function create() {
			h5 = element("h5");
			h5.textContent = "To login:";
			t1 = space();
			div = element("div");
			ol = element("ol");
			li0 = element("li");
			li0.textContent = "Select an enviroment from the list. If you can't find the correct\n          environment/apiKey, select \"Other\" option and you will be able to set\n          a custom apiKey";
			t3 = space();
			li1 = element("li");
			li1.textContent = "Set the token";
			t5 = space();
			li2 = element("li");
			li2.textContent = "Put the additional data (if required)";
			t7 = space();
			li3 = element("li");
			li3.textContent = "Click on login button";
			add_location(h5, file, 7, 4, 160);
			add_location(li0, file, 10, 8, 259);
			add_location(li1, file, 15, 8, 468);
			add_location(li2, file, 16, 8, 499);
			add_location(li3, file, 17, 8, 554);
			attr_dev(ol, "class", "list-decimal list-inside pl-3");
			add_location(ol, file, 9, 6, 208);
			attr_dev(div, "class", "pl-3");
			add_location(div, file, 8, 4, 183);
		},
		m: function mount(target, anchor) {
			insert_dev(target, h5, anchor);
			insert_dev(target, t1, anchor);
			insert_dev(target, div, anchor);
			append_dev(div, ol);
			append_dev(ol, li0);
			append_dev(ol, t3);
			append_dev(ol, li1);
			append_dev(ol, t5);
			append_dev(ol, li2);
			append_dev(ol, t7);
			append_dev(ol, li3);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(h5);
			if (detaching) detach_dev(t1);
			if (detaching) detach_dev(div);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(7:2) {#if !loggedin}",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div;
	let p;
	let t1;

	function select_block_type(ctx, dirty) {
		if (!/*loggedin*/ ctx[0]) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			div = element("div");
			p = element("p");
			p.textContent = "The main purpose of this utility is testing the webSDK widget app";
			t1 = space();
			if_block.c();
			add_location(p, file, 5, 2, 65);
			attr_dev(div, "class", "howto");
			add_location(div, file, 4, 0, 43);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, p);
			append_dev(div, t1);
			if_block.m(div, null);
		},
		p: function update(ctx, [dirty]) {
			if (current_block_type !== (current_block_type = select_block_type(ctx, dirty))) {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(div, null);
				}
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if_block.d();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('HowTo', slots, []);
	let { loggedin } = $$props;

	$$self.$$.on_mount.push(function () {
		if (loggedin === undefined && !('loggedin' in $$props || $$self.$$.bound[$$self.$$.props['loggedin']])) {
			console.warn("<HowTo> was created without expected prop 'loggedin'");
		}
	});

	const writable_props = ['loggedin'];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<HowTo> was created with unknown prop '${key}'`);
	});

	$$self.$$set = $$props => {
		if ('loggedin' in $$props) $$invalidate(0, loggedin = $$props.loggedin);
	};

	$$self.$capture_state = () => ({ loggedin });

	$$self.$inject_state = $$props => {
		if ('loggedin' in $$props) $$invalidate(0, loggedin = $$props.loggedin);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [loggedin];
}

class HowTo extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, { loggedin: 0 });

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "HowTo",
			options,
			id: create_fragment.name
		});
	}

	get loggedin() {
		throw new Error("<HowTo>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set loggedin(value) {
		throw new Error("<HowTo>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}
}

export default HowTo;