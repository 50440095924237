/* components/panels/Features.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	create_component,
	destroy_component,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	listen_dev,
	mount_component,
	run_all,
	safe_not_equal,
	space,
	text,
	transition_in,
	transition_out,
	validate_slots
} from "svelte/internal";

import Button from '../common/Button.svelte';
import { setFeatures, getLocalStorage } from '../../actions.js';
const file = "components/panels/Features.svelte";

// (41:2) <Button id="applyFeaturesBtn" className="self-end" on:click={handleSubmit}>
function create_default_slot(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Apply");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot.name,
		type: "slot",
		source: "(41:2) <Button id=\\\"applyFeaturesBtn\\\" className=\\\"self-end\\\" on:click={handleSubmit}>",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let ul;
	let li0;
	let input0;
	let input0_value_value;
	let label0;
	let t1;
	let li1;
	let input1;
	let input1_value_value;
	let label1;
	let t3;
	let button;
	let current;
	let mounted;
	let dispose;

	button = new Button({
			props: {
				id: "applyFeaturesBtn",
				className: "self-end",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button.$on("click", /*handleSubmit*/ ctx[2]);

	const block = {
		c: function create() {
			div1 = element("div");
			div0 = element("div");
			ul = element("ul");
			li0 = element("li");
			input0 = element("input");
			label0 = element("label");
			label0.textContent = "History";
			t1 = space();
			li1 = element("li");
			input1 = element("input");
			label1 = element("label");
			label1.textContent = "Appointment";
			t3 = space();
			create_component(button.$$.fragment);
			attr_dev(input0, "class", "shadow-none mx-1");
			attr_dev(input0, "name", "history");
			attr_dev(input0, "id", "history-feature");
			input0.__value = input0_value_value = true;
			input0.value = input0.__value;
			attr_dev(input0, "type", "checkbox");
			add_location(input0, file, 19, 8, 702);
			attr_dev(label0, "for", "history-feature");
			add_location(label0, file, 26, 10, 895);
			attr_dev(li0, "class", "flex");
			add_location(li0, file, 18, 6, 676);
			attr_dev(input1, "class", "shadow-none mx-1");
			attr_dev(input1, "name", "appointment");
			attr_dev(input1, "id", "appointment-feature");
			input1.__value = input1_value_value = true;
			input1.value = input1.__value;
			attr_dev(input1, "type", "checkbox");
			add_location(input1, file, 29, 8, 984);
			attr_dev(label1, "for", "appointment-feature");
			add_location(label1, file, 36, 10, 1189);
			attr_dev(li1, "class", "flex");
			add_location(li1, file, 28, 6, 958);
			add_location(ul, file, 17, 4, 665);
			attr_dev(div0, "class", "flex mt-1 w-full");
			add_location(div0, file, 16, 2, 630);
			attr_dev(div1, "id", "featuresContainer");
			attr_dev(div1, "class", "collapsable-content flex flex-col my-2 space-y-4");
			add_location(div1, file, 15, 0, 542);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			append_dev(div1, div0);
			append_dev(div0, ul);
			append_dev(ul, li0);
			append_dev(li0, input0);
			input0.checked = /*hasHistory*/ ctx[0];
			append_dev(li0, label0);
			append_dev(ul, t1);
			append_dev(ul, li1);
			append_dev(li1, input1);
			input1.checked = /*hasAppointment*/ ctx[1];
			append_dev(li1, label1);
			append_dev(div1, t3);
			mount_component(button, div1, null);
			current = true;

			if (!mounted) {
				dispose = [
					listen_dev(input0, "change", /*input0_change_handler*/ ctx[3]),
					listen_dev(input1, "change", /*input1_change_handler*/ ctx[4])
				];

				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*hasHistory*/ 1) {
				input0.checked = /*hasHistory*/ ctx[0];
			}

			if (dirty & /*hasAppointment*/ 2) {
				input1.checked = /*hasAppointment*/ ctx[1];
			}

			const button_changes = {};

			if (dirty & /*$$scope*/ 128) {
				button_changes.$$scope = { dirty, ctx };
			}

			button.$set(button_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(button.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(button.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
			destroy_component(button);
			mounted = false;
			run_all(dispose);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('Features', slots, []);
	let features = getLocalStorage('features') || '{}';
	const parsedFeatures = JSON.parse(features);

	let hasHistory = parsedFeatures?.hasHistory === undefined
	? true
	: parsedFeatures?.hasHistory;

	let hasAppointment = parsedFeatures?.hasAppointment === undefined
	? true
	: parsedFeatures?.hasAppointment;

	function handleSubmit() {
		setFeatures(JSON.stringify({ hasHistory, hasAppointment }));
	}

	const writable_props = [];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<Features> was created with unknown prop '${key}'`);
	});

	function input0_change_handler() {
		hasHistory = this.checked;
		$$invalidate(0, hasHistory);
	}

	function input1_change_handler() {
		hasAppointment = this.checked;
		$$invalidate(1, hasAppointment);
	}

	$$self.$capture_state = () => ({
		Button,
		setFeatures,
		getLocalStorage,
		features,
		parsedFeatures,
		hasHistory,
		hasAppointment,
		handleSubmit
	});

	$$self.$inject_state = $$props => {
		if ('features' in $$props) features = $$props.features;
		if ('hasHistory' in $$props) $$invalidate(0, hasHistory = $$props.hasHistory);
		if ('hasAppointment' in $$props) $$invalidate(1, hasAppointment = $$props.hasAppointment);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [
		hasHistory,
		hasAppointment,
		handleSubmit,
		input0_change_handler,
		input1_change_handler
	];
}

class Features extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, {});

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "Features",
			options,
			id: create_fragment.name
		});
	}
}

export default Features;