"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.visibilityOptions = exports.languages = exports.defaultServices = exports.defaultContext = exports.defaultConfig = exports.defaultAddress = void 0;
var defaultContext = exports.defaultContext = {
  type: 'triage',
  intent: 'get_care'
};
var defaultConfig = exports.defaultConfig = {
  notes: 'test',
  reasonCode: 'triage',
  attributeValues: [{
    attributeName: 'attr1',
    value: 'attr1-value'
  }]
};
var languages = exports.languages = ['en-US', 'es-CO', 'es-MX', 'es', 'pt-BR'];
var visibilityOptions = exports.visibilityOptions = ['always', 'onEncounterOnly', 'hidden'];
var defaultServices = exports.defaultServices = [
// ------------------- AXA --------------------
{
  name: 'AXA',
  obj: [{
    config: {
      encounterConfig: {
        notes: 'test',
        reasonCode: 'nurse-chat'
      }
    },
    imageUrl: 'https://assets.pager.com/icons/home_get_care_icon@3x.png',
    titles: {
      'en-US': {
        title: 'I Need Medical Help (rc: nurse-chat)',
        subtitle: ''
      },
      'es-CO': {
        title: 'Necesito orientación médica (rc: nurse-chat)',
        subtitle: ''
      },
      'es-MX': {
        title: 'Necesito orientación médica (rc: nurse-chat)',
        subtitle: ''
      },
      'pt-BR': {
        title: 'Necessito de orientação médica (rc: nurse-chat)',
        subtitle: ''
      },
      es: {
        title: 'Necesito orientación médica (rc: nurse-chat)',
        subtitle: ''
      }
    }
  }, {
    config: {
      encounterConfig: {
        notes: 'test',
        reasonCode: 'scheduling'
      }
    },
    imageUrl: 'https://assets.pager.com/icons/home_get_care_icon@3x.png',
    titles: {
      'en-US': {
        title: 'I need help scheduling an appointment (rc: scheduling)',
        subtitle: ''
      },
      'es-CO': {
        title: 'Necesito agendar una cita (rc: scheduling)',
        subtitle: ''
      },
      'es-MX': {
        title: 'Necesito agendar una cita (rc: scheduling)',
        subtitle: ''
      },
      'pt-BR': {
        title: 'Necessito de orientação médica (rc: nurse-chat)',
        subtitle: ''
      },
      es: {
        title: 'Necesito agendar una cita (rc: scheduling)',
        subtitle: ''
      }
    }
  }, {
    config: {
      encounterConfig: {
        notes: 'test',
        reasonCode: 'customer-service'
      }
    },
    imageUrl: 'https://assets.pager.com/icons/home_get_care_icon@3x.png',
    titles: {
      'en-US': {
        title: 'I Need To Talk To a CSA (rc: customer-service)',
        subtitle: ''
      },
      'es-CO': {
        title: 'Necesito hablar con un asesor (rc: customer-service)',
        subtitle: ''
      },
      'es-MX': {
        title: 'Necesito hablar con un asesor (rc: customer-service)',
        subtitle: ''
      },
      'pt-BR': {
        title: 'Necessito de orientação médica (rc: nurse-chat)',
        subtitle: ''
      },
      es: {
        title: 'Necesito hablar con un asesor (rc: customer-service)',
        subtitle: ''
      }
    }
  }]
},
// ------------------- DEMO --------------------
{
  name: 'DEMO',
  obj: [{
    config: {
      encounterConfig: {
        notes: 'test',
        reasonCode: 'nurse-chat'
      }
    },
    imageUrl: 'https://assets.pager.com/icons/home_get_care_icon@3x.png',
    titles: {
      'en-US': {
        title: "I'm not feeling well (rc: nurse-chat)",
        subtitle: ''
      },
      'es-CO': {
        title: 'No me siento bien (rc: nurse-chat)',
        subtitle: ''
      },
      'es-MX': {
        title: 'No me siento bien (rc: nurse-chat)',
        subtitle: ''
      },
      'pt-BR': {
        title: 'Necessito de orientação médica (rc: nurse-chat)',
        subtitle: ''
      },
      es: {
        title: 'No me siento bien (rc: nurse-chat)',
        subtitle: ''
      }
    }
  }, {
    config: {
      encounterConfig: {
        notes: 'test',
        reasonCode: 'customer-service'
      }
    },
    imageUrl: 'https://assets.pager.com/icons/home_get_care_icon@3x.png',
    titles: {
      'en-US': {
        title: 'I need help scheduling an appointment (rc: customer-service)',
        subtitle: ''
      },
      'es-CO': {
        title: 'Necesito agendar una cita (rc: customer-service)',
        subtitle: ''
      },
      'es-MX': {
        title: 'Necesito agendar una cita (rc: customer-service)',
        subtitle: ''
      },
      'pt-BR': {
        title: 'Necessito de orientação médica (rc: nurse-chat)',
        subtitle: ''
      },
      es: {
        title: 'Necesito agendar una cita (rc: customer-service)',
        subtitle: ''
      }
    }
  }, {
    config: {
      encounterConfig: {
        notes: 'test',
        reasonCode: 'customer-service'
      }
    },
    imageUrl: 'https://assets.pager.com/icons/home_get_care_icon@3x.png',
    titles: {
      'en-US': {
        title: 'I have a question about my plan (rc: customer-service)',
        subtitle: ''
      },
      'es-CO': {
        title: 'Tengo una pregunta sobre mi plan (rc: customer-service)',
        subtitle: ''
      },
      'es-MX': {
        title: 'Tengo una pregunta sobre mi plan (rc: customer-service)',
        subtitle: ''
      },
      'pt-BR': {
        title: 'Necessito de orientação médica (rc: nurse-chat)',
        subtitle: ''
      },
      es: {
        title: 'Tengo una pregunta sobre mi plan (rc: customer-service)',
        subtitle: ''
      }
    }
  }, {
    config: {
      encounterConfig: {
        notes: 'test',
        reasonCode: 'symptom-checker'
      }
    },
    imageUrl: 'https://assets.pager.com/icons/home_get_care_icon@3x.png',
    titles: {
      'en-US': {
        title: 'Symptom Checker (rc: symptom-checker)',
        subtitle: ''
      },
      'es-CO': {
        title: 'Symptom Checker (rc: symptom-checker)',
        subtitle: ''
      },
      'es-MX': {
        title: 'Symptom Checker (rc: symptom-checker)',
        subtitle: ''
      },
      'pt-BR': {
        title: 'Necessito de orientação médica (rc: nurse-chat)',
        subtitle: ''
      },
      es: {
        title: 'Symptom Checker (rc: symptom-checker)',
        subtitle: ''
      }
    }
  }]
},
// ------------------- GENERAL --------------------
{
  name: 'GENERAL',
  obj: [{
    config: {
      encounterConfig: {
        notes: 'test',
        reasonCode: 'nurse'
      }
    },
    imageUrl: 'https://assets.pager.com/icons/home_get_care_icon@3x.png',
    titles: {
      'en-US': {
        title: "I'm not feeling well (rc: nurse)",
        subtitle: ''
      },
      'es-CO': {
        title: 'No me siento bien (rc: nurse)',
        subtitle: ''
      },
      'es-MX': {
        title: 'No me siento bien (rc: nurse)',
        subtitle: ''
      },
      'pt-BR': {
        title: 'Necessito de orientação médica (rc: nurse-chat)',
        subtitle: ''
      },
      es: {
        title: 'No me siento bien (rc: nurse)',
        subtitle: ''
      }
    }
  }, {
    config: {
      encounterConfig: {
        notes: 'test',
        reasonCode: 'nurse'
      }
    },
    imageUrl: 'https://assets.pager.com/icons/home_get_care_icon@3x.png',
    titles: {
      'en-US': {
        title: 'I need help scheduling an appointment (rc: nurse)',
        subtitle: ''
      },
      'es-CO': {
        title: 'Necesito agendar una cita (rc: nurse)',
        subtitle: ''
      },
      'es-MX': {
        title: 'Necesito agendar una cita (rc: nurse)',
        subtitle: ''
      },
      'pt-BR': {
        title: 'Necessito de orientação médica (rc: nurse-chat)',
        subtitle: ''
      },
      es: {
        title: 'Necesito agendar una cita (rc: nurse)',
        subtitle: ''
      }
    }
  }, {
    config: {
      encounterConfig: {
        notes: 'test',
        reasonCode: 'nurse'
      }
    },
    imageUrl: 'https://assets.pager.com/icons/home_get_care_icon@3x.png',
    titles: {
      'en-US': {
        title: 'I have a question about my plan (rc: nurse)',
        subtitle: ''
      },
      'es-CO': {
        title: 'Tengo una pregunta sobre mi plan (rc: nurse)',
        subtitle: ''
      },
      'es-MX': {
        title: 'Tengo una pregunta sobre mi plan (rc: nurse)',
        subtitle: ''
      },
      'pt-BR': {
        title: 'Necessito de orientação médica (rc: nurse-chat)',
        subtitle: ''
      },
      es: {
        title: 'Tengo una pregunta sobre mi plan (rc: nurse)',
        subtitle: ''
      }
    }
  }, {
    config: {
      encounterConfig: {
        notes: 'test',
        reasonCode: 'nurse'
      }
    },
    imageUrl: '',
    titles: {
      'en-US': {
        title: 'Something else (rc: nurse)',
        subtitle: ''
      },
      'es-CO': {
        title: 'Otra consulta (rc: nurse)',
        subtitle: ''
      },
      'es-MX': {
        title: 'Otra consulta (rc: nurse)',
        subtitle: ''
      },
      'pt-BR': {
        title: 'Necessito de orientação médica (rc: nurse-chat)',
        subtitle: ''
      },
      es: {
        title: 'Otra consulta (rc: nurse)',
        subtitle: ''
      }
    }
  }]
},
// ------------------- REGENCE --------------------
{
  name: 'REGENCE',
  obj: [{
    config: {
      encounterConfig: {
        notes: 'test',
        reasonCode: 'nurse_care'
      }
    },
    imageUrl: 'https://assets.pager.com/icons/home_get_care_icon@3x.png',
    titles: {
      'en-US': {
        title: 'Chat With a Nurse (rc: nurse_care)',
        subtitle: ''
      },
      'es-CO': {
        title: 'Chat con enfermera (rc: nurse_care)',
        subtitle: ''
      },
      'es-MX': {
        title: 'Chat con enfermera (rc: nurse_care)',
        subtitle: ''
      },
      'pt-BR': {
        title: 'Necessito de orientação médica (rc: nurse-chat)',
        subtitle: ''
      },
      es: {
        title: 'Chat con enfermera (rc: nurse_care)',
        subtitle: ''
      }
    }
  }]
}];
var defaultAddress = exports.defaultAddress = {
  number: '625',
  street: 'Broadway Ave.',
  subLocality: 'NY',
  city: 'NYC',
  zipCode: '10001',
  state: 'NY',
  country: 'USA',
  location: {
    latitude: 40.712776,
    longitude: -74.005974
  }
};