/* components/common/Button.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	attr_dev,
	bubble,
	create_slot,
	detach_dev,
	dispatch_dev,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	insert_dev,
	listen_dev,
	safe_not_equal,
	transition_in,
	transition_out,
	update_slot_base,
	validate_slots
} from "svelte/internal";

const file = "components/common/Button.svelte";

function create_fragment(ctx) {
	let button;
	let button_class_value;
	let current;
	let mounted;
	let dispose;
	const default_slot_template = /*#slots*/ ctx[5].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[4], null);

	const block = {
		c: function create() {
			button = element("button");
			if (default_slot) default_slot.c();
			attr_dev(button, "id", /*id*/ ctx[1]);
			attr_dev(button, "class", button_class_value = "" + (/*buttonHover*/ ctx[2] + " " + /*className*/ ctx[0] + " hover:text-white focus:shadow-outline focus:outline-none"));
			attr_dev(button, "type", "button");
			add_location(button, file, 14, 0, 270);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, button, anchor);

			if (default_slot) {
				default_slot.m(button, null);
			}

			current = true;

			if (!mounted) {
				dispose = listen_dev(button, "click", /*click_handler*/ ctx[6], false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 16)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[4],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[4])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[4], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*id*/ 2) {
				attr_dev(button, "id", /*id*/ ctx[1]);
			}

			if (!current || dirty & /*buttonHover, className*/ 5 && button_class_value !== (button_class_value = "" + (/*buttonHover*/ ctx[2] + " " + /*className*/ ctx[0] + " hover:text-white focus:shadow-outline focus:outline-none"))) {
				attr_dev(button, "class", button_class_value);
			}
		},
		i: function intro(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(button);
			if (default_slot) default_slot.d(detaching);
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('Button', slots, ['default']);
	let { type = 'normal' } = $$props;
	let { className = '' } = $$props;
	let { id = null } = $$props;
	let buttonHover;

	if (type === 'normal') {
		buttonHover = 'hover:bg-pager-orange';
	} else if (type === 'danger') {
		buttonHover = 'hover:bg-red-600';
	}

	const writable_props = ['type', 'className', 'id'];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<Button> was created with unknown prop '${key}'`);
	});

	function click_handler(event) {
		bubble.call(this, $$self, event);
	}

	$$self.$$set = $$props => {
		if ('type' in $$props) $$invalidate(3, type = $$props.type);
		if ('className' in $$props) $$invalidate(0, className = $$props.className);
		if ('id' in $$props) $$invalidate(1, id = $$props.id);
		if ('$$scope' in $$props) $$invalidate(4, $$scope = $$props.$$scope);
	};

	$$self.$capture_state = () => ({ type, className, id, buttonHover });

	$$self.$inject_state = $$props => {
		if ('type' in $$props) $$invalidate(3, type = $$props.type);
		if ('className' in $$props) $$invalidate(0, className = $$props.className);
		if ('id' in $$props) $$invalidate(1, id = $$props.id);
		if ('buttonHover' in $$props) $$invalidate(2, buttonHover = $$props.buttonHover);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [className, id, buttonHover, type, $$scope, slots, click_handler];
}

class Button extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, { type: 3, className: 0, id: 1 });

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "Button",
			options,
			id: create_fragment.name
		});
	}

	get type() {
		throw new Error("<Button>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set type(value) {
		throw new Error("<Button>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	get className() {
		throw new Error("<Button>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set className(value) {
		throw new Error("<Button>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	get id() {
		throw new Error("<Button>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set id(value) {
		throw new Error("<Button>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}
}

export default Button;