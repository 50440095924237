/* components/panels/Actions.svelte generated by Svelte v3.53.1 */
import {
	SvelteComponentDev,
	add_location,
	append_dev,
	attr_dev,
	create_component,
	destroy_component,
	destroy_each,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	listen_dev,
	mount_component,
	noop,
	safe_not_equal,
	set_data_dev,
	space,
	text,
	transition_in,
	transition_out,
	validate_each_argument,
	validate_slots
} from "svelte/internal";

import Button from '../common/Button.svelte';
import { urls } from '../../envs.js';
import { getLocalStorage, turnOffInitFlow } from '../../actions';
import { createEventDispatcher } from 'svelte';
import Collapsable from '../common/Collapsable.svelte';
import EncounterConfig from './EncounterConfig.svelte';
import EventLog from './EventLog.svelte';
import Getters from './Getters.svelte';
import { LOCAL_STORAGE_INTERNAL_MP_FLAG } from '../../constants';
const file = "components/panels/Actions.svelte";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[16] = list[i];
	return child_ctx;
}

// (41:8) {:else}
function create_else_block_2(ctx) {
	let span;

	const block = {
		c: function create() {
			span = element("span");
			span.textContent = "Not logged in";
			attr_dev(span, "class", "bg-red-600 status-badge");
			add_location(span, file, 41, 10, 1370);
		},
		m: function mount(target, anchor) {
			insert_dev(target, span, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(span);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block_2.name,
		type: "else",
		source: "(41:8) {:else}",
		ctx
	});

	return block;
}

// (36:8) {#if loggedin}
function create_if_block_5(ctx) {
	let span;
	let t0;
	let t1;

	const block = {
		c: function create() {
			span = element("span");
			t0 = text("User:\n            ");
			t1 = text(/*currentPatient*/ ctx[1]);
			attr_dev(span, "class", "bg-green-600 status-badge");
			add_location(span, file, 36, 10, 1238);
		},
		m: function mount(target, anchor) {
			insert_dev(target, span, anchor);
			append_dev(span, t0);
			append_dev(span, t1);
		},
		p: function update(ctx, dirty) {
			if (dirty & /*currentPatient*/ 2) set_data_dev(t1, /*currentPatient*/ ctx[1]);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(span);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_5.name,
		type: "if",
		source: "(36:8) {#if loggedin}",
		ctx
	});

	return block;
}

// (45:32) {:else}
function create_else_block_1(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("custom env");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block_1.name,
		type: "else",
		source: "(45:32) {:else}",
		ctx
	});

	return block;
}

// (45:10) {#if hasUrl}
function create_if_block_4(ctx) {
	let t_value = /*env*/ ctx[3].name + "";
	let t;

	const block = {
		c: function create() {
			t = text(t_value);
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_4.name,
		type: "if",
		source: "(45:10) {#if hasUrl}",
		ctx
	});

	return block;
}

// (50:8) {#if internalTracking}
function create_if_block_3(ctx) {
	let span;

	const block = {
		c: function create() {
			span = element("span");
			span.textContent = "Internal MixPanel";
			attr_dev(span, "class", "bg-yellow-400 status-badge");
			add_location(span, file, 50, 10, 1706);
		},
		m: function mount(target, anchor) {
			insert_dev(target, span, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(span);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_3.name,
		type: "if",
		source: "(50:8) {#if internalTracking}",
		ctx
	});

	return block;
}

// (54:8) {#if hasUrl}
function create_if_block_1(ctx) {
	let div;
	let span1;
	let t;
	let span0;
	let mounted;
	let dispose;

	function select_block_type_2(ctx, dirty) {
		if (/*collapsed*/ ctx[2]) return create_if_block_2;
		return create_else_block;
	}

	let current_block_type = select_block_type_2(ctx, -1);
	let if_block = current_block_type(ctx);

	const block = {
		c: function create() {
			div = element("div");
			span1 = element("span");
			t = text("URLs\n              ");
			span0 = element("span");
			if_block.c();
			attr_dev(span0, "class", "text-xs");
			add_location(span0, file, 58, 14, 2039);
			attr_dev(span1, "class", "text-blue-800 cursor-pointer dark:text-white");
			add_location(span1, file, 56, 12, 1946);
			attr_dev(div, "class", "pl-1 self-center");
			add_location(div, file, 55, 10, 1885);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div, anchor);
			append_dev(div, span1);
			append_dev(span1, t);
			append_dev(span1, span0);
			if_block.m(span0, null);

			if (!mounted) {
				dispose = listen_dev(div, "click", /*toggle*/ ctx[7], false, false, false);
				mounted = true;
			}
		},
		p: function update(ctx, dirty) {
			if (current_block_type !== (current_block_type = select_block_type_2(ctx, dirty))) {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(span0, null);
				}
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div);
			if_block.d();
			mounted = false;
			dispose();
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_1.name,
		type: "if",
		source: "(54:8) {#if hasUrl}",
		ctx
	});

	return block;
}

// (60:38) {:else}
function create_else_block(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("▲");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_else_block.name,
		type: "else",
		source: "(60:38) {:else}",
		ctx
	});

	return block;
}

// (60:16) {#if collapsed}
function create_if_block_2(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("▼");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block_2.name,
		type: "if",
		source: "(60:16) {#if collapsed}",
		ctx
	});

	return block;
}

// (68:2) {#if !collapsed && hasUrl}
function create_if_block(ctx) {
	let div1;
	let div0;
	let ul;
	let each_value = urls[/*env*/ ctx[3].type];
	validate_each_argument(each_value);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const block = {
		c: function create() {
			div1 = element("div");
			div0 = element("div");
			ul = element("ul");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr_dev(ul, "class", "list-disc list-inside");
			add_location(ul, file, 73, 8, 2460);
			attr_dev(div0, "id", "environmentContainer");
			attr_dev(div0, "class", "w-full text-sm text-blue-800 underline bg-gray-200 p-5 dark:bg-gray-600 dark:text-white");
			add_location(div0, file, 69, 6, 2301);
			attr_dev(div1, "class", "mt-3 flex justify-end");
			add_location(div1, file, 68, 4, 2259);
		},
		m: function mount(target, anchor) {
			insert_dev(target, div1, anchor);
			append_dev(div1, div0);
			append_dev(div0, ul);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(ul, null);
			}
		},
		p: function update(ctx, dirty) {
			if (dirty & /*urls, env*/ 8) {
				each_value = urls[/*env*/ ctx[3].type];
				validate_each_argument(each_value);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(ul, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div1);
			destroy_each(each_blocks, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_if_block.name,
		type: "if",
		source: "(68:2) {#if !collapsed && hasUrl}",
		ctx
	});

	return block;
}

// (75:10) {#each urls[env.type] as url}
function create_each_block(ctx) {
	let li;
	let a;
	let t0_value = /*url*/ ctx[16].name + "";
	let t0;
	let a_href_value;
	let t1;

	const block = {
		c: function create() {
			li = element("li");
			a = element("a");
			t0 = text(t0_value);
			t1 = space();
			attr_dev(a, "href", a_href_value = /*url*/ ctx[16].url);
			attr_dev(a, "rel", "noreferrer");
			attr_dev(a, "target", "_blank");
			add_location(a, file, 76, 14, 2591);
			attr_dev(li, "class", "w-1/2 float-left");
			add_location(li, file, 75, 12, 2547);
		},
		m: function mount(target, anchor) {
			insert_dev(target, li, anchor);
			append_dev(li, a);
			append_dev(a, t0);
			append_dev(li, t1);
		},
		p: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(li);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_each_block.name,
		type: "each",
		source: "(75:10) {#each urls[env.type] as url}",
		ctx
	});

	return block;
}

// (87:6) <Button id="showActionBtn" on:click={() => dispatch('show')}>
function create_default_slot_9(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Show");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_9.name,
		type: "slot",
		source: "(87:6) <Button id=\\\"showActionBtn\\\" on:click={() => dispatch('show')}>",
		ctx
	});

	return block;
}

// (88:6) <Button id="hideActionBtn" on:click={() => dispatch('hide')}>
function create_default_slot_8(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Hide");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_8.name,
		type: "slot",
		source: "(88:6) <Button id=\\\"hideActionBtn\\\" on:click={() => dispatch('hide')}>",
		ctx
	});

	return block;
}

// (89:6) <Button id="openActionBtn" on:click={() => dispatch('open')}>
function create_default_slot_7(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Open");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_7.name,
		type: "slot",
		source: "(89:6) <Button id=\\\"openActionBtn\\\" on:click={() => dispatch('open')}>",
		ctx
	});

	return block;
}

// (90:6) <Button id="closeActionBtn" on:click={() => dispatch('close')}>
function create_default_slot_6(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Close");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_6.name,
		type: "slot",
		source: "(90:6) <Button id=\\\"closeActionBtn\\\" on:click={() => dispatch('close')}>",
		ctx
	});

	return block;
}

// (91:6) <Button id="logoutActionBtn" on:click={() => dispatch('logout')}>
function create_default_slot_5(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Logout");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_5.name,
		type: "slot",
		source: "(91:6) <Button id=\\\"logoutActionBtn\\\" on:click={() => dispatch('logout')}>",
		ctx
	});

	return block;
}

// (92:6) <Button id="destroyActionBtn" on:click={() => dispatch('destroy')} type="danger">
function create_default_slot_4(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Destroy");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_4.name,
		type: "slot",
		source: "(92:6) <Button id=\\\"destroyActionBtn\\\" on:click={() => dispatch('destroy')} type=\\\"danger\\\">",
		ctx
	});

	return block;
}

// (95:6) <Button id="clearActionBtn" on:click={() => dispatch('clear')} type="danger">
function create_default_slot_3(ctx) {
	let t;

	const block = {
		c: function create() {
			t = text("Clean storage");
		},
		m: function mount(target, anchor) {
			insert_dev(target, t, anchor);
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(t);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_3.name,
		type: "slot",
		source: "(95:6) <Button id=\\\"clearActionBtn\\\" on:click={() => dispatch('clear')} type=\\\"danger\\\">",
		ctx
	});

	return block;
}

// (100:2) <Collapsable title="Start Encounter" bold>
function create_default_slot_2(ctx) {
	let encounterconfig;
	let current;
	encounterconfig = new EncounterConfig({ $$inline: true });

	const block = {
		c: function create() {
			create_component(encounterconfig.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(encounterconfig, target, anchor);
			current = true;
		},
		i: function intro(local) {
			if (current) return;
			transition_in(encounterconfig.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(encounterconfig.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(encounterconfig, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_2.name,
		type: "slot",
		source: "(100:2) <Collapsable title=\\\"Start Encounter\\\" bold>",
		ctx
	});

	return block;
}

// (103:2) <Collapsable title="Events Log" bold>
function create_default_slot_1(ctx) {
	let eventlog;
	let current;
	eventlog = new EventLog({ $$inline: true });

	const block = {
		c: function create() {
			create_component(eventlog.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(eventlog, target, anchor);
			current = true;
		},
		i: function intro(local) {
			if (current) return;
			transition_in(eventlog.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(eventlog.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(eventlog, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot_1.name,
		type: "slot",
		source: "(103:2) <Collapsable title=\\\"Events Log\\\" bold>",
		ctx
	});

	return block;
}

// (106:2) <Collapsable title="Getters" bold>
function create_default_slot(ctx) {
	let getters;
	let current;
	getters = new Getters({ $$inline: true });

	const block = {
		c: function create() {
			create_component(getters.$$.fragment);
		},
		m: function mount(target, anchor) {
			mount_component(getters, target, anchor);
			current = true;
		},
		i: function intro(local) {
			if (current) return;
			transition_in(getters.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(getters.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			destroy_component(getters, detaching);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_default_slot.name,
		type: "slot",
		source: "(106:2) <Collapsable title=\\\"Getters\\\" bold>",
		ctx
	});

	return block;
}

function create_fragment(ctx) {
	let div7;
	let div0;
	let t1;
	let div3;
	let div2;
	let div1;
	let t2;
	let span0;
	let t3;
	let span1;
	let t6;
	let t7;
	let t8;
	let t9;
	let div4;
	let t11;
	let div6;
	let div5;
	let button0;
	let t12;
	let button1;
	let t13;
	let button2;
	let t14;
	let button3;
	let t15;
	let button4;
	let t16;
	let button5;
	let t17;
	let button6;
	let t18;
	let collapsable0;
	let t19;
	let collapsable1;
	let t20;
	let collapsable2;
	let current;

	function select_block_type(ctx, dirty) {
		if (/*loggedin*/ ctx[0]) return create_if_block_5;
		return create_else_block_2;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block0 = current_block_type(ctx);

	function select_block_type_1(ctx, dirty) {
		if (/*hasUrl*/ ctx[6]) return create_if_block_4;
		return create_else_block_1;
	}

	let current_block_type_1 = select_block_type_1(ctx, -1);
	let if_block1 = current_block_type_1(ctx);
	let if_block2 = /*internalTracking*/ ctx[5] && create_if_block_3(ctx);
	let if_block3 = /*hasUrl*/ ctx[6] && create_if_block_1(ctx);
	let if_block4 = !/*collapsed*/ ctx[2] && /*hasUrl*/ ctx[6] && create_if_block(ctx);

	button0 = new Button({
			props: {
				id: "showActionBtn",
				$$slots: { default: [create_default_slot_9] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button0.$on("click", /*click_handler*/ ctx[9]);

	button1 = new Button({
			props: {
				id: "hideActionBtn",
				$$slots: { default: [create_default_slot_8] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button1.$on("click", /*click_handler_1*/ ctx[10]);

	button2 = new Button({
			props: {
				id: "openActionBtn",
				$$slots: { default: [create_default_slot_7] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button2.$on("click", /*click_handler_2*/ ctx[11]);

	button3 = new Button({
			props: {
				id: "closeActionBtn",
				$$slots: { default: [create_default_slot_6] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button3.$on("click", /*click_handler_3*/ ctx[12]);

	button4 = new Button({
			props: {
				id: "logoutActionBtn",
				$$slots: { default: [create_default_slot_5] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button4.$on("click", /*click_handler_4*/ ctx[13]);

	button5 = new Button({
			props: {
				id: "destroyActionBtn",
				type: "danger",
				$$slots: { default: [create_default_slot_4] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button5.$on("click", /*click_handler_5*/ ctx[14]);

	button6 = new Button({
			props: {
				id: "clearActionBtn",
				type: "danger",
				$$slots: { default: [create_default_slot_3] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	button6.$on("click", /*click_handler_6*/ ctx[15]);

	collapsable0 = new Collapsable({
			props: {
				title: "Start Encounter",
				bold: true,
				$$slots: { default: [create_default_slot_2] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	collapsable1 = new Collapsable({
			props: {
				title: "Events Log",
				bold: true,
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	collapsable2 = new Collapsable({
			props: {
				title: "Getters",
				bold: true,
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			},
			$$inline: true
		});

	const block = {
		c: function create() {
			div7 = element("div");
			div0 = element("div");
			div0.textContent = "Current Status:";
			t1 = space();
			div3 = element("div");
			div2 = element("div");
			div1 = element("div");
			if_block0.c();
			t2 = space();
			span0 = element("span");
			if_block1.c();
			t3 = space();
			span1 = element("span");
			span1.textContent = `Visibility: ${/*visibilityMode*/ ctx[4] ?? 'visible'}`;
			t6 = space();
			if (if_block2) if_block2.c();
			t7 = space();
			if (if_block3) if_block3.c();
			t8 = space();
			if (if_block4) if_block4.c();
			t9 = space();
			div4 = element("div");
			div4.textContent = "Actions and Info:";
			t11 = space();
			div6 = element("div");
			div5 = element("div");
			create_component(button0.$$.fragment);
			t12 = space();
			create_component(button1.$$.fragment);
			t13 = space();
			create_component(button2.$$.fragment);
			t14 = space();
			create_component(button3.$$.fragment);
			t15 = space();
			create_component(button4.$$.fragment);
			t16 = space();
			create_component(button5.$$.fragment);
			t17 = space();
			create_component(button6.$$.fragment);
			t18 = space();
			create_component(collapsable0.$$.fragment);
			t19 = space();
			create_component(collapsable1.$$.fragment);
			t20 = space();
			create_component(collapsable2.$$.fragment);
			attr_dev(div0, "class", "flex-1 border-b border-gray-300 pb-1 font-bold mb-3");
			add_location(div0, file, 31, 2, 985);
			attr_dev(span0, "class", "bg-pager-orange status-badge");
			add_location(span0, file, 43, 8, 1453);
			attr_dev(span1, "class", "bg-red-400 status-badge");
			add_location(span1, file, 47, 8, 1577);
			attr_dev(div1, "class", "flex items-center");
			add_location(div1, file, 34, 6, 1173);
			attr_dev(div2, "class", "text-sm flex");
			add_location(div2, file, 33, 4, 1140);
			attr_dev(div3, "class", "flex p-2 mt-1 w-full shadow-md dark:bg-gray-700");
			add_location(div3, file, 32, 2, 1074);
			attr_dev(div4, "class", "flex-1 border-b border-gray-300 pb-1 font-bold my-3");
			add_location(div4, file, 83, 2, 2741);
			attr_dev(div5, "class", "flex-1 space-x-3 flex-row");
			add_location(div5, file, 85, 4, 2898);
			attr_dev(div6, "class", "flex p-2 mt-1 w-full shadow-md dark:bg-gray-700");
			add_location(div6, file, 84, 2, 2832);
			attr_dev(div7, "id", "actionsContainer");
			attr_dev(div7, "class", "my-10");
			add_location(div7, file, 30, 0, 941);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, div7, anchor);
			append_dev(div7, div0);
			append_dev(div7, t1);
			append_dev(div7, div3);
			append_dev(div3, div2);
			append_dev(div2, div1);
			if_block0.m(div1, null);
			append_dev(div1, t2);
			append_dev(div1, span0);
			if_block1.m(span0, null);
			append_dev(div1, t3);
			append_dev(div1, span1);
			append_dev(div1, t6);
			if (if_block2) if_block2.m(div1, null);
			append_dev(div1, t7);
			if (if_block3) if_block3.m(div1, null);
			append_dev(div7, t8);
			if (if_block4) if_block4.m(div7, null);
			append_dev(div7, t9);
			append_dev(div7, div4);
			append_dev(div7, t11);
			append_dev(div7, div6);
			append_dev(div6, div5);
			mount_component(button0, div5, null);
			append_dev(div5, t12);
			mount_component(button1, div5, null);
			append_dev(div5, t13);
			mount_component(button2, div5, null);
			append_dev(div5, t14);
			mount_component(button3, div5, null);
			append_dev(div5, t15);
			mount_component(button4, div5, null);
			append_dev(div5, t16);
			mount_component(button5, div5, null);
			append_dev(div5, t17);
			mount_component(button6, div5, null);
			append_dev(div7, t18);
			mount_component(collapsable0, div7, null);
			append_dev(div7, t19);
			mount_component(collapsable1, div7, null);
			append_dev(div7, t20);
			mount_component(collapsable2, div7, null);
			current = true;
		},
		p: function update(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block0) {
				if_block0.p(ctx, dirty);
			} else {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(div1, t2);
				}
			}

			if_block1.p(ctx, dirty);
			if (/*hasUrl*/ ctx[6]) if_block3.p(ctx, dirty);

			if (!/*collapsed*/ ctx[2] && /*hasUrl*/ ctx[6]) {
				if (if_block4) {
					if_block4.p(ctx, dirty);
				} else {
					if_block4 = create_if_block(ctx);
					if_block4.c();
					if_block4.m(div7, t9);
				}
			} else if (if_block4) {
				if_block4.d(1);
				if_block4 = null;
			}

			const button0_changes = {};

			if (dirty & /*$$scope*/ 524288) {
				button0_changes.$$scope = { dirty, ctx };
			}

			button0.$set(button0_changes);
			const button1_changes = {};

			if (dirty & /*$$scope*/ 524288) {
				button1_changes.$$scope = { dirty, ctx };
			}

			button1.$set(button1_changes);
			const button2_changes = {};

			if (dirty & /*$$scope*/ 524288) {
				button2_changes.$$scope = { dirty, ctx };
			}

			button2.$set(button2_changes);
			const button3_changes = {};

			if (dirty & /*$$scope*/ 524288) {
				button3_changes.$$scope = { dirty, ctx };
			}

			button3.$set(button3_changes);
			const button4_changes = {};

			if (dirty & /*$$scope*/ 524288) {
				button4_changes.$$scope = { dirty, ctx };
			}

			button4.$set(button4_changes);
			const button5_changes = {};

			if (dirty & /*$$scope*/ 524288) {
				button5_changes.$$scope = { dirty, ctx };
			}

			button5.$set(button5_changes);
			const button6_changes = {};

			if (dirty & /*$$scope*/ 524288) {
				button6_changes.$$scope = { dirty, ctx };
			}

			button6.$set(button6_changes);
			const collapsable0_changes = {};

			if (dirty & /*$$scope*/ 524288) {
				collapsable0_changes.$$scope = { dirty, ctx };
			}

			collapsable0.$set(collapsable0_changes);
			const collapsable1_changes = {};

			if (dirty & /*$$scope*/ 524288) {
				collapsable1_changes.$$scope = { dirty, ctx };
			}

			collapsable1.$set(collapsable1_changes);
			const collapsable2_changes = {};

			if (dirty & /*$$scope*/ 524288) {
				collapsable2_changes.$$scope = { dirty, ctx };
			}

			collapsable2.$set(collapsable2_changes);
		},
		i: function intro(local) {
			if (current) return;
			transition_in(button0.$$.fragment, local);
			transition_in(button1.$$.fragment, local);
			transition_in(button2.$$.fragment, local);
			transition_in(button3.$$.fragment, local);
			transition_in(button4.$$.fragment, local);
			transition_in(button5.$$.fragment, local);
			transition_in(button6.$$.fragment, local);
			transition_in(collapsable0.$$.fragment, local);
			transition_in(collapsable1.$$.fragment, local);
			transition_in(collapsable2.$$.fragment, local);
			current = true;
		},
		o: function outro(local) {
			transition_out(button0.$$.fragment, local);
			transition_out(button1.$$.fragment, local);
			transition_out(button2.$$.fragment, local);
			transition_out(button3.$$.fragment, local);
			transition_out(button4.$$.fragment, local);
			transition_out(button5.$$.fragment, local);
			transition_out(button6.$$.fragment, local);
			transition_out(collapsable0.$$.fragment, local);
			transition_out(collapsable1.$$.fragment, local);
			transition_out(collapsable2.$$.fragment, local);
			current = false;
		},
		d: function destroy(detaching) {
			if (detaching) detach_dev(div7);
			if_block0.d();
			if_block1.d();
			if (if_block2) if_block2.d();
			if (if_block3) if_block3.d();
			if (if_block4) if_block4.d();
			destroy_component(button0);
			destroy_component(button1);
			destroy_component(button2);
			destroy_component(button3);
			destroy_component(button4);
			destroy_component(button5);
			destroy_component(button6);
			destroy_component(collapsable0);
			destroy_component(collapsable1);
			destroy_component(collapsable2);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots('Actions', slots, []);
	let { loggedin = false } = $$props;
	let { currentPatient = '' } = $$props;
	const env = JSON.parse(getLocalStorage('env'));
	const visibilityMode = getLocalStorage('visibility');

	const internalTracking = localStorage.getItem(LOCAL_STORAGE_INTERNAL_MP_FLAG) === 'true'
	? true
	: false;

	const hasUrl = !!urls[env.type];
	let collapsed = true;

	function toggle() {
		$$invalidate(2, collapsed = !collapsed);
	}

	const dispatch = createEventDispatcher();
	const writable_props = ['loggedin', 'currentPatient'];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== '$$' && key !== 'slot') console.warn(`<Actions> was created with unknown prop '${key}'`);
	});

	const click_handler = () => dispatch('show');
	const click_handler_1 = () => dispatch('hide');
	const click_handler_2 = () => dispatch('open');
	const click_handler_3 = () => dispatch('close');
	const click_handler_4 = () => dispatch('logout');
	const click_handler_5 = () => dispatch('destroy');
	const click_handler_6 = () => dispatch('clear');

	$$self.$$set = $$props => {
		if ('loggedin' in $$props) $$invalidate(0, loggedin = $$props.loggedin);
		if ('currentPatient' in $$props) $$invalidate(1, currentPatient = $$props.currentPatient);
	};

	$$self.$capture_state = () => ({
		Button,
		urls,
		getLocalStorage,
		turnOffInitFlow,
		createEventDispatcher,
		Collapsable,
		EncounterConfig,
		EventLog,
		Getters,
		LOCAL_STORAGE_INTERNAL_MP_FLAG,
		loggedin,
		currentPatient,
		env,
		visibilityMode,
		internalTracking,
		hasUrl,
		collapsed,
		toggle,
		dispatch
	});

	$$self.$inject_state = $$props => {
		if ('loggedin' in $$props) $$invalidate(0, loggedin = $$props.loggedin);
		if ('currentPatient' in $$props) $$invalidate(1, currentPatient = $$props.currentPatient);
		if ('collapsed' in $$props) $$invalidate(2, collapsed = $$props.collapsed);
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	return [
		loggedin,
		currentPatient,
		collapsed,
		env,
		visibilityMode,
		internalTracking,
		hasUrl,
		toggle,
		dispatch,
		click_handler,
		click_handler_1,
		click_handler_2,
		click_handler_3,
		click_handler_4,
		click_handler_5,
		click_handler_6
	];
}

class Actions extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, { loggedin: 0, currentPatient: 1 });

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "Actions",
			options,
			id: create_fragment.name
		});
	}

	get loggedin() {
		throw new Error("<Actions>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set loggedin(value) {
		throw new Error("<Actions>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	get currentPatient() {
		throw new Error("<Actions>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set currentPatient(value) {
		throw new Error("<Actions>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}
}

export default Actions;